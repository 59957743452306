import { memo, useState, useCallback, useMemo, useEffect } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { CartItem } from "./CartItem";
import {
  usePersonalization,
  useNews,
  useTechStacks,
  useRankedFeedApi,
} from "~src/customHooks";
import { PanelListContainer } from "~src/layout/MainPanel/PanelBlock";

import { RankedItem } from "~src/api/typings";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { MAX_ICEBREAKERS } from "~src/constants";
import { CART_HEADER } from "~src/strings";
import {
  icebreakerCount,
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
  getAllCartItems,
  isItemInCart,
} from "~src/utils";

import { StyledStartMyEmailModalItem } from "../Styled";

interface Props {
  hasFormItem?: boolean;
}

export const Cart = memo(function Cart({ hasFormItem }: Props) {
  const {
    addItemToCartContext,
    currentPersonalization: {
      cart,
      profile: { email },
    },
    removeItemFromCartContext,
    resetCartContext,
  } = usePersonalization();
  const {
    data: { openedBy },
    modalType,
    next,
    reset,
  } = useCarouselContext();
  const [expanded, setExpanded] = useState<string>("");

  const { news } = useNews();
  const { techStacks } = useTechStacks();
  const areDependenciesLoading =
    !news[email] ||
    news[email]?.loading ||
    !techStacks[email] ||
    techStacks[email]?.loading;

  const {
    data: { rankedFeed },
  } = useRankedFeedApi(email, areDependenciesLoading);
  const feedCount = icebreakerCount(rankedFeed);

  useEffect(() => {
    if (
      ([ModalType.Cart, ModalType.CartEmptied].includes(ModalType[modalType]) &&
        (!openedBy || openedBy !== email)) ||
      feedCount === 0
    ) {
      reset();
      resetCartContext();
    }
  }, [openedBy, email, feedCount, modalType, resetCartContext, reset]);

  const cartCount = icebreakerCount(cart);
  const onSelect = useCallback(
    (e: CheckboxChangeEvent, item: RankedItem) => {
      e.stopPropagation();
      const { checked } = e.target;
      if (checked) {
        addItemToCartContext(item);
      } else {
        removeItemFromCartContext(item);
        if (cartCount === 1) {
          next(ModalType.CartEmptied, {
            openedBy: email,
            phase: CART_HEADER.ADD_CONTEXT,
          });
        }
      }
      segmentEvent(
        SegmentEventNames.CheckboxClicked,
        SegmentEventLocations.StartMyEmail
      );
    },
    [addItemToCartContext, removeItemFromCartContext, cartCount, next, email]
  );

  const entity = modalType === ModalType.CartEmptied ? rankedFeed : cart;
  const allItems = useMemo(() => getAllCartItems(entity), [entity]);
  const onExpand = useCallback((id: string) => {
    setExpanded((prev) => (prev === id ? "" : id));
  }, []);

  const renderItems = useMemo(
    () =>
      allItems.map((item, i) => {
        const isExpanded = expanded === item.id;
        const isSelected = isItemInCart(cart, item);
        const isDisabled = cartCount >= MAX_ICEBREAKERS && !isSelected;
        return (
          <StyledStartMyEmailModalItem
            key={item.id}
            $padding={hasFormItem ? "16px 12px 12px" : "16px 12px"}
          >
            <PanelListContainer>
              <CartItem
                hasFormItem={hasFormItem}
                index={i}
                item={item}
                isDisabled={isDisabled}
                isExpanded={isExpanded}
                isSelected={isSelected}
                onExpand={onExpand}
                onSelect={onSelect}
              />
            </PanelListContainer>
          </StyledStartMyEmailModalItem>
        );
      }),
    [allItems, cart, cartCount, expanded, hasFormItem, onExpand, onSelect]
  );

  return renderItems;
});
