import { formatDate, formatDuration } from "../utils/datetime";
import {
  CallOutlineItem,
  GongCall,
  GongData,
  Party,
  Step,
  Collaboration,
} from "../redux/typings";
import { useCRM } from ".";

const numberOfCallParticipants = (participants: string[]): string => {
  if (!participants.length) {
    return "No Call Participants";
  } else if (participants.length === 1) {
    return participants[0];
  } else {
    return `${participants[0]} +${participants.length - 1}`;
  }
};

export const useGongData = (): GongCall[] => {
  const {
    currentCRM: { gong_data: gongData },
  } = useCRM();

  if (!gongData.length) {
    return [];
  }

  // map gong data array items to gong call objects
  const gongCalls: GongCall[] = gongData.map((callObj: GongData) => {
    const id: string = callObj.metaData.id;
    const title: string = callObj.metaData.title;
    const url: string = callObj.metaData.url;
    const callTime: string = callObj.metaData.started;
    const callTimeFormatted: string = formatDate(callTime);
    const duration: number = callObj.metaData.duration;
    const durationFormatted: string = formatDuration(duration);
    const callBrief: string = callObj.content.brief;
    const outline: CallOutlineItem[] = callObj.content.outline;
    // TODO: remove checks below we can verify all calls have parties, current api responses do not
    const participants: string[] = callObj.parties
      ? callObj.parties.map((party: Party) => party.name)
      : [];
    const participantsText: string = numberOfCallParticipants(participants);
    const nextSteps: string[] = callObj.nextSteps
      ? callObj.nextSteps.map((step: Step) => step.text)
      : [];

    const collaboration: Collaboration = callObj.collaboration;

    return {
      id,
      title,
      url,
      callTime,
      callTimeFormatted,
      participants,
      participantsText,
      duration,
      durationFormatted,
      nextSteps,
      callBrief,
      outline,
      collaboration,
    };
  });

  return gongCalls;
};
