import { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Flex } from "antd";
import { styled } from "styled-components";
import root from "react-shadow/styled-components";

import { Button, Icon, Text } from "@lavender-ai/lav-components";

import { Recommendation, AISuggestion } from "~src/redux/typings";
import { useDomNodes } from "../../../../../../../../../customHooks";
import { Spinner } from "~src/component/Lottie";
import { insertToTextfield } from "~src/utils/dom";

import {
  SegmentEventLocations,
  SegmentEventNames,
  segmentEvent,
} from "~src/utils";

interface Props {
  recommendation: Recommendation;
  suggestions: AISuggestion | undefined;
  clear: () => void;
  regenerate: () => void;
}

export const Suggestion = ({
  recommendation,
  suggestions,
  clear,
  regenerate,
}: Props) => {
  if (recommendation.rectype === "Mobile Issues") {
    return <></>;
  }
  const measuredRef = useRef<HTMLElement>(null);

  const { panelParent, emailBody } = useDomNodes();

  const scrollElement = panelParent?.querySelector(".qz.aiL");
  const parentScrollElement = panelParent?.querySelector(".Tm.aeJ .aeF");

  useEffect(() => {
    const adjustHeight = () => {
      if (!panelParent || !emailBody || !recommendation.id) {
        return;
      }
      const item = emailBody?.ownerDocument.getElementById(recommendation.id);
      if (!item || !measuredRef.current) return;
      const location = item.getBoundingClientRect();
      const emailLocation = emailBody.getBoundingClientRect();
      measuredRef.current.style.bottom =
        suggestions === undefined ? "auto" : "10px";
      measuredRef.current.style.top = location.y + location.height + 6 + "px";

      measuredRef.current.style.width = emailLocation?.width + "px";
      measuredRef.current.style.maxWidth = "400px";
      measuredRef.current.style.left = emailLocation?.left + "px";
      measuredRef.current.style.position = "fixed";
    };

    const heightObserver = new ResizeObserver(adjustHeight);
    panelParent && heightObserver.observe(panelParent);
    scrollElement?.addEventListener("scroll", adjustHeight);
    parentScrollElement?.addEventListener("scroll", adjustHeight);

    // Scroll into view?
    const item = recommendation.id
      ? document.getElementById(recommendation.id)
      : null;
    const topPos = item?.offsetTop;
    if (scrollElement && item && topPos !== undefined) {
      scrollElement.scrollTop = topPos;
    }
    if (parentScrollElement && item) {
      parentScrollElement.scrollTop = parentScrollElement.scrollHeight;
    }

    return () => {
      if (panelParent) {
        heightObserver.unobserve(panelParent);
        scrollElement?.removeEventListener("scroll", adjustHeight);
        parentScrollElement?.removeEventListener("scroll", adjustHeight);
      } else {
        heightObserver.disconnect();
      }
    };
  }, [
    emailBody,
    panelParent,
    parentScrollElement,
    recommendation.id,
    scrollElement,
    suggestions,
  ]);

  const insertAction = useCallback(
    (textToInsert: string, recommendationId: string) => {
      emailBody && insertToTextfield(emailBody, textToInsert, recommendationId);
      clear();
    },
    [clear, emailBody]
  );

  const isLoading = suggestions === undefined;
  if (!emailBody?.ownerDocument.body) {
    console.error(
      "Email Body Document not found",
      emailBody,
      emailBody?.ownerDocument.body
    );
    return null;
  }

  return createPortal(
    <ShadowDOM id={`pop-${recommendation.id}`} ref={measuredRef}>
      <style>
        @import {`"${chrome.runtime.getURL?.("react/bundle.css")}"`}
      </style>
      <Popup className={isLoading ? "lv-popup-loading" : ""}>
        <PopupHeader>
          <Text size="subtext1">
            {recommendation?.gpt_rec || recommendation?.rectype}
          </Text>
          <CloseButton onClick={clear}>
            <Icon variant="IconX" size="extra-small" />
          </CloseButton>
        </PopupHeader>
        {!isLoading ? (
          <AllSuggestions>
            {suggestions?.RecommendationObject?.map((s, i) => (
              <Suggest className="lv-suggest-popup" key={`pop-sug-${i}`}>
                <SuggestTitle size="subtext1">{s.RecType}</SuggestTitle>
                <SuggestBody size="body2">{s.NewSentence}</SuggestBody>
                <Button
                  size="small"
                  variant="primary"
                  onClick={() => {
                    if (!recommendation.id) {
                      return;
                    }
                    insertAction(s.NewSentence, recommendation.id);
                    segmentEvent(
                      SegmentEventNames.RecInserted,
                      SegmentEventLocations.EmailCoach
                    );
                  }}
                >
                  Insert
                </Button>
              </Suggest>
            ))}
          </AllSuggestions>
        ) : (
          <LoadingBox>
            <Spinner width={60} height={60} />
          </LoadingBox>
        )}
        <Regenerate className="lv-regenerate" onClick={regenerate}>
          <Icon variant="IconWand" size="extra-small" />
          <Text size="subtext1">Regenerate options</Text>
        </Regenerate>
      </Popup>
    </ShadowDOM>,
    emailBody?.ownerDocument.body
  );
};

const ShadowDOM = styled(root.div)`
  height: auto;
  width: 100px;
  position: fixed;
  z-index: 9999999;
`;
const Popup = styled(Flex)`
  border-radius: var(--radius-md-lrg, 12px);
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  overflow: scroll;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  &.lv-popup-loading .lv-regenerate {
    cursor: not-allowed;
  }
`;
const PopupHeader = styled(Flex)`
  display: flex;
  flex-direction: row;
  padding: 16px 40px 12px 16px;
  justify-content: center;
  align-items: center;
  background: var(--color-neutral-purple-100, #e9ebf2);
  color: var(--color-neutral-purple-900, #0d1421);
  position: relative;
`;
const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 32px;
  height: 32px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &:hover svg {
    opacity: 0.7;
  }
`;

const Suggest = styled(Flex)`
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--color-neutral-purple-100, #e9ebf2);
  position: relative;
  button {
    min-height: 24px;
    position: absolute;
    right: 16px;
    top: 20px;
    transform: translateY(-50%);
    display: none !important;
  }
  &:hover {
    background: var(--lv-panel-background-color);
    button {
      display: flex !important;
      cursor: pointer !important;
    }
  }
`;
const Regenerate = styled(Flex)`
  display: flex;
  flex-direction: row;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid var(--color-neutral-purple-100, #e9ebf2);
  span {
    color: var(--color-neutral-purple-900, #0d1421) !important;
  }
  svg {
    margin-right: 4px;
  }
  &:hover {
    background: var(--lv-panel-background-color);
  }
`;
const AllSuggestions = styled.div`
  > .lv-suggest-popup:last-child {
    border-top: 0 !important;
  }
`;
const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 68px;
`;
const SuggestTitle = styled(Text)`
  color: var(--color-neutral-purple-600, #7f848f) !important;
  margin-bottom: 12px !important;
`;
const SuggestBody = styled(Text)`
  color: var(--color-neutral-purple-900, #0d1421) !important;
`;
