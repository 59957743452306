import { XOR } from "ts-xor";

import { ErrorResponse } from "./api";
import { ValueProps } from "~src/redux/typings";

export interface ModifyUserValuePropsPayload {
  categories: ValueProps;
  chromeId: chromeId;
}

export type ModifyUserValuePropsResponse = XOR<ErrorResponse, void>;
