import { MutationDefinition } from "@reduxjs/toolkit/query";
import { XOR } from "ts-xor";

import { CartItem, RankedFeedType } from "./";
import {
  APIResponseStatus,
  ErrorResponse,
  SuccessReponse,
  UseMutationBaseQuery,
} from "./api";
import { CustomCategories } from "~src/redux/typings";

export interface StartMyEmailPayload {
  chromeId: chromeId;
  email: emailAddress;
  cart: StartMyEmailCartPayload;
  custom: CustomCategories["categories"]["custom"];
  signal?: AbortSignal;
  sessionId: lvIdentifier;
  stream?: boolean;
  n: number;
}

export type StartMyEmailSuccessResponse = SuccessReponse<{
  generated: StartMyEmailCart;
}>;

export type StartMyEmailHTTPResponse = XOR<
  ErrorResponse,
  StartMyEmailSuccessResponse
>;

export type StartMyEmailResponse = XOR<
  StartMyEmailHTTPResponse,
  StartMyEmailWSResponse
>;

export interface StartMyEmailWSResponse {
  generated: StartMyEmailCart;
  status: APIResponseStatus;
  type: "sme";
}

export interface StartMyEmailData {
  cart: Required<StartMyEmailCart> & {
    emails: Record<string, StartMyEmailEmail>;
  };
  result: NormalizedSection;
}

interface StartMyEmailEmail {
  id: string;
  content: string;
  parentId: StartMyEmailCartItemId;
  parentType: RankedFeedType;
}

export type NormalizedSection = Record<RankedFeedType, string[]> & {
  emails: string[];
};
export type StartMyEmailCart = {
  [K in RankedFeedType]?: StartMyEmailSection;
};
export type StartMyEmailCartPayload = {
  [K in RankedFeedType]?: StartMyEmailCartItem[];
};
export type StartMyEmailCartItemId = string;
export type StartMyEmailSection = Record<
  StartMyEmailCartItemId,
  StartMyEmailCartItem
>;

export interface StartMyEmailCartItem extends Omit<CartItem, "type" | "id"> {
  id: StartMyEmailCartItemId;
  emails?: string[]; // generated emails. only in response
}

export type UseStartMyEmailMutationDefinition = MutationDefinition<
  StartMyEmailPayload,
  UseMutationBaseQuery,
  never,
  StartMyEmailData,
  "api"
>;
