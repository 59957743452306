import { useMemo } from "react";

import { useVerification } from ".";

import {
  type IPersonalizationContext,
  usePersonalizationContext,
} from "../contexts/PersonalizationContextProvider";
import { useAppSelector } from "../redux/hooks";
import {
  type VerificationState,
  type RecipientPersonalizationMap,
  NetworkStatus,
} from "../redux/typings";

// Hook providing all information about an individual personalization - use throughout the entire personalization panel

export interface IUsePersonalization extends IPersonalizationContext {
  loading: boolean;
  status: NetworkStatus;
  lastUpdated: number | null;
  recipients: string[];
  recipientVerifications: VerificationState;
  personalizations: RecipientPersonalizationMap;
}

export const usePersonalization = (): IUsePersonalization => {
  const { recipients, recipientVerifications } = useVerification();

  const {
    personalizations: personalizationsObj,
    status = NetworkStatus.idle,
    lastUpdated,
  } = useAppSelector(({ feed }) => feed);
  const personalizations = useMemo(
    () => personalizationsObj,
    [personalizationsObj]
  );

  const {
    addItemToCartContext,
    removeItemFromCartContext,
    currentPersonalization,
    resetPersonalizationContext,
    resetCartContext,
    setPersonalizationContext,
  } = usePersonalizationContext();

  return {
    loading: status === NetworkStatus.loading,
    status,
    lastUpdated,
    personalizations,
    recipients,
    recipientVerifications,
    addItemToCartContext,
    removeItemFromCartContext,
    resetCartContext,
    currentPersonalization,
    resetPersonalizationContext,
    setPersonalizationContext,
  };
};
