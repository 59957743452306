import { Draft } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { APIEndpoints } from "~src/api";
import {
  APIResponseStatus,
  RankedFeedParams,
  RankedFeedResponse,
  RankedItem,
} from "~src/api/typings";
import {
  DEFAULT_RANKED_FEED,
  RANKED_FEED_TYPES,
  REFRESH_INTERVAL,
} from "~src/constants";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import {
  getUpdatedStartMyEmailCart,
  icebreakerCount,
  mainTrigger,
} from "~src/utils";

import { parseRankedFeed } from "./feed/helpers";
import {
  IcebreakersData,
  PersonalizationRankedData,
  ValueProps,
} from "./typings";
import { valuePropsActions, valuePropsApiSlice } from "./";

export const rankedFeedApiSlice = createApi({
  reducerPath: "rankedFeedApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.BACKEND_API}/api/` }),
  endpoints: (builder) => ({
    getRankedFeed: builder.query<IcebreakersData, RankedFeedParams>({
      query: ({ email, chromeId }) => ({
        url: APIEndpoints.rankedFeed,
        params: { email, chrome_id: chromeId },
      }),
      transformErrorResponse: (response, _meta, arg) => {
        parseAndLogError(response, ErrorIdentifiers.API_ERROR, {
          email: arg.email,
          request: APIEndpoints.rankedFeed,
        });
        return {
          alert: "",
          caption: "",
          cta: "",
          header: "",
          rankedFeed: DEFAULT_RANKED_FEED,
        };
      },
      transformResponse: (response: RankedFeedResponse, _meta) => ({
        alert: response?.alert || "",
        caption: response?.caption || "",
        cta: response?.cta || "",
        header: response?.header || "",
        rankedFeed:
          response?.ranked_feed !== undefined
            ? parseRankedFeed(response)
            : DEFAULT_RANKED_FEED,
      }),
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, dispatch, updateCachedData }
      ) {
        const {
          data: { rankedFeed },
        } = await cacheDataLoaded;
        if (icebreakerCount(rankedFeed) === 0) {
          return;
        }
        const { data } = await dispatch(
          valuePropsApiSlice.endpoints.getValueProps.initiate(arg)
        );
        if (data?.valueProps && data?.status === APIResponseStatus.success) {
          const dataMap = new Map(
            data.valueProps.map((item) => [item.id, item])
          );
          let updatedRankedFeed: PersonalizationRankedData = { ...rankedFeed };
          const categories: ValueProps = {};
          const userCategories: ValueProps = {};
          updateCachedData((draft) => {
            updatedRankedFeed = { ...draft.rankedFeed };
            for (const type of RANKED_FEED_TYPES) {
              updatedRankedFeed[type].items = draft.rankedFeed[type].items.map(
                (item: Draft<RankedItem>) => {
                  const found = dataMap.get(item.id);
                  if (found) {
                    categories[type] = {
                      ...(categories[type] || {}),
                      [mainTrigger(item.triggers)]: found.value || "",
                    };
                    userCategories[type] = {
                      ...(userCategories[type] || {}),
                      [mainTrigger(item.triggers)]: found.user_value || "",
                    };
                    return {
                      ...item,
                      value: found.value || "",
                      user_value: found.user_value || "",
                    };
                  }
                  return item;
                }
              );
            }
            draft.rankedFeed = updatedRankedFeed;
          });
          dispatch(
            valuePropsActions.setCurrentValueProps({
              generated: getUpdatedStartMyEmailCart(
                updatedRankedFeed,
                categories,
                true
              ),
              status: APIResponseStatus.success,
              type: "value_prop_generation",
              email: arg.email,
            })
          );
          dispatch(
            valuePropsActions.setCurrentUserValueProps({
              generated: getUpdatedStartMyEmailCart(
                updatedRankedFeed,
                userCategories,
                false
              ),
              status: APIResponseStatus.success,
              type: "value_prop_generation",
              email: arg.email,
            })
          );
        }
      },
      keepUnusedDataFor: REFRESH_INTERVAL.FEED,
    }),
  }),
});

export const {
  endpoints: { getRankedFeed },
  reducer: rankedFeedApiReducer,
  useGetRankedFeedQuery,
} = rankedFeedApiSlice;
