import { createAsyncThunk } from "@reduxjs/toolkit/react";
import { sanitize } from "dompurify";

import { openChat, askChat, analyzeChat } from "~src/api";
import * as askChatApi from "~src/api/typings/askChat";
import { editEmail } from "~src/api/editEmail";
import { chatsAction, RootState, AppDispatch } from "~src/redux";
import {
  InitializeChatData,
  InitializeChatArgs,
  NetworkStatus,
  RunChatData,
  RunChatArgs,
} from "~src/redux/typings";
import { CHAT_GPT_ERRORS } from "~src/strings";
import {
  newChatMessage,
  isFixItAllTrigger,
  createChatEditEmailData,
  cleanResponseText,
  checkForSuggestions,
  extractBodySubject,
} from "~src/utils";
import { isAxiosError } from "axios";
import { isLavenderAPIError } from "~src/typeGuards";

const { FALLBACK_ANALYZE_CHAT, FALLBACK_ASK_CHAT } = CHAT_GPT_ERRORS;

const createTypedAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

export const chat = createTypedAsyncThunk<
  InitializeChatData | undefined,
  InitializeChatArgs
>(
  "chat/chat",
  async (
    { lvIdentifier, question },
    { getState }
  ): Promise<InitializeChatData | undefined> => {
    const {
      chats,
      config: { chromeId },
    } = getState();
    const existingChat = chats[lvIdentifier];
    const message = newChatMessage(askChatApi.ChatRole.User, question);
    if (existingChat) {
      const { chatThreadId, status } = existingChat;
      if (status === NetworkStatus.loading || !chatThreadId) {
        return;
      }
      return { chatThreadId, messages: [...existingChat.messages, message] };
    } else {
      const { chat_thread_id } = await openChat(chromeId);
      return { chatThreadId: chat_thread_id, messages: [message] };
    }
  }
);

export const run = createTypedAsyncThunk<RunChatData | undefined, RunChatArgs>(
  "chat/run",
  async (
    {
      autoSuggestion,
      content,
      recs,
      lvIdentifier,
      question,
      searchWeb,
      location,
      timezone,
    },
    thunkApi
  ): Promise<RunChatData | undefined> => {
    const {
      config: { chromeId },
      features,
    } = thunkApi.getState();
    let data: askChatApi.Response;
    let hasError = false;
    let chatResponse: InitializeChatData | undefined;

    try {
      chatResponse = await thunkApi
        .dispatch(
          chat({
            lvIdentifier,
            question,
          })
        )
        .unwrap();

      if (!chatResponse) {
        return;
      }
    } catch (e) {
      if (isAxiosError(e)) {
        throw new Error(FALLBACK_ASK_CHAT);
      } else {
        throw new Error(FALLBACK_ASK_CHAT);
      }
    }

    thunkApi.dispatch(chatsAction.setLoading({ lvIdentifier }));

    if (isFixItAllTrigger(question, features) && content && recs) {
      try {
        const controller = new AbortController();
        const payload = createChatEditEmailData(chromeId, content, recs);
        data = await editEmail(payload, controller.signal);
      } catch (e) {
        hasError = true;
        if (isAxiosError(e)) {
          throw new Error(FALLBACK_ASK_CHAT);
        } else {
          throw new Error(FALLBACK_ASK_CHAT);
        }
      }
    } else {
      try {
        data = await askChat({
          autoSuggestion,
          chromeId,
          searchWeb,
          messages: chatResponse.messages,
          chatThreadId: chatResponse.chatThreadId,
          location,
          timezone,
        });
      } catch (e) {
        if (isAxiosError(e)) {
          hasError = true;
          throw new Error(e.response?.data.message || FALLBACK_ASK_CHAT);
        } else {
          throw new Error(FALLBACK_ASK_CHAT);
        }
      }
    }

    if (hasError || isLavenderAPIError(data)) {
      return;
    }

    const parsedContent = askChatApi.isAskChatResponse(data)
      ? cleanResponseText(data.content, true)
      : `Subject: ${sanitize(data.subject)}\n\n${cleanResponseText(data.email, true)}`;
    const sanitizedQuery =
      askChatApi.isAskChatResponse(data) && data.query
        ? sanitize(data.query)
        : undefined;
    thunkApi.dispatch(
      chatsAction.addAssistantMessage({
        lvIdentifier,
        ...data,
        content: parsedContent,
        query: sanitizedQuery,
      })
    );

    const isSuggestion = checkForSuggestions(question, features);
    try {
      if (
        (isSuggestion &&
          data.content &&
          extractBodySubject(data.content).subject) ||
        !data.content ||
        !!autoSuggestion
      ) {
        const { score, recs } = await analyzeChat({
          chromeId,
          response: parsedContent,
          // This flag isn't actually being added to features yet in old extension
          source: "chat_gpt",
        });
        return { score, recs };
      } else {
        thunkApi.dispatch(chatsAction.finishLoading({ lvIdentifier }));
      }
    } catch (e) {
      if (isAxiosError(e)) {
        throw new Error(FALLBACK_ANALYZE_CHAT);
      } else {
        throw new Error(FALLBACK_ANALYZE_CHAT);
      }
    }
  }
);
