import { ButtonProps } from "@lavender-ai/lav-components";
import { useMemo } from "react";

import { ModalType } from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  CompanySubTab,
  PersonalizationTab,
  ProfileSubTab,
} from "~src/component/typings";
import { usePanelTabContext } from "~src/contexts";
import { useCRM } from "~src/customHooks";
import { NO_DATA_MESSAGES } from "~src/strings";

export type SupportedCTAButtons = Extract<
  ModalType | CompanySubTab | ProfileSubTab,
  | ProfileSubTab.SOCIAL_FEED
  | ProfileSubTab.PODCASTS
  | ProfileSubTab.EXPERIENCE
  | ProfileSubTab.ACTIVITY
  | ProfileSubTab.GONG
  | ProfileSubTab.HUBSPOT
  | ProfileSubTab.SALESFORCE
  | CompanySubTab.TECH_STACK
  | CompanySubTab.NEWS
>;

export const useCTATabNavigationActions: () => Record<
  SupportedCTAButtons,
  () => void
> = () => {
  const { setPanelTabContext } = usePanelTabContext<
    PersonalizationTab,
    ProfileSubTab | CompanySubTab
  >();

  return {
    [ProfileSubTab.SOCIAL_FEED]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.PROFILE,
        subTab: ProfileSubTab.SOCIAL_FEED,
      });
    },
    [ProfileSubTab.PODCASTS]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.PROFILE,
        subTab: ProfileSubTab.PODCASTS,
      });
    },
    [ProfileSubTab.EXPERIENCE]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.PROFILE,
        subTab: ProfileSubTab.EXPERIENCE,
      });
    },
    [ProfileSubTab.ACTIVITY]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.PROFILE,
        subTab: ProfileSubTab.ACTIVITY,
      });
    },
    [ProfileSubTab.GONG]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.PROFILE,
        subTab: ProfileSubTab.GONG,
      });
    },
    [ProfileSubTab.HUBSPOT]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.PROFILE,
        subTab: ProfileSubTab.HUBSPOT,
      });
    },
    [ProfileSubTab.SALESFORCE]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.PROFILE,
        subTab: ProfileSubTab.SALESFORCE,
      });
    },
    [CompanySubTab.TECH_STACK]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.COMPANY,
        subTab: CompanySubTab.TECH_STACK,
      });
    },
    [CompanySubTab.NEWS]: () => {
      setPanelTabContext({
        tab: PersonalizationTab.COMPANY,
        subTab: CompanySubTab.NEWS,
      });
    },
  };
};

export const useSecondaryTextGenerator = () => {
  return useMemo(() => {
    return Object.values(NO_DATA_MESSAGES)[
      Math.floor(Math.random() * Object.keys(NO_DATA_MESSAGES).length)
    ];
  }, []);
};

export const CTATabToButtonPropsMap: Record<SupportedCTAButtons, ButtonProps> =
  {
    [ProfileSubTab.SOCIAL_FEED]: {
      children: "Social Posts",
      prefix: "IconBrandLinkedin",
    },
    [ProfileSubTab.PODCASTS]: { children: "Podcasts", prefix: "IconBroadcast" },
    [ProfileSubTab.EXPERIENCE]: {
      children: "Experience",
      prefix: "IconBriefcase",
    },
    [ProfileSubTab.ACTIVITY]: { children: "Activity", prefix: "IconActivity" },
    [ProfileSubTab.GONG]: { children: "Gong", prefix: "IconGongLogo" },
    [ProfileSubTab.HUBSPOT]: { children: "Hubspot", prefix: "IconHubspotLogo" },
    [ProfileSubTab.SALESFORCE]: {
      children: "Salesforce",
      prefix: "IconSalesforce",
    },
    [CompanySubTab.TECH_STACK]: { children: "Tech Stack", prefix: "IconStack" },
    [CompanySubTab.NEWS]: { children: "News & Events", prefix: "IconNews" },
  };

export const useGetRandomCTAButtonNames = (): SupportedCTAButtons[] => {
  const { subTab = "" } = usePanelTabContext<
    PersonalizationTab,
    ProfileSubTab | CompanySubTab
  >();
  const { hasGongAccess, hasSalesforceAccess, hasHubspotAccess } =
    useCRM().currentCRM;

  const ctaButtonNames = Object.keys(
    CTATabToButtonPropsMap
  ) as SupportedCTAButtons[];

  if (subTab === "") {
    ctaButtonNames.splice(ctaButtonNames.indexOf(CompanySubTab.NEWS), 1);
  }

  // Remove the current subTab from the list of CTA buttons
  if (
    Object.keys(CTATabToButtonPropsMap).includes(subTab as SupportedCTAButtons)
  ) {
    ctaButtonNames.splice(
      ctaButtonNames.indexOf(subTab as SupportedCTAButtons),
      1
    );
  }

  // Remove the Gong button if the user does not have access to Gong
  if (!hasGongAccess) {
    ctaButtonNames.splice(ctaButtonNames.indexOf(ProfileSubTab.GONG), 1);
  }

  // Remove the Salesforce button if the user does not have access to Salesforce
  if (!hasSalesforceAccess) {
    ctaButtonNames.splice(ctaButtonNames.indexOf(ProfileSubTab.SALESFORCE), 1);
  }

  // Remove the Hubspot button if the user does not have access to Hubspot
  if (!hasHubspotAccess) {
    ctaButtonNames.splice(ctaButtonNames.indexOf(ProfileSubTab.HUBSPOT), 1);
  }

  const randomCTAButtonNames = [] as SupportedCTAButtons[];
  while (randomCTAButtonNames.length < 3) {
    const randomIndex = Math.floor(
      Math.random() * Object.values(ctaButtonNames).length
    );
    randomCTAButtonNames.push(ctaButtonNames[randomIndex]);
    ctaButtonNames.splice(randomIndex, 1);
  }

  return randomCTAButtonNames;
};
