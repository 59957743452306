import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GenerateIntroState, PredictLeadEvent } from "./frameworkAi";
import { fetchFrameworksAI } from "../../api/aiSuggestion";
import { NetworkStatus } from "../typings";

const initialState: GenerateIntroState = {} as GenerateIntroState;

export const getGenerateIntro = createAsyncThunk(
  "getGenerateIntro",
  async ({
    lvIdentifier,
    chromeId,
    category,
    object,
    ignore_cache,
    email,
  }: {
    lvIdentifier: lvIdentifier;
    chromeId: chromeId;
    category: string;
    object: PredictLeadEvent;
    ignore_cache: boolean;
    email: string;
  }) => {
    const response = await fetchFrameworksAI(
      chromeId,
      category,
      object,
      ignore_cache,
      email
    );
    return {
      lvIdentifier,
      data: response.data,
      error: response.data.message,
    };
  }
);

const generateIntroSlice = createSlice({
  name: "generateIntro",
  initialState,
  reducers: {
    setGenerateIntro: (
      state,
      {
        payload: { lvIdentifier, category, object },
      }: {
        payload: {
          lvIdentifier: lvIdentifier;
          category: string;
          object: PredictLeadEvent;
        };
      }
    ) => {
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          category,
          object,
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGenerateIntro.pending, (state, action) => {
        state[action.meta.arg.lvIdentifier].getGenerateIntroStatus =
          NetworkStatus.loading;
      })
      .addCase(getGenerateIntro.fulfilled, (state, action) => {
        state[action.payload.lvIdentifier].suggestions = [];
        action.payload.data.snippets?.forEach((snippet) => {
          state[action.payload.lvIdentifier].suggestions.push(snippet.Template);
        });
        state[action.payload.lvIdentifier].getGenerateIntroStatus =
          NetworkStatus.success;
      })
      .addCase(getGenerateIntro.rejected, (state, action) => {
        state[action.meta.arg.lvIdentifier].getGenerateIntroStatus =
          NetworkStatus.failed;
        state[action.meta.arg.lvIdentifier].getGenerateIntroError =
          action.error.message ?? "";
      });
  },
});

export const generateIntroAction = generateIntroSlice.actions;
export const generateIntroReducer = generateIntroSlice.reducer;
