import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from "@reduxjs/toolkit";

import { getOpenEmailTotal } from "../api";
import {
  EmailIdentifier,
  NetworkStatus,
  OpenEmailTrackingState,
} from "./typings";

const initialState = {} as OpenEmailTrackingState;

export const fetchOpenEmailTotals = createAsyncThunk(
  "openEmailTracking/fetchOpenEmailTotals",
  async ({
    chromeId,
    lvIdentifier,
  }: {
    chromeId: chromeId;
    lvIdentifier: lvIdentifier;
  }) => {
    const response = await getOpenEmailTotal(chromeId, lvIdentifier);
    return {
      lvIdentifier,
      openTotal: response.data?.total_opens,
      status: response.data?.message,
      rows: response.data.opens || [],
      error: response.data.error,
    };
  }
);

const openEmailTrackingSlice = createSlice({
  name: "openEmailTracking",
  initialState,
  reducers: {
    initialState: (state, action: PayloadAction<EmailIdentifier>) => {
      if (action.payload.lvIdentifier) {
        state[action.payload.lvIdentifier] = {
          openTotal: undefined,
          status: NetworkStatus.idle,
          error: "",
          isOpen: false,
          rows: [],
        };
      }
    },
    close: (
      state: OpenEmailTrackingState,
      action: PayloadAction<EmailIdentifier>
    ) => {
      const { lvIdentifier } = action.payload;

      if (lvIdentifier && state[lvIdentifier]) {
        state[lvIdentifier]!.isOpen = false;
      }
    },
    open: (
      state: OpenEmailTrackingState,
      action: PayloadAction<EmailIdentifier>
    ) => {
      const { lvIdentifier } = action.payload;

      if (lvIdentifier && state[lvIdentifier]) {
        state[lvIdentifier]!.isOpen = true;
      }
    },
    toggle: (
      state: OpenEmailTrackingState,
      action: PayloadAction<EmailIdentifier>
    ) => {
      const { lvIdentifier } = action.payload;

      if (lvIdentifier && state[lvIdentifier]) {
        state[lvIdentifier]!.isOpen = !state[lvIdentifier]!.isOpen;
      }
    },
  },
  extraReducers(builder) {
    builder
      // INFO: open email tracking
      .addCase(fetchOpenEmailTotals.pending, (state) => {
        state.status = NetworkStatus.loading;
      })
      .addCase(fetchOpenEmailTotals.fulfilled, (state, { payload }) => {
        state.status = NetworkStatus.success;
        state[payload.lvIdentifier] = {
          openTotal: payload.openTotal,
          status: payload.status,
          rows: payload.rows,
          error: payload.error,
          isOpen: false,
        };
      })
      .addCase(fetchOpenEmailTotals.rejected, (state, action) => {
        state.status = NetworkStatus.failed;
        const lvIdentifier = action.meta.arg.lvIdentifier;
        if (state[lvIdentifier]) {
          state[lvIdentifier]!.error = action.error.message ?? "";
        }
      });
  },
});

export const openEmailTrackingReducer = openEmailTrackingSlice.reducer;
export const openEmailTrackingAction = openEmailTrackingSlice.actions;
