import { Collapse, CollapseProps } from "antd";

import { IconButton } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";

const panelStyle: React.CSSProperties = {
  background: "var(--color-base-White)",
  borderRadius: "16px",
  paddingTop: "4px",
  paddingBottom: "4px",
  textAlign: "left",
  width: "100%",
};

interface Props extends Pick<CollapseProps, "expandIcon"> {
  collapsible?: boolean;
  initialCollapsed?: boolean;
  item: ArrayElement<NonUndefined<CollapseProps["items"]>>;
}

export const ItemCollapse = ({
  collapsible = true,
  initialCollapsed,
  expandIcon = ({ isActive }) => CollapseIconButton({ isActive, collapsible }),
  item,
}: Props) => {
  return (
    <Collapse
      bordered={false}
      collapsible={!collapsible ? "disabled" : undefined}
      defaultActiveKey={
        initialCollapsed && item.key !== undefined
          ? item.key.toString()
          : undefined
      }
      expandIcon={expandIcon}
      expandIconPosition="end"
      ghost
      items={[item]}
      style={panelStyle}
    />
  );
};

interface CollapseIconButtonProps {
  isActive: boolean | undefined;
  collapsible: boolean;
}

const CollapseIconButton = ({
  isActive,
  collapsible,
}: CollapseIconButtonProps) => {
  return isActive ? (
    <IconButton
      icon={LavenderIcon.IconChevronUp}
      size="small"
      state={!collapsible ? "disabled" : undefined}
    />
  ) : (
    <IconButton
      icon={LavenderIcon.IconChevronDown}
      size="small"
      state={!collapsible ? "disabled" : undefined}
    />
  );
};
