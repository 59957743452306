export const ensureFullUrl = (url: string) => {
  if (!url || url === "https://") {
    // Data bug
    return "";
  }

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "https://" + url;
  }

  return url;
};
