import styled from "styled-components";

export const NoDataContainer = styled.div`
  display: flex;
  padding: var(--size-32, 32px) var(--size-24, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
`;

export const NoDataHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-24, 24px);
  align-self: stretch;
`;

export const EnvelopeErrorImg = styled.img`
  rotate: -3deg;
`;

export const Divider = styled.div`
  height: 1px;
  align-self: stretch;
  background: var(--color-neutral-purple-100, #e9ebf2);
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const CTAButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  width: 300px;
  align-self: center;
`;
