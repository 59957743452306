import { v4 as uuidv4 } from "uuid";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { postEmailAnalysis } from "../api";
import {
  EmailAnalysis,
  EmailAnalysisState,
  NetworkStatus,
  SystemData,
  WritingData,
} from "./typings";

const initialState: EmailAnalysisState = {};

export const fetchEmailAnalysis = createAsyncThunk(
  "emailAnalysis/fetchEmailAnalysis",
  async ({
    lvIdentifier,
    systemData,
    writingData,
  }: {
    lvIdentifier: lvIdentifier;
    systemData: SystemData;
    writingData: WritingData;
  }) => {
    if (window.email_data?.analysisData) {
      const analysisData = window?.email_data?.analysisData;
      window.email_data.analysisData = undefined;
      return {
        lvIdentifier,
        data: analysisData,
      };
    }

    const response = await postEmailAnalysis(
      lvIdentifier,
      systemData,
      writingData
    );

    // INFO: add unique id to each recommendation, remove once we're getting from the backend
    response.data.recommendations =
      response.data.recommendations?.map((recommendation) => ({
        ...recommendation,
        id: "rec-" + uuidv4(),
      })) || [];

    return { lvIdentifier, data: response.data };
  }
);
const emailAnalysisSlice = createSlice({
  initialState,
  name: "emailAnalysis",
  reducers: {
    initialize: (
      state: EmailAnalysisState,
      { payload: lvIdentifier }
    ): EmailAnalysisState => {
      state[lvIdentifier] = {
        analysis: {
          long_sentences: 0, // add this property
          hedge_words: 0, // add this property
          adverbs: 0, // add this property
          i_we_you_ratio: 0,
          you_count: 0,
          i_count: 0,
          deidentified_body: "",
          deidentified_subject: "",
          body_exclamation_count: 0,
          first_word: "",
          subject: { word_count: 0, needs_work: false },
          grade: "",
          ceq: -1,
          dynamic_ceq: -1,
          clarity: 0,
          formality: 0,
          tones: {
            analytical: 0,
            angry: 0,
            confident: 0,
            friendly: 0,
            happy: 0,
            sad: 0,
            scared: 0,
            unsure: 0,
          },
          tone_sentences: {
            analytical: 0,
            angry: 0,
            confident: 0,
            friendly: 0,
            happy: 0,
            sad: 0,
            scared: 0,
            unsure: 0,
          },
          reading_time: 0,
          writing_time: 0,
          body_length: 0,
          sentence_count: 0,
          sentiment: "",
          top_tones: [],
          spam_arr: [],
        },
        highlights: {
          LongSentences: null,
          HardWords: null,
          LongWords: null,
          UncommonWords: null,
          SpamWords: null,
          FormalitySentences: {},
          ToneSentences: [],
          HedgeWords: null,
          Adverbs: null,
          Cliches: null,
        },
        recommendations: null,
        hasUpdates: false,
        stage: "cold",
        scoringModel: "best practices",
        status: NetworkStatus.idle,
        hasPersonalizedScore: false,
      };
      return state;
    },
    finishedUpdating: (state, { payload: lvIdentifier }) => {
      return {
        ...state,
        [lvIdentifier]: { ...state[lvIdentifier], hasUpdates: false },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchEmailAnalysis.pending,
        (state: EmailAnalysisState, action) => {
          state[action.meta.arg.lvIdentifier].status = NetworkStatus.loading;
        }
      )
      .addCase(
        fetchEmailAnalysis.fulfilled,
        (state: EmailAnalysisState, { payload }) => {
          state[payload.lvIdentifier] = {
            ...payload.data,
            status: NetworkStatus.success,
            hasUpdates: true,
          } as EmailAnalysis;
        }
      )
      .addCase(
        fetchEmailAnalysis.rejected,
        (state: EmailAnalysisState, action) => {
          state[action.meta.arg.lvIdentifier].status = NetworkStatus.failed;
        }
      );
  },
});

//TODO : Fill out this function to fetch the data from the backend, and return lvIdentifier with the data
// create this as a middleware
export const shouldFetchEmailAnalysis = () => {
  // TODO: check all api params are valid before calling fetchEmailAnalysis()
  // check for the following values in the redux store:
  // 1. subject
  // 2. body
  // 3. email address
  // 4. hasUpdates is false
  // 5. email body writing is paused
  // 6. email subject writing is paused
  // 7. email address writing is paused
};

export const emailAnalysisReducer = emailAnalysisSlice.reducer;
export const emailAnalysisAction = emailAnalysisSlice.actions;
