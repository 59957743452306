import { useEffect } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { EmailCoachView } from "~src/contexts";

import { useLvIdentifier, useShouldDisplayPaywall } from "~src/customHooks";
import { Header, Category, NoEmailData, WhatsDoneRight } from "./components";
import { getBodyLength, getWritingData } from "../../utils";
import { MIN_EMAIL_BODY_WORD_COUNT } from "~src/constants";
import { Spinner } from "~src/component/Lottie";
import { EmailAnalysis, EmailWritingData, NetworkStatus, Recommendation } from "~src/redux/typings";
import { emailAction, useAppDispatch, useAppSelector } from "~src/redux";

interface Props {
  view: EmailCoachView;
  analyticsData: EmailAnalysis;
  emailWritingData: EmailWritingData;
}

export const EmailCoach = ({ view, analyticsData, emailWritingData }: Props) => {
  const lvIdentifier = useLvIdentifier();
  const shouldDisplayPaywall = useShouldDisplayPaywall("emailCoach");
  const dispatch = useAppDispatch();

  // Todo: if email is empty
  const apiStatus = useAppSelector((state) => state.emailAnalysis[lvIdentifier].status);

  useEffect(() => {
    if (shouldDisplayPaywall) {
      return;
    }

    // at this point, the coach has been "used" (used == opened during email writing)
    dispatch(emailAction.tagCoachUsed({ id: lvIdentifier }));
  }, [dispatch, lvIdentifier, shouldDisplayPaywall]);

  if (shouldDisplayPaywall) {
    return <NoEmailData />;
  }
  if (apiStatus === NetworkStatus.failed && analyticsData.analysis.dynamic_ceq < 0) {
    return <NoEmailData />;
  }

  const writingData = getWritingData(lvIdentifier);
  const bodyLength = getBodyLength(writingData.body);

  if (bodyLength < MIN_EMAIL_BODY_WORD_COUNT) {
    return <NoEmailData />;
  }

  const loading = apiStatus === NetworkStatus.idle || analyticsData.analysis.dynamic_ceq < 0;

  const recommendations: Recommendation[] = analyticsData?.recommendations ?? [];

  return loading ? (
    <EmailCoachLoading vertical={true} align="center" justify="center">
      <Spinner width={60} height={60} />
    </EmailCoachLoading>
  ) : (
    <EmailCoachBody vertical={true} align="center" justify="space-between">
      <Header
        ceq={analyticsData?.analysis?.dynamic_ceq}
        writingTime={emailWritingData?.writingStartTime}
        readingTime={analyticsData?.analysis?.reading_time}
      />
      <Category
        recommendations={recommendations}
        score={analyticsData?.analysis?.dynamic_ceq}
        type="danger"
        view={view}
      />
      <Category type="warning" recommendations={recommendations} view={view} />
      <WhatsDoneRight recommendations={recommendations} />
    </EmailCoachBody>
  );
};

const EmailCoachLoading = styled(Flex)`
  height: 100%;
  padding: 0 20px;
  min-width: 335px;
`;
const EmailCoachBody = styled(Flex)`
  padding: 20px 20px 0;
  min-width: 335px;
  overflow: hidden;
`;
