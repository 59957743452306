// APIEndpoints already have a base prefix of /api defined in their slice with RTK Query
export enum APIEndpoints {
  humantics = "humantics",
  linkedInPosts = "linkedin-posts",
  podcasts = "podcasts",
  rankedFeed = "ranked-feed",
  startMyEmail = "start-my-email",
  valueProps = "value-props",
  // API/PROFILE
  profileSettings = "profile/settings",
  // API/USER
  userSettings = "user/settings",
  userValueProps = "user/value-props",
}

export enum HEADERS {
  applicationJson = "application/json",
  formUrlEncodeded = "application/x-www-form-urlencoded; charset=UTF-8",
}

export enum URLS {
  additionalCompanyData = `/api/additional-company-data`,
  aiSuggestion = `/api/GPT3`,
  analyzeChat = `/api/analyze-chat-response`,
  askChat = `/api/ask-chat`,
  checkForLavenderAnywhereUser = `/api/check-for-lavender-everywhere-user`,
  config = `/api/extension-config`,
  crmData = `/iframe/crm-data`,
  crmUser = `/api/check-for-crm-user`,
  editEmail = `/api/GPT/edit-email`, // API/GPT
  emailAnalysis = `/api/email-analysis`,
  emailSummary = `/api/GPT/email-summary`, // API/GPT
  emailVerification = `/api/email-verification`,
  featureFlag = `/api/feature-flag-2`,
  frameworksAi = `/api/frameworks-ai`,
  getSetting = `/api/get-setting`,
  gifs = `/api/get-gifs`,
  mailboxUser = `/api/check-for-mailbox-user`,
  news = `/iframe/news`,
  onboarding = `/api/onboarding-feature-used`,
  openChat = `/api/lavender-chat-gpt`,
  openEmailTotal = `/api/get-pixel-2`,
  pixelSent2 = `/api/pixel-sent-2`,
  profile = `/iframe/profile-v2`,
  rankedFeed = `/api/ranked-feed`,
  readTheRoom = `/api/read-room-2`,
  segmentEvent = `/segment-handler`,
  setNotes = `/api/set-notes`,
  techStack = `/api/tech-stack-data`,
  toggleSetting = `/iframe/toggle-setting-2`, // IFRAME
  trendingGifs = `/api/trending-gifs`,
  tweets = `/iframe/tweets-data`,
  user = `/api/user`, // API/USER
}
