import { store } from "~src/redux/store";
import { RemoveReplyFWDNodes } from "~src/initializers";

const createPixelTrackingURL = (lvIdentifier: lvIdentifier) => {
  const state = store.getState();
  const pixelURL = state.config.settings?.pixelURL;
  const url = new URL(`${pixelURL}/p.png?`);
  url.searchParams.set("pixel_id", lvIdentifier);
  return url.toString();
};

export const setupTrackingPixelListener = (
  emailContainer: HTMLElement,
  lvIdentifier: lvIdentifier
) => {
  const state = store.getState();
  const selectors = state.config.settings?.selectors;
  const platform = state.config.settings?.platform;

  if (!selectors || !platform) return;

  const { openEmailPixelInsertionSelector: emailBodySelector } = selectors;

  if (isAllowedForOpenTracking(platform) && emailBodySelector) {
    emailContainer.addEventListener("input", () => {
      // INFO: emailBody can be null if the email is not finish loaded yet
      const emailBody = emailContainer.querySelector(emailBodySelector);
      if (!emailBody) return;
      const pixelDom = emailBody.querySelector(
        `img[alt='ltp|${lvIdentifier}']`
      );
      if (!pixelDom) {
        const imageDom = document.createElement("img");
        imageDom.style.cssText = `
          display: none !important;
          visibility: hidden !important;
          width: 0 !important;
          height: 0 !important;
          border: none !important;
          padding: 0 !important;
          margin: 0 !important;
          outline: none !important;
          font-size: 0 !important;
          line-height: 0 !important;
          text-indent: 0 !important;
          background: transparent !important;
          z-index: -10000 !important;
        `;
        imageDom.classList.add("lavender-pixel");
        imageDom.alt = `ltp|${lvIdentifier}`;
        imageDom.src = createPixelTrackingURL(lvIdentifier);
        emailBody.append(imageDom);
      }
    });
  }
};

const isAllowedForOpenTracking = (platform: string): boolean => {
  const TRACKING_ALLOW_LIST = ["gmail", "outlook"];
  return TRACKING_ALLOW_LIST.includes(platform);
};

// extractExistingPixel looks for and finds an existing pixel id in an email
// if one doesn't exist, returns null
export function extractExistingPixel(
  emailContainer: HTMLElement
): lvIdentifier | null {
  // find all image elements whose `alt` attribute start with `ltp|`
  const emailContainerClone = emailContainer.cloneNode(true) as HTMLElement;
  RemoveReplyFWDNodes(emailContainerClone);

  const foundPixels = emailContainerClone.querySelectorAll(`img[alt^="ltp|"]`);
  if (foundPixels.length < 1) {
    return null;
  }

  // the first one (top-most in DOM tree) will be the most recent as we prepend the new pixel
  const raw = foundPixels[0].attributes.getNamedItem("alt");
  if (raw === null) {
    return null;
  }

  // tracking pixel format: ltp|000000000000
  const split = raw.value.split("|");
  if (split.length !== 2) {
    return null;
  }

  return split[1];
}
