import { INewsContext, useNewsContext } from "~src/contexts";
import { useAppSelector } from "~src/redux";
import { NetworkStatus, RecipientNewsMap } from "~src/redux/typings";

interface IUseNews extends Omit<INewsContext, "currentNews"> {
  loading: boolean;
  status: NetworkStatus;
  lastUpdated: number | null;
  news: RecipientNewsMap;
}

export const useNews = (): IUseNews => {
  const {
    news,
    status = NetworkStatus.idle,
    lastUpdated,
  } = useAppSelector(({ feed }) => feed);

  const { resetNewsContext, setNewsContext } = useNewsContext();

  return {
    loading: status === NetworkStatus.loading,
    status,
    lastUpdated,
    news,
    resetNewsContext,
    setNewsContext,
  };
};
