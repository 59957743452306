import { Middleware, combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { apiReducer, apiSlice } from "./api";
import { chatsReducer } from "./chat";
import { configReducer } from "./config";
import { localStorageReducer } from "./localStorage";
import { featuresReducer } from "./feature";
import { feedReducer } from "./feed";
import { mainPanelReducer } from "./mainPanel";
import { emailDataReducer } from "./emailData";
import { emailAnalysisReducer } from "./emailAnalysis";
import { userAccountReducer } from "./userAccount";
import { settingsCarouselReducer } from "./settingsCarousel";
import { openEmailTrackingReducer } from "./openEmailTracking";
import { readTheRoomEmailSummaryReducer } from "./readTheRoomEmailSummary";
import { selectedRecommendationReducer } from "./selectedRecommendation";
import { highlightsReducer } from "./highlights";
import { fixItAllReducer } from "./fixItAll";
import { startMyEmailReducer } from "./startMyEmail";
import { verificationsReducer } from "./verification";
import {
  linkedInPostsApiReducer,
  linkedInPostsApiSlice,
} from "./linkedInPostsApi";
import { podcastsApiReducer, podcastsApiSlice } from "./podcastsApi";
import { profileApiReducer, profileApiSlice } from "./profileApi";
import { rankedFeedApiReducer, rankedFeedApiSlice } from "./rankedFeedApi";
import { generateIntroReducer } from "./frameworkAi/actions";
import { lavenderAnywhereReducer } from "./lavenderAnywhere";
import { valuePropsReducer } from "./valueProps";
import { valuePropsApiReducer, valuePropsApiSlice } from "./valuePropsApi";

const persistConfig = {
  key: "lavenderLocalStorage",
  storage,
};

export const persistedReducer = persistReducer(
  persistConfig,
  localStorageReducer
);

const rootReducer = combineReducers({
  chats: chatsReducer,
  config: configReducer,
  features: featuresReducer,
  feed: feedReducer,
  mainPanel: mainPanelReducer,
  emailData: emailDataReducer,
  emailAnalysis: emailAnalysisReducer,
  localStorage: persistedReducer,
  userAccount: userAccountReducer,
  settingsCarousel: settingsCarouselReducer,
  openEmailTracking: openEmailTrackingReducer,
  readTheRoomEmailSummary: readTheRoomEmailSummaryReducer,
  selectedRecommendation: selectedRecommendationReducer,
  highlights: highlightsReducer,
  fixItAll: fixItAllReducer,
  verifications: verificationsReducer,
  generateIntro: generateIntroReducer,
  [apiSlice.reducerPath]: apiReducer,
  [linkedInPostsApiSlice.reducerPath]: linkedInPostsApiReducer,
  [podcastsApiSlice.reducerPath]: podcastsApiReducer,
  [rankedFeedApiSlice.reducerPath]: rankedFeedApiReducer,
  [profileApiSlice.reducerPath]: profileApiReducer,
  [valuePropsApiSlice.reducerPath]: valuePropsApiReducer,
  lavenderAnywhere: lavenderAnywhereReducer,
  startMyEmail: startMyEmailReducer,
  valueProps: valuePropsReducer,
});

const middleware: Middleware[] = [
  apiSlice.middleware,
  linkedInPostsApiSlice.middleware,
  podcastsApiSlice.middleware,
  profileApiSlice.middleware,
  rankedFeedApiSlice.middleware,
  valuePropsApiSlice.middleware,
];

const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middleware),
  });
};
export const store = makeStore();

// INFO: know issue with writing unit test for redux-persist
// https://github.com/rt2zz/redux-persist/issues/802
// https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof makeStore>;
