import { useCallback, useEffect, useMemo } from "react";

import {
  usePersonalization,
  usePrevious,
  useNews,
  useCRM,
  useTweets,
  useTechStacks,
  useShouldDisplayPaywall,
} from ".";

import { IPersonalization } from "../redux/typings";
import {
  newPendingPersonalization,
  newPendingTweets,
  newPendingTechStack,
  newPendingNews,
  newPendingCRM,
} from "~src/utils";

// Hook modularizing Effect hooks to be used at the root of the personalization panel only (or higher)

export const usePersonalizationPanel = () => {
  const {
    recipients,
    lastUpdated,
    recipientVerifications,
    currentPersonalization,
    personalizations,
    setPersonalizationContext,
    resetPersonalizationContext,
  } = usePersonalization();
  const { crms, setCRMContext, resetCRMContext } = useCRM();
  const { news, setNewsContext, resetNewsContext } = useNews();
  const { tweets, setTweetsContext, resetTweetsContext } = useTweets();
  const { techStacks, setTechStackContext, resetTechStackContext } =
    useTechStacks();
  const prevLastUpdated = usePrevious(lastUpdated);
  const prevEmail = usePrevious(currentPersonalization?.profile?.email || "");
  const currentEmail = currentPersonalization?.profile?.email || "";
  const shouldDisplayPaywall = useShouldDisplayPaywall("profile");

  const personalizationsLoading = useMemo(() => {
    const emailData = personalizations[currentEmail];
    return !!emailData?.profile?.loading;
  }, [currentEmail, personalizations]);

  const jobsLoading = useMemo(() => {
    const emailData = personalizations[currentEmail];

    return !!emailData?.company.jobs.loading;
  }, [currentEmail, personalizations]);

  const crmsLoading = useMemo(() => {
    const crmData = crms[currentEmail];
    return !!crmData?.loading;
  }, [crms, currentEmail]);

  const newsLoading = useMemo(() => {
    const newsData = news[currentEmail];
    return !!newsData?.loading;
  }, [currentEmail, news]);

  const tweetsLoading = useMemo(() => {
    const tweetsData = tweets[currentEmail];
    return !!tweetsData?.loading;
  }, [currentEmail, tweets]);

  const techStacksLoading = useMemo(() => {
    const techStackData = techStacks[currentEmail];
    return !!techStackData?.loading;
  }, [currentEmail, techStacks]);

  const prevPersonalizationLoadingState = usePrevious(personalizationsLoading);
  const prevCRMLoadingState = usePrevious(crmsLoading);
  const prevNewsLoadingState = usePrevious(newsLoading);
  const prevTweetsLoadingState = usePrevious(tweetsLoading);
  const prevTechStackLoadingState = usePrevious(techStacksLoading);
  const prevJobsLoadingState = usePrevious(jobsLoading);

  const recipientsArray = useMemo(
    () => Object.entries(recipients),
    [recipients]
  );

  const setContextForInitialRecipient = useCallback(() => {
    setPersonalizationContext(
      personalizations[recipients[0]] ||
        newPendingPersonalization(recipients[0])
    );

    setNewsContext(news[recipients[0]] || newPendingNews());
    setCRMContext(crms[recipients[0]] || newPendingCRM());
    setTweetsContext(tweets[recipients[0]] || newPendingTweets());
    setTechStackContext(techStacks[recipients[0]] || newPendingTechStack());
  }, [
    personalizations,
    recipients,
    setPersonalizationContext,
    news,
    setNewsContext,
    crms,
    setCRMContext,
    tweets,
    setTweetsContext,
    techStacks,
    setTechStackContext,
  ]);

  const shouldUpdatePersonalizationContext = useMemo(() => {
    return (
      (prevPersonalizationLoadingState && !personalizationsLoading) ||
      currentEmail !== prevEmail ||
      prevLastUpdated !== lastUpdated
    );
  }, [
    currentEmail,
    lastUpdated,
    personalizationsLoading,
    prevEmail,
    prevLastUpdated,
    prevPersonalizationLoadingState,
  ]);

  const shouldUpdatePersonalizationContextWithJobs = useMemo(() => {
    return (
      (prevJobsLoadingState && !jobsLoading) ||
      currentEmail !== prevEmail ||
      prevLastUpdated !== lastUpdated
    );
  }, [
    currentEmail,
    jobsLoading,
    lastUpdated,
    prevEmail,
    prevJobsLoadingState,
    prevLastUpdated,
  ]);

  const shouldUpdateCRMContext = useMemo(() => {
    return (
      (prevCRMLoadingState && !crmsLoading) ||
      currentEmail !== prevEmail ||
      prevLastUpdated !== lastUpdated
    );
  }, [
    crmsLoading,
    currentEmail,
    lastUpdated,
    prevCRMLoadingState,
    prevEmail,
    prevLastUpdated,
  ]);

  const shouldUpdateNewsContext = useMemo(() => {
    return (
      (prevNewsLoadingState && !newsLoading) ||
      currentEmail !== prevEmail ||
      prevLastUpdated !== lastUpdated
    );
  }, [
    currentEmail,
    lastUpdated,
    newsLoading,
    prevEmail,
    prevLastUpdated,
    prevNewsLoadingState,
  ]);

  const shouldUpdateTweetsContext = useMemo(() => {
    return (
      (prevTweetsLoadingState && !tweetsLoading) ||
      currentEmail !== prevEmail ||
      prevLastUpdated !== lastUpdated
    );
  }, [
    currentEmail,
    lastUpdated,
    prevEmail,
    prevLastUpdated,
    prevTweetsLoadingState,
    tweetsLoading,
  ]);

  const shouldUpdateTechStackContext = useMemo(() => {
    return (
      (prevTechStackLoadingState && !techStacksLoading) ||
      currentEmail !== prevEmail ||
      prevLastUpdated !== lastUpdated
    );
  }, [
    currentEmail,
    lastUpdated,
    prevEmail,
    prevLastUpdated,
    prevTechStackLoadingState,
    techStacksLoading,
  ]);

  // If personalization tab was opened before recipient email data is in state, update the context with the first recipient
  useEffect(() => {
    if (!currentEmail && recipients[0] && !shouldDisplayPaywall) {
      console.debug(
        "RUNNING EFFECT 1: POPULATING FOR INIT RECIPIENT STATE",
        "recipient: ",
        recipients[0]
      );
      setContextForInitialRecipient();
    }
  }, [
    recipients,
    personalizations,
    setPersonalizationContext,
    currentEmail,
    setContextForInitialRecipient,
    shouldDisplayPaywall,
  ]);

  const canUpdate = useMemo(() => {
    if (shouldDisplayPaywall) {
      return false;
    }

    const validRecipient = recipientVerifications[currentEmail];
    if (!validRecipient) {
      return false;
    }

    return true;
  }, [currentEmail, recipientVerifications, shouldDisplayPaywall]);

  const shouldUpdatePersonalization = useMemo(() => {
    if (!canUpdate) {
      return false;
    }

    return shouldUpdatePersonalizationContext;
  }, [canUpdate, shouldUpdatePersonalizationContext]);

  const shouldUpdateJobs = useMemo(() => {
    if (!canUpdate) {
      return false;
    }

    return shouldUpdatePersonalizationContextWithJobs;
  }, [canUpdate, shouldUpdatePersonalizationContextWithJobs]);

  const shouldUpdateCRM = useMemo(() => {
    if (!canUpdate) {
      return false;
    }

    return shouldUpdateCRMContext;
  }, [canUpdate, shouldUpdateCRMContext]);

  const shouldUpdateNews = useMemo(() => {
    if (!canUpdate) {
      return false;
    }

    return shouldUpdateNewsContext;
  }, [canUpdate, shouldUpdateNewsContext]);

  const shouldUpdateTweets = useMemo(() => {
    if (!canUpdate) {
      return false;
    }

    return shouldUpdateTweetsContext;
  }, [canUpdate, shouldUpdateTweetsContext]);

  const shouldUpdateTechStacks = useMemo(() => {
    if (!canUpdate) {
      return false;
    }

    return shouldUpdateTechStackContext;
  }, [canUpdate, shouldUpdateTechStackContext]);

  // If personalization data finished loading, update the context for the current recipient
  useEffect(() => {
    if (!shouldUpdatePersonalization) {
      return;
    }

    const personalization = personalizations[currentEmail];

    if (personalization !== undefined) {
      console.debug(
        "RUNNING EFFECT 2: POPULATING FOR NEW PERSONALIZATION STATE",
        "personalization: ",
        personalization
      );
      setPersonalizationContext(personalization);
    }
  }, [
    currentEmail,
    personalizations,
    setPersonalizationContext,
    shouldUpdatePersonalization,
  ]);

  // jobs
  useEffect(() => {
    if (!shouldUpdateJobs) {
      return;
    }

    const personalizationData = personalizations[currentEmail];
    if (!personalizationData) {
      return;
    }

    // const {
    //   company: { jobs },
    // } = personalizationData;
    setPersonalizationContext(personalizationData);
  }, [
    currentEmail,
    personalizations,
    setPersonalizationContext,
    shouldUpdateJobs,
  ]);

  // crm
  useEffect(() => {
    if (!shouldUpdateCRM) {
      return;
    }
    const crmData = crms[currentEmail];
    if (crmData !== undefined) {
      setCRMContext(crmData);
    }
  }, [crms, currentEmail, setCRMContext, shouldUpdateCRM]);

  // news
  useEffect(() => {
    if (!shouldUpdateNews) {
      return;
    }
    const newsData = news[currentEmail];
    if (newsData !== undefined) {
      setNewsContext(newsData);
    }
  }, [currentEmail, news, setNewsContext, shouldUpdateNews]);

  // tweets
  useEffect(() => {
    if (!shouldUpdateTweets) {
      return;
    }
    const tweetsData = tweets[currentEmail];
    if (tweetsData !== undefined) {
      setTweetsContext(tweetsData);
    }
  }, [currentEmail, setTweetsContext, shouldUpdateTweets, tweets]);

  // techstack
  useEffect(() => {
    if (!shouldUpdateTechStacks) {
      return;
    }
    const techStackData = techStacks[currentEmail];
    if (techStackData !== undefined) {
      setTechStackContext(techStackData);
    }
  }, [currentEmail, setTechStackContext, shouldUpdateTechStacks, techStacks]);

  // If current recipient is deleted, update the context for the next recipient
  useEffect(() => {
    if (currentEmail && !recipientVerifications?.[currentEmail]) {
      let foundPersonalization: IPersonalization | undefined;
      for (const email of recipients) {
        if (email !== currentEmail) {
          foundPersonalization =
            personalizations[email] || newPendingPersonalization(email);
          if (personalizations[email]) {
            break;
          }
        }
      }

      if (foundPersonalization) {
        console.debug(
          "RUNNING EFFECT 3: POPULATING FOR DELETED RECIPIENT STATE",
          "personalization: ",
          foundPersonalization
        );
        setPersonalizationContext(foundPersonalization);

        const {
          profile: { email },
        } = foundPersonalization;

        const crmItem = crms[email];
        if (crmItem) {
          setCRMContext(crmItem);
        }

        const newsItem = news[email];
        if (newsItem) {
          setNewsContext(newsItem);
        }

        const tweetsItem = tweets[email];
        if (tweetsItem) {
          setTweetsContext(tweetsItem);
        }

        const techStackitem = techStacks[email];
        if (techStackitem) {
          setTechStackContext(techStackitem);
        }
      } else {
        console.debug(
          "RUNNING EFFECT 3: RESETTING FOR DELETED RECIPIENT STATE",
          "personalization: ",
          foundPersonalization
        );
        resetPersonalizationContext();
        resetCRMContext();
        resetNewsContext();
        resetTweetsContext();
        resetTechStackContext();
      }
    }
  }, [
    currentEmail,
    recipients,
    personalizations,
    setPersonalizationContext,
    resetPersonalizationContext,
    recipientVerifications,
    resetCRMContext,
    resetNewsContext,
    resetTweetsContext,
    resetTechStackContext,
    crms,
    news,
    tweets,
    techStacks,
    setCRMContext,
    setNewsContext,
    setTweetsContext,
    setTechStackContext,
  ]);

  return {
    currentEmail,
    didUserSwitchProfileInView: prevEmail !== currentEmail,
    recipientsArray,
  };
};
