import { HubspotData, SalesforceData } from "~src/api/typings";
import { GongData } from "./personalization";

export interface ICRM {
  hasGongAccess: boolean;
  hasHubspotAccess: boolean;
  hasSalesforceAccess: boolean;
  gong_data: GongData[];
  hubspot_data: HubspotData | undefined;
  salesforce_data: SalesforceData | undefined;
  loading: boolean;
}

export type RecipientCRMMap = Record<emailAddress, ICRM | undefined>;

export interface CRMData {
  toAddresses: emailAddress[];
  crmData: RecipientCRMMap;
}
