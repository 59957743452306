import {
  PersonalizationEvent,
  PersonalizationExperience,
  PersonalizationJobOpening,
  PersonalizationTechStack,
  WorkHistory,
} from "~src/redux/typings";
import {
  FeedEntityAttribute,
  JobEntityAttribute,
  TechEntityAttribute,
} from "./additionalCompanyData";
import { CompanyFeed } from "./personalization";
import {
  RankedFeedEvent,
  RankedFeedJobOpening,
  RankedFeedTech,
} from "./rankedFeed";

export type EventItem = CompanyFeed<FeedEntityAttribute> | RankedFeedEvent;
export type JobItem = CompanyFeed<JobEntityAttribute> | RankedFeedJobOpening;
export type TechItem = CompanyFeed<TechEntityAttribute> | RankedFeedTech;
export type WorkHistoryItem = WorkHistory | PersonalizationExperience;
export type RankedItem =
  | PersonalizationEvent
  | PersonalizationExperience
  | PersonalizationJobOpening
  | PersonalizationTechStack;
