import { useCallback } from "react";

import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { useValuePropsMutationContext } from "~src/contexts";
import { parseAndLogError, ErrorIdentifiers } from "~src/logger";
import { CART_HEADER } from "~src/strings";
import {
  getGenerateValuePropsPayload,
  needsRefreshValueProps,
} from "~src/utils";

import { useStartMyEmail } from "./useStartMyEmail";
import { useValuePropGeneration } from "./useValuePropGeneration";

/**
 * When routing to the "Value Props" view, this will
 * 1. Cancel any ongoing value prop generation
 * 2. If in value prop generation mode:
 *   - Start the value prop generation if the feature flag and toggle is on
 *   - Navigate to the loading states view while the value prop analysis is ongoing
 *   - Navigate to the "Value Props" view on completion
 *  Else:
 *   - Navigate to the "Value Props" view immediately and display any available user_value props
 */

export const useRouteToCartModal = () => {
  const { next } = useCarouselContext();
  const {
    currentPersonalization: {
      cart,
      profile: { email },
    },
  } = useStartMyEmail();
  const { currentGeneration, isUserValuePropGenerationOn } =
    useValuePropGeneration();
  const { generateValuePropsMutation } = useValuePropsMutationContext();

  const handleRouteToCartModal = useCallback(() => {
    const smeCart = getGenerateValuePropsPayload(cart);
    const needsRefresh = isUserValuePropGenerationOn
      ? needsRefreshValueProps(currentGeneration, smeCart)
      : false;

    if (needsRefresh) {
      try {
        generateValuePropsMutation(smeCart, email);
      } catch (e) {
        parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
          request: "generateValueProps",
        });
        // TODO: next(ErrorModalType, { error: e })
      }
      next(ModalType.Overlay, {
        openedBy: email,
        phase: CART_HEADER.ADD_CONTEXT,
      });
    } else {
      next(ModalType.Cart, {
        openedBy: email,
        phase: CART_HEADER.ADD_CONTEXT,
      });
    }
  }, [
    cart,
    currentGeneration,
    email,
    generateValuePropsMutation,
    isUserValuePropGenerationOn,
    next,
  ]);

  return { handleRouteToCartModal };
};
