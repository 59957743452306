export interface UpdateSettingsPayload {
  chromeId: chromeId;
  companyDescription: string;
  companyName: string;
  companyUrl: string;
  email: emailAddress;
  position: string;
}

export interface UpdateSettingsResponse {
  status: "success";
}
