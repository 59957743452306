import { capitalCase } from "change-case";

import { PersonalizationTrigger } from "~src/api/typings";
import { UnassignedTriggerColorCount } from "~src/component/constants";
import {
  LavenderTextColorMap,
  UnassignedTriggerColorMap,
} from "~src/component/typings";
import { PersonalizationRankedData } from "~src/redux/typings";

export const icebreakerCount = (
  rankedFeedOrCart: PersonalizationRankedData
): number =>
  Object.keys(rankedFeedOrCart).reduce(
    (acc, key) => acc + (rankedFeedOrCart[key].items?.length || 0),
    0
  );

export const hasItems = (
  rankedFeedOrCart: PersonalizationRankedData
): boolean => {
  return icebreakerCount(rankedFeedOrCart) > 0;
};

export const normalizeTriggers = (
  triggers: PersonalizationTrigger[] | null
): string[] =>
  triggers !== null && triggers !== undefined ? Object.keys(triggers[0]) : [];

export const mainTrigger = (triggers: PersonalizationTrigger[]): string => {
  return Object.keys(triggers[0])[0];
};
export const prettyTrigger = (str: string): string => {
  switch (str) {
    case "award_or_recognition_received":
      return "Award Received";
    case "company_acquires_or_merges":
      return "M&A";
    case "expansion":
      return "Rapid Expansion";
    case "funding_or_raises_capital":
      return "Funding Received";
    case "new_employee_hired":
      return "New Hire";
    case "employee_leaves_or_retires":
      return "Employee Exit";
    case "product_launch_or_improvements":
      return "Product Update";
    case "product_review":
      return "Review";
    case "company_vision_or_strategy":
      return "Strategic Vision";
    case "ipo":
      return "IPO";
    default:
      return capitalCase(str).replace("Or", "or");
  }
};

export const triggerColor = (str: string, idx: number) => {
  switch (str) {
    case "competitive_product":
    case "complementary_product":
      return LavenderTextColorMap[LavenderTextColorMap.peony];
    case "mutual_employer":
    case "role_resonance":
    case "seniority_resonance":
      return LavenderTextColorMap[LavenderTextColorMap.pear];
    case "new_employee_hired":
    case "employee_leaves_or_retires":
    case "employee_promotion":
    case "new_employment":
    case "promotion":
      return LavenderTextColorMap[LavenderTextColorMap.sapphire];
    case "funding_or_raises_capital":
    case "investment":
    case "ipo":
      return LavenderTextColorMap[LavenderTextColorMap.lavender];
    default:
      return UnassignedTriggerColorMap[idx % UnassignedTriggerColorCount];
  }
};
