import { useCallback } from "react";
import { Flex, MenuItemProps } from "antd";
import styled from "styled-components";

import { Button } from "@lavender-ai/lav-components";

import { useCRM, usePersonalization } from "~src/customHooks";

import { usePanelTabContext } from "~src/contexts";
import { PERSONALIZATION_SUB_TAB } from "~src/strings";
import { LavenderIcon } from "../typings/icon";
import {
  type PersonalizationSubTab,
  PersonalizationTab,
  ProfileSubTab,
  CompanySubTab,
  FeedSubTab,
} from "../typings/personalization";
import { IconProps } from "@lavender-ai/lav-components/dist/esm/types/src/particles/Icon";
import {
  PERSONALIZATION_TAB_SEGMENT_EVENT_MAP,
  SegmentEventLocations,
  segmentEvent,
} from "~src/utils";
import { SocialPostsButton } from "~src/component/Molecules/SocialPostsButton/SocialPostsButton";

const {
  ABOUT,
  CONNECT_CRM,
  GONG,
  JOBS,
  NEWS,
  PERSONALITY,
  ACTIVITY,
  SOCIAL_FEED,
  PODCASTS,
  TECH_STACK,
  FUNDING,
  EXPERIENCE,
  HUBSPOT,
  SALESFORCE,
} = PERSONALIZATION_SUB_TAB;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px;
`;

export interface TabMenuItemsProps
  extends Omit<MenuItemProps, "key" | "onClick"> {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  key: FeedSubTab | ProfileSubTab | CompanySubTab;
  iconSrc?: IconProps["variant"];
}

type CategoryType = "profile" | "company" | undefined;

// If the user has SF or HS, display that button. Otherwise display login button
const useCRMButtonSelector = (): TabMenuItemsProps[] => {
  const {
    currentCRM: { hasHubspotAccess, hasSalesforceAccess },
  } = useCRM();

  if (!hasSalesforceAccess && !hasHubspotAccess) {
    return [
      {
        key: CONNECT_CRM,
        children: CONNECT_CRM,
        iconSrc: LavenderIcon.IconPlus,
      },
    ];
  }

  const crmButtons: TabMenuItemsProps[] = [];

  if (hasSalesforceAccess) {
    crmButtons.push({
      key: SALESFORCE,
      children: SALESFORCE,
      iconSrc: LavenderIcon.IconSalesforce,
    });
  }

  if (hasHubspotAccess) {
    crmButtons.push({
      key: HUBSPOT,
      children: HUBSPOT,
      iconSrc: LavenderIcon.IconHubspotLogo,
    });
  }

  return crmButtons;
};

export const PersonalizationSubTabs = ({
  category,
}: {
  category: CategoryType;
}) => {
  const {
    currentPersonalization: {
      company: { name: companyName },
    },
  } = usePersonalization();
  const { subTab, setPanelTabContext } = usePanelTabContext<
    PersonalizationTab,
    PersonalizationSubTab
  >();

  const crmButtons = useCRMButtonSelector();

  const isCompanySubTabDisabled = !companyName;

  const onSelect = useCallback(
    (subTab: FeedSubTab | ProfileSubTab | CompanySubTab) => {
      setPanelTabContext({
        tab:
          category === "profile"
            ? PersonalizationTab.PROFILE
            : PersonalizationTab.COMPANY,
        subTab,
      });
      const eventName = PERSONALIZATION_TAB_SEGMENT_EVENT_MAP[subTab];
      if (eventName !== undefined) {
        segmentEvent(eventName, SegmentEventLocations.PersonalizationAssistant);
      }
    },
    [category, setPanelTabContext]
  );

  const items: TabMenuItemsProps[] =
    category === "profile"
      ? [...BASE_PROFILE_ITEMS, ...crmButtons]
      : BASE_COMPANY_ITEMS.map((item) => ({
          ...item,
          disabled: isCompanySubTabDisabled,
        }));

  return (
    <TagsWrapper>
      <Flex justify="flex-start" align="flex-start" gap="small" wrap="wrap">
        {items.map((item) => {
          if (item.key === SOCIAL_FEED) {
            return (
              <SocialPostsButton
                key={item.key}
                variant={item.key === subTab ? "plain" : "secondary"}
                state={item.key === subTab ? "focus" : "initial"}
                onClick={() => {
                  onSelect(item.key);
                }}
              />
            );
          }
          return (
            <Button
              key={item.key}
              prefix={item.iconSrc}
              variant={item.key === subTab ? "plain" : "secondary"}
              size="small"
              state={item.key === subTab ? "focus" : "initial"}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(item.key);
              }}
            >
              {item.children}
            </Button>
          );
        })}
      </Flex>
    </TagsWrapper>
  );
};

const BASE_PROFILE_ITEMS: TabMenuItemsProps[] = [
  {
    iconSrc: LavenderIcon.IconUser,
    children: ABOUT,
    key: ABOUT,
  },
  {
    key: SOCIAL_FEED,
    iconSrc: LavenderIcon.IconUsers,
    children: SOCIAL_FEED,
  },
  {
    iconSrc: LavenderIcon.IconBuildingBroadcastTower,
    children: PODCASTS,
    key: PODCASTS,
    disabled: false,
  },
  {
    key: PERSONALITY,
    iconSrc: LavenderIcon.IconMoodSmile,
    children: PERSONALITY,
  },
  {
    key: EXPERIENCE,
    iconSrc: LavenderIcon.IconBriefcase,
    children: EXPERIENCE,
  },
  {
    key: GONG,
    iconSrc: LavenderIcon.IconGongLogo,
    children: GONG,
  },
  {
    key: ACTIVITY,
    iconSrc: LavenderIcon.IconActivity,
    children: ACTIVITY,
  },
];

const BASE_COMPANY_ITEMS: TabMenuItemsProps[] = [
  {
    key: ABOUT,
    iconSrc: LavenderIcon.IconBuildingSkyscraper,
    children: ABOUT,
  },
  {
    key: NEWS,
    iconSrc: LavenderIcon.IconNews,
    children: NEWS,
  },
  {
    key: JOBS,
    iconSrc: LavenderIcon.IconWorldDollar,
    children: JOBS,
  },
  {
    key: TECH_STACK,
    iconSrc: LavenderIcon.IconStack,
    children: TECH_STACK,
  },
  {
    key: FUNDING,
    iconSrc: LavenderIcon.IconReportMoney,
    children: FUNDING,
  },
];
