import { Flex } from "antd";

import { IconButton, Text } from "@lavender-ai/lav-components";

import { PanelAlert } from "~src/component/Atoms/PanelAlert/PanelAlert";
import { usePersonalization, useValuePropGeneration } from "~src/customHooks";

import { useCarouselContext } from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { SecondaryText } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";
import { CART_HEADER, CART_ALERT } from "~src/strings";
import { icebreakerCount } from "~src/utils";

import { StyledCartHeader } from "../../../Shared/Styled";

export const Header = () => {
  const {
    currentPersonalization: { cart },
  } = usePersonalization();

  const { isUserValuePropGenerationOn } = useValuePropGeneration();
  const { reset } = useCarouselContext();
  const cartCount = icebreakerCount(cart);

  return (
    <StyledCartHeader align="flex-start" gap={4} vertical>
      <Flex
        align="center"
        className="lv-cart-header"
        justify="flex-start"
        gap={12}
      >
        <IconButton
          icon={LavenderIcon.IconChevronLeft}
          variant="secondary"
          onClick={reset}
        />
        <>
          <Text color="dark" size="subtext3">
            {CART_HEADER.ADD_CONTEXT}
          </Text>
          <SecondaryText
            className="lv-cart-header-secondary-text"
            $light
            size="body2"
          >
            {CART_HEADER.SLASH_SELECTED(cartCount)}
          </SecondaryText>
        </>
      </Flex>
      {!isUserValuePropGenerationOn && (
        <PanelAlert message={CART_ALERT.ADDING_CONTEXT} type="info" />
      )}
    </StyledCartHeader>
  );
};
