import { Text } from "@lavender-ai/lav-components";

import { useAppSelector } from "~src/redux";
import { BILLING_PLANS } from "~src/constants";
import { useCRM } from "~src/customHooks";
import { useCarouselContext } from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  PersonalizationTab,
  PersonalizationSubTab,
  ProfileSubTab,
  CompanySubTab,
} from "~src/component/typings";
import { useGlobalSearchContext, usePanelTabContext } from "~src/contexts";

import {
  SearchModalSuggestionsContainer,
  SuggestionsContainer,
  QuickActionsContainer,
  SuggestionsHeader,
  SuggestionsList,
  QuickActionsHeader,
} from "./SearchModalSuggestions.styled";
import { SuggestionRow } from "./SuggestionRow";
import { SearchModalQuickActionsRow } from "./QuickActionsRow";

export const SearchModalSuggestions = () => {
  const { setPanelTabContext } = usePanelTabContext<
    PersonalizationTab,
    PersonalizationSubTab
  >();
  const { hasGongAccess, hasHubspotAccess, hasSalesforceAccess } =
    useCRM().currentCRM;
  const showIntegrations = [BILLING_PLANS.Pro, BILLING_PLANS.Teams].includes(
    useAppSelector(
      ({ userAccount }) =>
        userAccount.settings.user?.billing_plan ?? BILLING_PLANS.Basic
    )
  );
  const { closeModal } = useGlobalSearchContext();

  // Show quick actions if the user doesn't have Gong, or neither Hubspot or Salesforce
  const showQuickActions =
    showIntegrations &&
    (!hasGongAccess || (!hasHubspotAccess && !hasSalesforceAccess));

  return (
    <SearchModalSuggestionsContainer>
      <SuggestionsContainer>
        <SuggestionsHeader>
          <Text
            size="body1"
            style={{
              color: "var(--color-neutral-purple-500, #B3B6BD)",
            }}
          >
            Suggestions
          </Text>
        </SuggestionsHeader>
        <SuggestionsList>
          <SuggestionRow
            icon="IconBrandLinkedin"
            onClick={() => {
              setPanelTabContext({
                tab: PersonalizationTab.PROFILE,
                subTab: ProfileSubTab.SOCIAL_FEED,
              });
              closeModal();
            }}
            targetString="Recipient"
            title="Social Feed"
          />
          <SuggestionRow
            icon="IconBriefcase"
            onClick={() => {
              setPanelTabContext({
                tab: PersonalizationTab.PROFILE,
                subTab: ProfileSubTab.EXPERIENCE,
              });
              closeModal();
            }}
            targetString="Recipient"
            title="Work History"
          />
          <SuggestionRow
            icon="IconStack"
            onClick={() => {
              setPanelTabContext({
                tab: PersonalizationTab.COMPANY,
                subTab: CompanySubTab.TECH_STACK,
              });
              closeModal();
            }}
            targetString="Company"
            title="Tech Stack"
          />
        </SuggestionsList>
      </SuggestionsContainer>
      {showQuickActions && (
        <QuickActionsContainer>
          <QuickActionsHeader>
            <Text
              size="body1"
              style={{
                color: "var(--color-neutral-purple-500, #B3B6BD)",
              }}
            >
              Quick Actions
            </Text>
          </QuickActionsHeader>
          {!hasGongAccess && (
            <SearchModalQuickActionsRow
              icon="IconGongLogo"
              onClick={() => {
                setPanelTabContext({
                  tab: PersonalizationTab.PROFILE,
                  subTab: ProfileSubTab.GONG,
                });
                closeModal();
              }}
              title="Integrate Gong"
            />
          )}
          {!(hasHubspotAccess || hasSalesforceAccess) && (
            <SearchModalQuickActionsRow
              icon="IconApps"
              onClick={() => {
                setPanelTabContext({
                  tab: PersonalizationTab.PROFILE,
                  subTab: ProfileSubTab.CONNECT_CRM,
                });
                closeModal();
              }}
              title="Connect your CRM"
              hasNewTag
            />
          )}
        </QuickActionsContainer>
      )}
    </SearchModalSuggestionsContainer>
  );
};
