import { ICRM } from "~src/redux/typings";

export const newPendingCRM = (): ICRM => ({
  hasGongAccess: false,
  hasHubspotAccess:false,
  hasSalesforceAccess:false,
  gong_data: [],
  hubspot_data: undefined,
  salesforce_data: undefined,
  loading: true,
});

export const newRejectedCRM = (): ICRM => ({
  hasGongAccess: false,
  hasHubspotAccess:false,
  hasSalesforceAccess:false,
  gong_data: [],
  hubspot_data: undefined,
  salesforce_data: undefined,
  loading: false,
});
