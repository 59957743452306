import styled from "styled-components";

import { Button } from "@lavender-ai/lav-components";

import { SECONDARY_ICON_MIXIN } from "./mixin";

export const FormSubmitButton = styled(Button)`
  background: var(--color-lavender-400);
  border: 1px solid var(--color-lavender-400);
  box-shadow: none;
`;

export const SecondaryIconButton = styled(Button)`
  .lav-components-icon-color-dark.lav-components-icon {
    ${SECONDARY_ICON_MIXIN}
  }
`;

export const SilverSecondaryButton = styled(Button)`
  border: 1px solid var(--color-neutral-blue-200, #e6e9f0);
  background: var(--color-neutral-blue-200, #e6e9f0);
`;
