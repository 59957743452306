import {
  RankedFeedType,
  RankedItem,
  StartMyEmailSection,
  WorkHistoryItem,
} from "~src/api/typings";
import {
  PersonalizationEvent,
  PersonalizationExperience,
  PersonalizationJobOpening,
  PersonalizationTechStack,
  ValueProp,
  WorkHistory,
} from "~src/redux/typings";

export const isRankedFeedExperience = (
  item: WorkHistoryItem
): item is PersonalizationExperience => {
  return "score" in item;
};
export const areWorkHistoryItems = (
  items: RankedItem[] | WorkHistory[]
): items is WorkHistory[] => {
  return (
    "type" in items[0] &&
    items[0].type === RankedFeedType.work_history &&
    "start" in items[0]
  );
};
export const isStartMyEmailSection = (
  section: StartMyEmailSection | undefined
): section is StartMyEmailSection => {
  return section !== undefined && typeof section === "object";
};

export const isValueProp = (
  value: ValueProp | undefined
): value is ValueProp => {
  return value !== undefined && typeof value === "object";
};

// Cart Items
export const isJobOpeningCartItem = (
  item: RankedItem
): item is PersonalizationJobOpening => {
  return item.type === RankedFeedType.job_openings;
};
export const isTechCartItem = (
  item: RankedItem
): item is PersonalizationTechStack => {
  return item.type === RankedFeedType.tech_stack;
};

// Cart list items
export const arePersonalizationEventItems = (
  items: RankedItem[]
): items is PersonalizationEvent[] => {
  return items[0].type === RankedFeedType.events;
};
export const arePersonalizationJobOpeningsItems = (
  items: RankedItem[]
): items is PersonalizationJobOpening[] => {
  return items[0].type === RankedFeedType.job_openings;
};
export const arePersonalizationTechStackItems = (
  items: RankedItem[]
): items is PersonalizationTechStack[] => {
  return items[0].type === RankedFeedType.tech_stack;
};
