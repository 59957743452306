export interface CallOutlineItemText {
  key: string;
  text: string;
}

export interface PublicComments {
  id: string;
  audioStartTime: number;
  audioEndTime: number;
  commenterUserId: string;
  comment: string;
  posted: string;
  inReplyTo: any;
  duringCall: boolean;
}

export interface Collaboration {
  publicComments: PublicComments[];
}

export interface CallOutlineItem {
  key: string;
  duration: number;
  section: string;
  items: CallOutlineItemText[];
}

export interface GongCall {
  id: string;
  title: string;
  url: string;
  callTime: string;
  callTimeFormatted: string;
  participants: string[];
  participantsText: string;
  duration: number;
  durationFormatted: string;
  nextSteps: string[];
  collaboration: Collaboration;
  callBrief: string;
  outline: CallOutlineItem[];
}
