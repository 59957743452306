import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { MobileDevice } from "~src/component/MobileDevice";
import {
  MOBILE_DEVICE,
  MOBILE_DEVICE_HEIGHT,
  MOBILE_DEVICE_WIDTH,
  WATCH_DEVICE,
} from "~src/constants";
import { useDomNodes } from "~src/customHooks";
import { store, useAppSelector } from "~src/redux";
import { InsertType } from "~src/redux/typings";
import { getBodyElement } from "~src/utils";

interface MobileEditorContextType {
  showMobileEditor: (
    mouseevent: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  hideMobileEditor: () => void;
  isMobileEditorOpen: boolean;
  enableDarkMode: () => void;
  disableDarkMode: () => void;
  isDarkMode: boolean;
  enableMobileDevice: () => void;
  enableWatchDevice: () => void;
  isMobileDevice: boolean;
}

const MobileEditorContext = createContext<MobileEditorContextType | undefined>(
  undefined
);

export const useMobileEditor = () => {
  const context = useContext(MobileEditorContext);
  if (context === undefined) {
    throw new Error(
      "useMobileEditor must be used within a MobileEditorProvider"
    );
  }
  return context;
};

interface ModalProviderProps {
  children: ReactNode;
  composeContainerSelector: string;
}

export const MobileEditorContextProvider: React.FC<ModalProviderProps> = ({
  children,
  composeContainerSelector,
}) => {
  const [isMobileEditorOpen, setMobileEditorOpen] = useState<boolean>(false);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(true);

  const [subjectText, setSubjectText] = useState("");
  const [bodyContent, setBodyContent] = useState("");

  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const user = useAppSelector(
    ({
      userAccount: {
        settings: { user },
      },
    }) => user
  );
  const firstName = user?.first_name;
  const lastName = user?.last_name;

  const showMobileEditor = useCallback(
    (mouseevent: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setMobileEditorOpen(true);
      const startingX =
        mouseevent.clientX - MOBILE_DEVICE_WIDTH - 40 < 0 // 40 is Buffer to not overlap with menu
          ? 0
          : mouseevent.clientX - MOBILE_DEVICE_WIDTH - 40;
      let startingY =
        mouseevent.clientY - MOBILE_DEVICE_HEIGHT / 2 < 0
          ? 0
          : mouseevent.clientY - MOBILE_DEVICE_HEIGHT / 2;
      if (startingY + MOBILE_DEVICE_HEIGHT > document.body.scrollHeight) {
        startingY = document.body.scrollHeight - MOBILE_DEVICE_HEIGHT;
      }
      setStartX(startingX);
      setStartY(startingY);
    },
    []
  );
  const hideMobileEditor = useCallback(() => {
    setMobileEditorOpen(false);
  }, []);

  const enableDarkMode = useCallback(() => {
    setIsDarkMode(true);
  }, []);

  const disableDarkMode = useCallback(() => {
    setIsDarkMode(false);
  }, []);

  const enableMobileDevice = useCallback(() => {
    setIsMobileDevice(true);
  }, []);

  const enableWatchDevice = useCallback(() => {
    setIsMobileDevice(false);
  }, []);

  const initialState = useMemo(
    () => ({
      showMobileEditor,
      hideMobileEditor,
      isMobileEditorOpen,
      enableDarkMode,
      disableDarkMode,
      isDarkMode,
      enableMobileDevice,
      enableWatchDevice,
      isMobileDevice,
    }),
    [
      showMobileEditor,
      hideMobileEditor,
      isMobileEditorOpen,
      enableDarkMode,
      disableDarkMode,
      isDarkMode,
      enableMobileDevice,
      enableWatchDevice,
      isMobileDevice,
    ]
  );

  const { panelParent } = useDomNodes();
  const selectors = store.getState().config.settings?.selectors;
  const {
    subjectBoxSelector,
    subjectSelector,
    bodySelector,
    bodyIframe,
    iframeChildSelector,
    composeContainers,
  } = selectors || {};

  const subjectField = subjectSelector
    ? (panelParent?.querySelector(subjectSelector) as HTMLInputElement)
    : null;

  const subjectBoxField = subjectBoxSelector
    ? (panelParent?.querySelector(subjectBoxSelector) as HTMLInputElement)
    : null;

  const { insertType } = useAppSelector(
    (state) => state.config.settings || { insertType: InsertType.Copy }
  );

  const editMailboxSubject = (newSubject: string) => {
    if (insertType === InsertType.Insert) {
      setSubjectText(newSubject);
      if (subjectBoxField !== null) {
        subjectBoxField.value = newSubject;
      }
      if (subjectField !== null) {
        subjectField.value = newSubject;
      }
    }
  };

  if (subjectBoxField !== null) {
    // watch subjectBoxField for gmail
    subjectBoxField.addEventListener("input", (event) => {
      const target = event.target as HTMLInputElement;
      setSubjectText(target?.value);
      if (subjectField) subjectField.value = target.value;
    });
  } else if (subjectField !== null) {
    subjectField.addEventListener("input", (event) => {
      const target = event.target as HTMLInputElement;
      setSubjectText(target?.value);
    });
  }
  let emailBody: HTMLTextAreaElement | null = bodySelector
    ? (panelParent?.querySelector(bodySelector) as HTMLTextAreaElement)
    : null;

  if (emailBody === null && bodyIframe && bodyIframe !== "") {
    const composeContainerSelectors =
      composeContainers
        ?.map(({ container }) => container.containerSelector)
        .join(", ") || "";
    const emailContainers = document.querySelectorAll<
      HTMLElement | HTMLIFrameElement
    >(composeContainerSelectors);
    for (const emailContainer of emailContainers) {
      emailBody = getBodyElement(
        bodyIframe,
        "body",
        iframeChildSelector,
        emailContainer
      ) as HTMLTextAreaElement;
      if (emailBody !== null) {
        break;
      }
    }
  }

  const EditMailboxBody = (newBody: string) => {
    setBodyContent(newBody);
    if (emailBody !== null && insertType === InsertType.Insert) {
      emailBody.innerHTML = newBody;
    }
  };

  const config = { childList: true, characterData: true, subtree: true };

  if (emailBody !== null) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "childList" ||
          mutation.type === "characterData"
        ) {
          if (emailBody !== null) {
            setBodyContent(emailBody.innerHTML || "");
          }
        }
      });
    });

    observer.observe(emailBody, config);
  }

  // Adding poll event to check if composeContainer is removed from the DOM
  const checkSelector = () => {
    const composeContainers = document.querySelector<HTMLElement>(
      composeContainerSelector
    );
    if (composeContainers === null) {
      hideMobileEditor();
      clearInterval(pollingId);
    }
  };

  const pollingId = window.setInterval(checkSelector, 2000);

  return (
    <MobileEditorContext.Provider value={initialState}>
      {children}
      {isMobileEditorOpen &&
        createPortal(
          <MobileDevice
            variant={isMobileDevice ? MOBILE_DEVICE : WATCH_DEVICE}
            darkMode={isDarkMode}
            firstName={firstName}
            lastName={lastName}
            subjectText={subjectText}
            emailBody={bodyContent}
            editMailboxSubject={editMailboxSubject}
            editMailBody={EditMailboxBody}
            startX={startX}
            startY={startY}
          />,
          document.body
        )}
    </MobileEditorContext.Provider>
  );
};
