import { useCallback, useEffect, useState } from "react";
import { Flex, Card } from "antd";
import { styled } from "styled-components";

import { AvatarProps, Text } from "@lavender-ai/lav-components";

import { FeatureFlagEnum } from "~src/api/typings";
import { ApplyAllFixesButton } from "~src/component/Molecules";
import { FIX_ALL_CUTOFF_SCORE } from "~src/constants";
import { EmailCoachView, useEmailCoachCarouselContext } from "~src/contexts";
import { useFeatureFlag, useLvIdentifier } from "~src/customHooks";
import { Recommendation } from "~src/redux/typings";
import {
  highlightsAction,
  getFixItAll,
  selectChromeId,
  useAppDispatch,
  useAppSelector,
  selectFixItAllAbortBySessionId,
} from "~src/redux";
import { LavenderIcon } from "~src/component/typings";
import { IconFrame } from "~src/component/Atoms";

import { getWritingData } from "../../../../utils";
import { CategorySection } from "../../../Shared/Styled/CategorySection";
import { CategoryItem } from "./components";

interface CategoryInfoType {
  color?: AvatarProps["color"];
  title: string;
  description: string;
  icon: LavenderIcon;
  thresholdMax: number;
  thresholdMin: number;
  highlightClass: string;
}

interface CategoryInfo {
  danger: CategoryInfoType;
  warning: CategoryInfoType;
}

const CATEGORY_INFO: CategoryInfo = {
  danger: {
    color: "tart",
    title: "Necessary Fixes",
    description: "Has the highest impact on your score",
    icon: LavenderIcon.IconAlertTriangleFilled,
    thresholdMax: 99999,
    thresholdMin: 2,
    highlightClass: "lv-highlight",
  },
  warning: {
    color: "marigold",
    title: "Suggestions",
    description: "Less impact but are great to keep in mind!",
    icon: LavenderIcon.IconBulb,
    thresholdMax: 2,
    thresholdMin: 0.1,
    highlightClass: "lv-highlight-yellow",
  },
};

interface Props {
  score?: number | undefined;
  recommendations: Recommendation[];
  type: string;
  view: EmailCoachView;
}

export const Category = ({ score, recommendations, type, view }: Props) => {
  const dispatch = useAppDispatch();
  const chromeId = useAppSelector(selectChromeId);

  const lvIdentifier = useLvIdentifier();

  const analysisData = useAppSelector(
    (state) => state.emailAnalysis[lvIdentifier]
  );
  const fetchFixItAllAbort = useAppSelector((state) =>
    selectFixItAllAbortBySessionId(state, lvIdentifier)
  );

  const { next } = useEmailCoachCarouselContext();
  const fixItAllFlagOn = useFeatureFlag(FeatureFlagEnum.fix_all);
  const [howMany, setHowMany] = useState<number>(5);
  const writingData = getWritingData(lvIdentifier);
  const body = writingData.body;
  const subject = writingData.subject;
  const stage = analysisData.stage;

  const showFixItAll = !!(
    fixItAllFlagOn &&
    type === "danger" &&
    score &&
    score < FIX_ALL_CUTOFF_SCORE
  );

  useEffect(() => {
    // This function will be called when the component is unmounted
    return () => {
      dispatch(
        highlightsAction.setHighlights({
          lvIdentifier,
          highlights: [],
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoryData: CategoryInfoType = CATEGORY_INFO[
    type
  ] as CategoryInfoType;
  const filtered: Recommendation[] = recommendations.filter((r) => {
    if (
      r.effectonscore >= categoryData.thresholdMin &&
      r.effectonscore < categoryData.thresholdMax
    ) {
      return true;
    }
    return false;
  });

  const onClickBoostScore = useCallback(() => {
    fetchFixItAllAbort();
    next(EmailCoachView.fixItAll);
    const controller = new AbortController();
    void dispatch(
      getFixItAll({
        controller,
        lvIdentifier,
        chromeId,
        body,
        subject,
        recommendations: filtered ?? [],
        stage,
      })
    );
  }, [
    body,
    chromeId,
    dispatch,
    fetchFixItAllAbort,
    filtered,
    lvIdentifier,
    next,
    stage,
    subject,
  ]);

  return filtered?.length <= 0 ? null : (
    <CategorySection
      className={`category-${type}`}
      vertical
      style={{ width: "100%", marginBottom: "24px" }}
    >
      <Card className="lv-card">
        <CategoryHeader className="lv-email-category-header">
          <IconFrame color={categoryData.color} variant={categoryData.icon} />
          <Flex className="lv-email-category-header-text-container" vertical>
            <Text color="black" size="subtext4">
              {categoryData.title}
            </Text>
            <Text
              size="body1"
              style={{
                marginTop: "16px",
                color: "var(--color-neutral-purple-600, #858092)",
              }}
            >
              {categoryData.description}
            </Text>
          </Flex>
        </CategoryHeader>
        <CategoryItems vertical>
          {filtered.slice(0, howMany).map((r) => (
            <CategoryItem
              key={`ci-${r.id}`}
              recommendation={r}
              score={score}
              type={type}
              toggleOn={() => {
                if (r.text) {
                  let highlights = [r.text];
                  if (r.text.includes("|")) {
                    highlights = r.text.split("|");
                  }
                  dispatch(
                    highlightsAction.setHighlights({
                      lvIdentifier,
                      highlightClass: categoryData?.highlightClass,
                      highlights,
                      recommendationId: r.id,
                    })
                  );
                }
              }}
              toggleOff={() => {
                if (view !== EmailCoachView.home) return;
                dispatch(
                  highlightsAction.setHighlights({
                    lvIdentifier,
                    highlights: [],
                  })
                );
              }}
            />
          ))}
        </CategoryItems>
        {filtered?.length > howMany ? (
          <ShowMore
            onClick={() => {
              setHowMany(howMany + 5);
            }}
          >
            Show {Math.min(5, filtered?.length - howMany)} More
          </ShowMore>
        ) : null}
      </Card>
      {showFixItAll && (
        <ApplyAllFixesButton
          content={body}
          onClick={onClickBoostScore}
          recs={filtered ?? []}
        />
      )}
    </CategorySection>
  );
};

const CategoryHeader = styled(Flex)`
  padding: 20px 16px;

  .lv-email-category-header-text-container {
    margin-top: 2px;
  }
`;
const CategoryItems = styled(Flex)`
  padding: 0 16px 20px 16px;
`;
const ShowMore = styled(Flex)`
  padding: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  border: 0;
  background: var(--lv-panel-background-color);
  cursor: pointer;
  margin: -12px 16px 16px 16px;
  &:hover {
    background: var(--color-neutral-purple-100, #ececee);
  }
`;
