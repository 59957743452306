import { networkRequest, URLS } from "~src/api";

import { EditEmailPayload, EditEmailResponse } from "./typings";

export const editEmail = async (
  { body, chromeId, promptName, recs, subject, stage }: EditEmailPayload,
  signal: AbortSignal
): Promise<EditEmailResponse> => {
  const { data } = await networkRequest.post<EditEmailResponse>(
    URLS.editEmail,
    {
      chrome_id: chromeId,
      body,
      subject,
      prompt_name: promptName,
      recs,
      stage,
    },
    {
      signal,
    }
  );
  return data;
};
