import { Flex } from "antd";
import { useCallback } from "react";

import { usePersonalization, useRouteToCartModal } from "~src/customHooks";

import { PanelBackgroundFlex } from "~src/component/Styled";
import { SME_CTA } from "~src/strings";
import {
  SegmentEventLocations,
  SegmentEventNames,
  icebreakerCount,
  segmentEvent,
} from "~src/utils";
import { PersonalizationPanelContainer } from "../../../";

import { StartMyEmailCTA } from "../../../StartMyEmail/StartMyEmailCTA/StartMyEmailCTA";
import { FullWidthFlex } from "../../../StartMyEmail/Styled/FullWidthFlex";
import { Cart, StyledStartMyEmailModalLayout } from "../../Shared";

import { Header } from "./Header/Header";

export const CartEmptiedModal = () => {
  const {
    currentPersonalization: { cart },
  } = usePersonalization();
  const { handleRouteToCartModal } = useRouteToCartModal();

  const count = icebreakerCount(cart);
  const needsInput = count === 0;

  const onCTAClick = useCallback(() => {
    handleRouteToCartModal();
    segmentEvent(
      SegmentEventNames.GenerateClicked,
      SegmentEventLocations.StartMyEmail
    );
  }, [handleRouteToCartModal]);

  return (
    <StyledStartMyEmailModalLayout
      rootClassName="lv-cart-emptied-modal-box"
      isFullHeight
    >
      <Flex justify="space-between" vertical>
        <PersonalizationPanelContainer align="center" gap={12} vertical>
          <Header />
          <FullWidthFlex gap={12} justify="center" vertical>
            <Cart />
          </FullWidthFlex>
        </PersonalizationPanelContainer>
      </Flex>
      <PanelBackgroundFlex className="lv-generate-emails-cta-container">
        <StartMyEmailCTA
          isDisabled={needsInput}
          onClick={onCTAClick}
          text={SME_CTA.GENERATE_EMAILS}
        />
      </PanelBackgroundFlex>
    </StyledStartMyEmailModalLayout>
  );
};
