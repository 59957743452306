import { Button, Text } from "@lavender-ai/lav-components";

import {
  PanelBlock,
  PanelBlockVariant,
} from "~src/layout/MainPanel/PanelBlock";
import EnvelopeErrorSVG from "~src/assets/images/envelope-error.svg";

import {
  NoDataContainer,
  NoDataHeader,
  EnvelopeErrorImg,
  CTAContainer,
  CTAButtons,
  Divider,
} from "./NoDataDisplay.styled";
import {
  CTATabToButtonPropsMap,
  useGetRandomCTAButtonNames,
  SupportedCTAButtons,
  useCTATabNavigationActions,
  useSecondaryTextGenerator,
} from "./utils";

interface Props {
  mainText: string;
  // If not provided, a random secondary text will be generated
  secondaryText?: string;
  // If not provided, 3 random cta buttons will be chosen
  ctaButtonNames?: SupportedCTAButtons[];
}

export const NoDataDisplay = ({
  ctaButtonNames,
  mainText,
  secondaryText,
}: Props) => {
  const ACTIONS = useCTATabNavigationActions();
  const randomCTAs = useGetRandomCTAButtonNames();
  // if undefined choose 3 random cta buttons
  ctaButtonNames = ctaButtonNames ?? randomCTAs;
  const generatedSecondaryText = useSecondaryTextGenerator();

  return (
    <PanelBlock variant={PanelBlockVariant.table}>
      <NoDataContainer>
        <NoDataHeader>
          <EnvelopeErrorImg src={EnvelopeErrorSVG} alt="No data" />
          <Text size="subtext3" color="dark">
            {mainText}
          </Text>
        </NoDataHeader>
        <Divider />
        <CTAContainer>
          <Text color="lavender" size="subtext1">
            {secondaryText ?? generatedSecondaryText}
          </Text>
          <CTAButtons>
            {ctaButtonNames.map((name) => (
              <Button
                key={name}
                onClick={ACTIONS[name]}
                {...CTATabToButtonPropsMap[name]}
              />
            ))}
          </CTAButtons>
        </CTAContainer>
      </NoDataContainer>
    </PanelBlock>
  );
};
