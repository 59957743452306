import { Col, Flex, Row } from "antd";
import styled from "styled-components";

import { Avatar, Icon, Text } from "@lavender-ai/lav-components";

import { RecScoreButton } from "~src/component/Atoms";
import { useFeatureFlag } from "~src/customHooks";

import { LavenderIcon, Message } from "~src/component/typings";
import { NetworkStatus, Recommendations } from "~src/redux/typings";
import { SUGGESTIONS } from "~src/strings";
import { filterUniqueByKey, getCeqColor, getTextClassName } from "~src/utils";

import { ApplyAllFixesButton } from "~src/component/Molecules";
import { StyledMessageContainer, StyledMessageTextContainer } from "../styles";

const { GREAT_JOB, TO_OPTIMIZE, LETS_IMPROVE } = SUGGESTIONS;

interface Props extends Message {
  content: string | undefined;
  score?: number;
  recs?: Recommendations;
  status?: NetworkStatus;
  onClick: (text: string) => void;
  onFixAllClick: (text: string, content: string, recs: Recommendations) => void;
}

const hasRecs = (recs: Recommendations) =>
  Array.isArray(recs) && recs.length > 0;

const StyledRow = styled(Row)`
  padding: 12px 0;
`;

export const LavenderMessage = ({
  content,
  onClick,
  onFixAllClick,
  score,
  recs = [],
  status,
}: Props) => {
  const fixItAllFlagOn = useFeatureFlag("fix_all");
  const showFixItAll = !!(fixItAllFlagOn && score && score < 90);

  return (
    <Flex align="baseline" gap="small">
      <div>
        <Avatar
          color="lavender"
          type="icon"
          size="small"
          icon={
            <Icon
              color="lavender"
              size="small"
              variant={LavenderIcon.IconBeaker}
            />
          }
        />
      </div>
      <StyledMessageContainer>
        <Flex align="baseline" gap="small" vertical>
          {score && (
            <StyledMessageTextContainer $status={status}>
              <span>
                <Text size="body2" color="dark">
                  {hasRecs(recs) ? "Email score is " : "Your email scored a "}
                  <span
                    className={getTextClassName(getCeqColor(score), "body2")}
                  >
                    <b>
                      {score}
                      {hasRecs(recs) ? "." : "!"}
                    </b>
                  </span>
                  {hasRecs(recs) ? ` ${LETS_IMPROVE}` : ` ${GREAT_JOB}`}
                </Text>
              </span>
            </StyledMessageTextContainer>
          )}
          {hasRecs(recs) && (
            <StyledMessageTextContainer $status={status} $isFullWidth vertical>
              <Row>
                <Col span={24}>
                  <Text
                    size="body1"
                    style={{
                      color: "var(--color-neutral-blue-500, #868C96)",
                    }}
                  >
                    {TO_OPTIMIZE}
                  </Text>
                </Col>
              </Row>

              {recs !== null &&
                filterUniqueByKey(recs, "gpt_rec")
                  .filter((rec) => rec.effectonscore > 0 && rec.gpt_rec)
                  .map((rec, index) => (
                    <StyledRow
                      align="middle"
                      justify="center"
                      key={index}
                      gutter={[12, 8]}
                    >
                      <Col span={18}>
                        <Text color="dark" size="body2">
                          {rec.gpt_rec}
                        </Text>
                      </Col>
                      <Col span={6}>
                        <RecScoreButton
                          rec={rec.gpt_rec}
                          effectOnScore={
                            Math.round(rec.effectonscore * 10) / 10
                          }
                          onClick={onClick}
                        />
                      </Col>
                    </StyledRow>
                  ))}
              {showFixItAll && (
                <ApplyAllFixesButton
                  content={content}
                  isFullWidth
                  recs={recs}
                  onClick={onFixAllClick}
                />
              )}
            </StyledMessageTextContainer>
          )}
        </Flex>
      </StyledMessageContainer>
    </Flex>
  );
};
