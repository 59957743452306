import { Flex, Space } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Avatar, Button, Icon, Text } from "@lavender-ai/lav-components";
import {
  useDomNodes,
  useLvIdentifier,
  usePersonalization,
} from "~src/customHooks";
import styled from "styled-components";
import { extractUserDataForProfile } from "~src/utils";
import { NetworkStatus } from "~src/redux/typings";
import { LineSkeleton } from "../../../Utilities/Loading";
import { getGenerateIntro } from "~src/redux/frameworkAi";
import { PredictLeadEvent } from "~src/redux/frameworkAi/frameworkAi";
import { prependBodyText } from "~src/layout/MainPanel/EmailCoachPanel/utils";
import { useCarouselContext } from "../CarouselContextProvider";
import { selectChromeId, useAppDispatch, useAppSelector } from "~src/redux";

const CustomAvatarIcon = styled(Icon)`
  display: block;
`;

const ChatResponse = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: var(--radius-md, 8px);
  background: var(--color-base-White);
  /* Shadow/sm */
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
`;

const UserResponse = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: var(--radius-md, 8px);
  /* Shadow/sm */
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--color-lavender-100, #d5cbf2);
  background: var(--color-lavender-100, #d5cbf2);
`;

export const ChatModal = () => {
  const dispatch = useAppDispatch();

  const lvIdentifier = useLvIdentifier();
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();
  const fetchGenerateIntroStatus: NetworkStatus = useAppSelector(
    (state) => state.generateIntro?.[lvIdentifier]?.getGenerateIntroStatus
  );
  const generateIntroCategory: string = useAppSelector(
    (state) => state.generateIntro?.[lvIdentifier].category
  );
  const generateIntroObject: PredictLeadEvent = useAppSelector(
    (state) => state.generateIntro?.[lvIdentifier].object
  );
  const generateIntroSuggestions: string[] = useAppSelector(
    (state) => state.generateIntro?.[lvIdentifier].suggestions
  );

  const user = useAppSelector((state) => state.userAccount.settings.user);
  const { avatar, displayName } = extractUserDataForProfile(user);
  const chromeId = useAppSelector((state) => selectChromeId(state));
  const { prev, prevModal } = useCarouselContext();

  const { panelParent } = useDomNodes();

  return (
    <div>
      <Flex
        className="lv-cta"
        justify="flex-start"
        align="center"
        style={{ width: "100%", height: "64px", paddingLeft: "20px" }}
        onClick={() => {
          prev(prevModal);
        }}
        vertical={false}
      >
        <LeftOutlined
          width="16px"
          height="16px"
          style={{
            padding: "8px",
            borderRadius: "14px",
            backgroundColor: "#E6E9F0",
            marginRight: "10px",
          }}
        />
        <Text color="black" size="subtext2">
          Generate Intro
        </Text>
      </Flex>
      <Space size={16} style={{ margin: "0 20px" }} direction={"vertical"}>
        <Flex gap={8}>
          <Avatar
            alt={displayName}
            src={avatar as string}
            shape={"circle"}
            style={{
              margin: "0px auto",
            }}
          />
          <UserResponse>
            <Text size="body1" color="black">
              Write an intro for an email based on the following information,
              &quot;
              {generateIntroObject.attributes.additional_data.article_sentence}
              &quot;
            </Text>
            {/* Text here about the information to send to backend */}
          </UserResponse>
        </Flex>
        <Flex gap={8}>
          <Avatar
            alt={"Open AI"}
            icon={<CustomAvatarIcon variant="IconBrandOpenai" />}
            shape={"circle"}
            style={{
              margin: "0px auto",
              background: "var(--color-neutral-purple-800)",
            }}
          />
          <Space direction={"vertical"} size={8} style={{ flex: "1 0 0" }}>
            {fetchGenerateIntroStatus === NetworkStatus.success && (
              <>
                {generateIntroSuggestions.map((suggestion, index) => (
                  <ChatResponse key={index}>
                    {suggestion}
                    <Space size={4} direction={"horizontal"} align={"start"}>
                      &nbsp;
                      <Button
                        size={"medium"}
                        onClick={() => {
                          prependBodyText(panelParent, suggestion);
                        }}
                      >
                        Insert &nbsp;
                        <Icon
                          size={"small"}
                          variant={"IconCircleArrowRight"}
                          color={"dark"}
                        />
                      </Button>
                    </Space>
                  </ChatResponse>
                ))}
                <Space>
                  <Button
                    size={"small"}
                    onClick={() => {
                      void dispatch(
                        getGenerateIntro({
                          lvIdentifier,
                          chromeId,
                          category: generateIntroCategory,
                          object: generateIntroObject,
                          ignore_cache: false,
                          email,
                        })
                      );
                    }}
                  >
                    <Icon
                      size={"small"}
                      variant={"IconRefresh"}
                      color={"lavender"}
                    />
                    &nbsp; Regenerate
                  </Button>
                </Space>
              </>
            )}
            {fetchGenerateIntroStatus === NetworkStatus.loading && (
              <ChatResponse>
                <Text size="body1" color="black">
                  <LineSkeleton loading={true} width={240}>
                    <></>
                  </LineSkeleton>
                </Text>
                <Text size="body1" color="black">
                  <LineSkeleton loading={true} width={240}>
                    <></>
                  </LineSkeleton>
                </Text>
                <Text size="body1" color="black">
                  <LineSkeleton loading={true} width={200}>
                    <></>
                  </LineSkeleton>
                </Text>
              </ChatResponse>
            )}
          </Space>
        </Flex>
      </Space>
    </div>
  );
};
