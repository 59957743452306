import { styled } from "styled-components";

import { StyledCompanyBlockHeader } from "~src/component/Organisms/Personalization/Views/Feed/Company";

export const StyledCartHeader = styled(StyledCompanyBlockHeader)`
  padding: 0;

  .lv-cart-header {
    padding: 16px 0;

    .lv-cart-header-secondary-text {
      margin-left: -8px;
    }
  }
`;
