import { memo, useEffect, useState } from "react";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { Flex } from "antd";
import { styled } from "styled-components";

import { AssistantMessage } from "~src/component/Molecules/Message/AssistantMessage/AssistantMessage";
import { LavenderLogo } from "~src/component/Atoms/LavenderLogo";
import { StreamMessage } from "~src/component/Molecules/Message/StreamMessage/StreamMessage";
import { useStartMyEmail, useStartMyEmailGeneration } from "~src/customHooks";

import { APIResponseStatus } from "~src/api/typings";
import { StyledMessageContainer } from "~src/component/Molecules/Message/styles";
import { NetworkStatus } from "~src/redux/typings";
import {
  generatedEmailCount,
  initStreamedPayload,
  normalizeCart,
} from "~src/utils";
import { CopyButton } from "~src/component/Molecules";
import { sanitize } from "dompurify";

const StyledGeneratedEmails = styled(Flex)`
  ${StyledMessageContainer} {
    width: var(--lv-expanded-panel-size);
  }
`;

interface Email {
  content: string | undefined;
  key: string | number;
  status?: APIResponseStatus;
}

export const GeneratedEmails = () => {
  const {
    currentPersonalization: {
      profile: { email },
    },
    isWebsocketsOn,
  } = useStartMyEmail();
  const {
    generatedEmailsData: startMyEmailHTTPResult,
    generatedEmailsStatus: status,
    generatingEmailsData,
    generatedEmailsError,
    generatingEmailsStatus,
    generationCount,
    isEmailGenerationRejected,
    isEmailGenerationSuccess,
  } = useStartMyEmailGeneration();

  const [streamedAnswers, setStreamedAnswers] = useState<Email[]>(() =>
    initStreamedPayload(generationCount)
  );

  useEffect(() => {
    if (
      !isWebsocketsOn ||
      !generatingEmailsData ||
      [APIResponseStatus.initiated, undefined, null].includes(
        generatingEmailsStatus
      )
    ) {
      return;
    }

    if (
      generatingEmailsStatus === APIResponseStatus.error ||
      isEmailGenerationRejected
    ) {
      setStreamedAnswers([
        {
          content: generatedEmailsError,
          key: "error",
          status: APIResponseStatus.error,
        },
      ]);
      return;
    }

    const normalized = isEmailGenerationSuccess
      ? startMyEmailHTTPResult
      : normalizeCart(generatingEmailsData);
    if (!normalized) {
      return;
    }
    const emailIds = normalized.result.emails;
    if (emailIds.length === 0) {
      return;
    }
    const emails = emailIds.map((emailId) => {
      const content = normalized.cart.emails[emailId].content.replace(
        /\\n/g,
        "<br/>"
      );
      return {
        content: sanitize(content),
        key: emailId,
        status: generatingEmailsStatus,
      };
    });
    setStreamedAnswers(emails);
  }, [
    email,
    generatedEmailsError,
    startMyEmailHTTPResult,
    generatingEmailsData,
    generatingEmailsStatus,
    generationCount,
    isEmailGenerationRejected,
    isEmailGenerationSuccess,
    isWebsocketsOn,
  ]);

  useEffect(() => {
    if (
      isWebsocketsOn ||
      [QueryStatus.uninitialized, QueryStatus.pending].includes(status)
    ) {
      return;
    }

    if (isEmailGenerationRejected) {
      setStreamedAnswers([
        {
          content: generatedEmailsError,
          key: "error",
          status: APIResponseStatus.error,
        },
      ]);
      return;
    }

    if (
      startMyEmailHTTPResult === undefined ||
      generatedEmailCount(startMyEmailHTTPResult) === 0
    ) {
      return;
    }

    const emails = startMyEmailHTTPResult.result.emails.map((emailId) => {
      const content = startMyEmailHTTPResult.cart.emails[
        emailId
      ].content.replace(/\\n/g, "<br/>");
      return {
        content: sanitize(content),
        key: emailId,
        status: APIResponseStatus.success,
      };
    });
    setStreamedAnswers(emails);
  }, [
    generatedEmailsError,
    startMyEmailHTTPResult,
    status,
    isEmailGenerationRejected,
    isWebsocketsOn,
    generationCount,
  ]);

  return (
    <StyledGeneratedEmails gap="small">
      <div>
        <LavenderLogo size={32} />
      </div>
      <Flex vertical gap="small">
        {isWebsocketsOn ? (
          <StreamedEmails emails={streamedAnswers} />
        ) : (
          <Emails emails={streamedAnswers} status={status} />
        )}
      </Flex>
    </StyledGeneratedEmails>
  );
};

interface StreamedEmailsProps {
  emails: Email[];
}

const StreamedEmails = memo(function StreamedEmails({
  emails,
}: StreamedEmailsProps) {
  return emails.map(({ content, key, status }) => {
    return <StreamMessage content={content} key={key} status={status} />;
  });
});

interface EmailsProps {
  emails: Email[];
  status: QueryStatus;
}

const Emails = memo(function Emails({ emails, status }: EmailsProps) {
  return emails.map(({ content, key }) => {
    return status === QueryStatus.pending ? (
      <AssistantMessage
        content="..."
        key={key}
        status={NetworkStatus.loading}
      />
    ) : (
      <AssistantMessage
        actions={
          content ? [<CopyButton key="copy" copyText={content} />] : undefined
        }
        content={content}
        key={key}
        status={
          status === QueryStatus.rejected ? NetworkStatus.failed : undefined
        }
      />
    );
  });
});
