import styled from "styled-components";
import ContentEditable from "react-contenteditable";

import { UserDataAvatar } from "../Molecules";

import { useAppSelector } from "~src/redux";

import { imgSources } from "./images";
import { CSS_PREFIX } from "./MobileDevice.types";
import {
  MOBILE_DEVICE_WIDTH,
  MOBILE_DEVICE_HEIGHT,
} from "../../constants/general";
import { extractAvatarFallback, extractUserDataForProfile } from "../../utils";
import { useShouldDisplayPaywall } from "~src/customHooks";
import { PaywallModal } from "../Molecules/PaywallModal";
import { DragHandle } from "./DragHandle";
import { MobileDynamicTime } from "./MobileDynamicTime";
import DOMPurify from "dompurify";
import { Flex } from "antd";
import { useState } from "react";
import { InsertType } from "~src/redux/typings";

const Phone = styled.div<{ $darkMode: boolean }>`
  /* Layout */
  width: ${MOBILE_DEVICE_WIDTH}px;
  height: ${MOBILE_DEVICE_HEIGHT}px;
  position: absolute;
  top: 0;
  /* Appearance */
  background-color: ${(props) => (props.$darkMode ? "#202125" : "#fff")};
  border-radius: 4rem;
  z-index: 10001;
`;

const Bezel = styled.div`
  /* Layout */
  width: 300px;
  height: 609px;
  position: absolute;
  top: -2px;
  right: -5px;
  z-index: 1;
  background-image: url(${imgSources.mobileBackground});
  z-index: 1;
  pointer-events: none;
`;

const Screen = styled.div<{ $darkMode: boolean }>`
  transform: scale(0.93);
  max-height: 609px;
  height: 609px;
  margin: 0 auto;
  position: relative;
  top: -32px;
  overflow-wrap: break-word;
  color: ${(props) => (props.$darkMode ? "#fff" : "#000")};
`;

const IconBackground = styled.img`
  /* Layout */
  height: 85px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  /* Appearance */
  border-radius: 15px 15px 0 0;
`;

const Scrollable = styled.div`
  /* Layout */
  overflow-y: scroll;
  max-height: 534px;
  height: 534px;
  border-radius: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SubjectBackground = styled.div<{ $darkMode: boolean }>`
  /* Layout */
  height: 90px;

  /* Appearance */
  background-image: ${(props) =>
    props.$darkMode
      ? `url(${imgSources.mobileSubjectBackgroundDarkMode})`
      : `url(${imgSources.mobileSubjectBackground})`};
  background-repeat: no-repeat;
  background-size: contain;
`;

const SubjectMessageContainer = styled.div`
  /* Layout */
  position: relative;
  top: 5px;
  left: 16px;
  width: fit-content;
  max-width: 85%;
  min-height: 1.5rem;
`;

const SubjectMessageEditable = styled.input<{ $darkMode: boolean }>`
  /* Layout */
  display: inline-block;
  width: fit-content;
  max-width: 79%;
  /* Appearance */
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  line-height: initial;
  border: none;
  outline: none;
  resize: none;
  color: ${(props) => (props.$darkMode ? "#fff" : "#000")};
  background-color: ${(props) => (props.$darkMode ? "#202125" : "#fff")};
`;

const SubjectMessage = styled.div<{ $darkMode: boolean }>`
  /* Layout */
  display: inline-block;
  width: fit-content;
  max-width: 79%;
  /* Appearance */
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  line-height: initial;
  border: none;
  outline: none;
  resize: none;
  color: ${(props) => (props.$darkMode ? "#fff" : "#000")};
  background-color: ${(props) => (props.$darkMode ? "#202125" : "#fff")};
`;

const InboxIcon = styled.div<{ $darkMode: boolean }>`
  /* Layout */
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  margin: 0 5px;
  vertical-align: super;
  line-height: initial;

  /* Appearance */
  color: ${(props) => (props.$darkMode ? "#fff" : "#000")};
  background: ${(props) => (props.$darkMode ? "#1f2025" : "#eeeeee")};
  border: ${(props) => (props.$darkMode ? "solid 1px #a3a6ab" : "#fff")};
  border-radius: 5px;
`;

const Name = styled.div<{ $darkMode: boolean }>`
  /* Layout */
  position: relative;
  top: -20px;
  left: 52px;
  width: 65%;
  line-height: initial;
  /* Appearance */
  color: ${(props) => (props.$darkMode ? "#fff" : "#000")};
  font-size: 12px;
  text-align: left;
`;

const ProfileImage = styled.div`
  /* Layout */
  width: 38px;
  height: 38px;
  position: relative;
  top: 16px;
  left: 12px;

  /* Appearance */
  border-radius: 25px;

  /* Outlook */
  & .mectrl_profilepic {
    height: 90%;
    width: 90%;
  }
  img.lavender-profile-img-tag {
    width: 90%;
    height: 90%;
    border-radius: 100%;
  }
`;

const EmailBodyEditable = styled(ContentEditable)<{ $darkMode?: boolean }>`
  /* Layout */
  padding: 0 5%;
  width: 95%;
  min-height: 360px;
  max-height: 360px;
  position: relative !important;
  box-sizing: content-box;
  height: fit-content;
  z-index: 1500 !important;
  text-align: left;
  /* Appearance */
  color: ${(props) => (props.$darkMode ? "#fff" : "#000")};
  border: none;
  outline: none;
  & [data-smartmail="gmail_signature"] {
    color: #888888;
  }
  resize: none;
  & [data-smartmail="gmail_signature"] a,
  & [data-smartmail="gmail_signature"] a:visited {
    color: #15c;
  }
  //INFO: overwrite Gmail
  ${(props) => {
    if (props.$darkMode) {
      return `&.Am {
        background: inherit;
      }`;
    }
  }}
  &.blur {
    filter: blur(0.3rem);
  }
  overflow: hidden;
  overflow-y: scroll;
  font-size: 14px;
`;

const EmailBody = styled.div<{ $darkMode?: boolean }>`
  /* Layout */
  padding: 0 5%;
  width: 95%;
  min-height: 360px;
  max-height: 360px;
  position: relative !important;
  box-sizing: content-box;
  height: fit-content;
  z-index: 1500 !important;
  text-align: left;
  /* Appearance */
  color: ${(props) => (props.$darkMode ? "#fff" : "#000")};
  border: none;
  outline: none;
  & [data-smartmail="gmail_signature"] {
    color: #888888;
  }
  resize: none;
  & [data-smartmail="gmail_signature"] a,
  & [data-smartmail="gmail_signature"] a:visited {
    color: #15c;
  }
  //INFO: overwrite Gmail
  ${(props) => {
    if (props.$darkMode) {
      return `&.Am {
        background: inherit;
      }`;
    }
  }}
  &.blur {
    filter: blur(0.3rem);
  }
  overflow: hidden;
  overflow-y: scroll;
  font-size: 14px;
`;

const Footer = styled.div<{ $darkMode: boolean }>`
  /* Layout */
  display: block;
  height: 50px;
  width: 100%;
  margin: auto;
  /* Appearance */
  background-image: ${(props) =>
    props.$darkMode
      ? `url(${imgSources.mobileEmailFooterDarkMode})`
      : `url(${imgSources.mobileEmailFooter})`};
  border-bottom: ${(props) =>
    props.$darkMode ? "29px solid #303134" : "26px solid white"};
  border-radius: 0 0 32px 32px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: content-box;
`;

const GestureBar = styled.div`
  width: 33%;
  border-radius: 5px;
  position: fixed;
  bottom: -2px;
  left: 33%;
  border: solid 2px black;
  backgroundcolor: black;
`;

const Warning = styled(Flex)`
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  width: fit-content;
  margin: -40px 53px;
  padding: 4px 8px;
  min-width: 32px;
  min-height: 32px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
`;

export const MobileContainer = ({
  $darkMode = false,
  firstName,
  lastName,
  subjectText,
  emailBody,
  editMailboxSubject,
  editMailBody,
}: {
  $darkMode: boolean;
  firstName: string;
  lastName: string;
  subjectText: string;
  emailBody: string;
  editMailboxSubject: (newSubject: string) => void;
  editMailBody: (newBody: string) => void;
}) => {
  const [showWarning, setShowWarning] = useState(false);

  const triggerWarning = () => {
    setShowWarning(true);
    setTimeout(() => {
      setShowWarning(false);
    }, 2500);
  };

  const { platformDisplayName, insertType } = useAppSelector(
    (state) =>
      state.config.settings || {
        platformDisplayName: "this platform",
        insertType: InsertType.Copy,
      }
  );
  const user = useAppSelector(
    ({
      userAccount: {
        settings: { user },
      },
    }) => user
  );
  const { avatar, displayName } = extractUserDataForProfile(user);

  return (
    <div className={`${CSS_PREFIX}-device`}>
      <Phone $darkMode={$darkMode}>
        <DragHandle type="phone" />
        <Bezel />
        <Screen $darkMode={$darkMode}>
          {useShouldDisplayPaywall("mobilePreview") && (
            <PaywallModal view="mobilePreview" />
          )}
          <MobileDynamicTime />
          {$darkMode ? (
            <IconBackground src={imgSources.mobileIconHeaderDarkMode} />
          ) : (
            <IconBackground src={imgSources.mobileIconHeader} />
          )}
          <Scrollable>
            <SubjectBackground $darkMode={$darkMode}>
              <SubjectMessageContainer>
                {insertType === InsertType.Insert ? (
                  <SubjectMessageEditable
                    $darkMode={$darkMode}
                    onChange={(e) => {
                      editMailboxSubject(e.target.value);
                    }}
                    value={subjectText}
                  />
                ) : (
                  <SubjectMessage
                    $darkMode={$darkMode}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(subjectText),
                    }}
                  />
                )}
                <InboxIcon $darkMode={$darkMode}>Inbox</InboxIcon>
              </SubjectMessageContainer>
              <ProfileImage>
                <UserDataAvatar
                  size="small"
                  src={avatar}
                  fallback={extractAvatarFallback(displayName, user?.email)}
                />
              </ProfileImage>
              <Name $darkMode={$darkMode}>
                {firstName}
                &nbsp;
                {lastName}
              </Name>
            </SubjectBackground>
            {insertType === InsertType.Insert ? (
              <EmailBodyEditable
                $darkMode={$darkMode}
                html={emailBody}
                onChange={(e) => {
                  editMailBody(e.currentTarget.innerHTML);
                }}
              />
            ) : (
              <>
                <EmailBody
                  $darkMode={$darkMode}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(emailBody),
                  }}
                  onClick={() => {
                    triggerWarning();
                  }}
                />

                {showWarning && (
                  <Warning>Editing Disabled for {platformDisplayName}</Warning>
                )}
              </>
            )}
            <Footer $darkMode={$darkMode} />
          </Scrollable>
          <GestureBar />
        </Screen>
      </Phone>
    </div>
  );
};
