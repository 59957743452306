import { Flex } from "antd";
import { styled } from "styled-components";
import { XOR } from "ts-xor";

import { PropsForStyling } from "../typings";

type WidthProps = XOR<
  { isFullWidth: boolean },
  { isPanelWidth: boolean }
>;

type EnhancedFlexProps = WidthProps & {
  isFullHeight?: boolean;
  margin?: string | number;
  padding?: string | number;
  textAlign?: string;
};

export const EnhancedFlex = styled(Flex)<PropsForStyling<EnhancedFlexProps>>`
  padding: ${({ $padding: padding }) => padding};
  margin: ${({ $margin: margin }) => margin};
  ${({ $isFullHeight: isFullHeight }) => isFullHeight && `min-height: 100%;`}
  ${({ $isFullWidth: isFullWidth }) => isFullWidth && `width: 100%;`}
  ${({ $isPanelWidth: isPanelWidth }) =>
    isPanelWidth && `width: var(--lv-expanded-panel-size);`}
  ${({ $textAlign: textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export const FullWidthFlex = styled(Flex)<
  PropsForStyling<Omit<EnhancedFlexProps, "isFullWidth">>
>`
  padding: ${({ $padding: padding }) => padding};
  ${({ $isFullHeight: isFullHeight }) => isFullHeight && `min-height: 100%;`}
  width: 100%;
`;

export const PanelBackgroundFlex = styled(EnhancedFlex)<
  PropsForStyling<EnhancedFlexProps>
>`
  background: var(--lv-panel-background-color);
`;
