import { Flex } from "antd";
import { styled } from "styled-components";

import { Text, TextArea, TextAreaProps } from "@lavender-ai/lav-components";

import { PropsForStyling } from "~src/component/typings";

interface Props extends Omit<TextAreaProps, "size"> {
  label: string;
  name: string;
  initialHeight?: number;
}

interface StyledFlexProps {
  initialHeight: number;
}

const StyledTextAreaCountContainer = styled(Flex)`
  white-space: pre-wrap;
`;

const StyledTextAreaCount = styled(Text)`
  font-size: 13px;
  font-weight: 400;
`;

const StyledFlex = styled(Flex)<PropsForStyling<StyledFlexProps>>`
  .lav-components-textarea.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    font-size: 14px;
  }

  .lv-labeled-textarea textarea {
    height: ${({ $initialHeight }) => $initialHeight}px;
  }
`;

export const LabeledTextArea = ({
  label,
  name,
  initialHeight = 130,
  minLength,
  ...props
}: Props) => {
  const countConfig = makeCountConfig({
    count: 500,
    maxLength: 500,
    minLength,
  });
  return (
    <StyledFlex vertical gap="small" $initialHeight={initialHeight}>
      <Text color="dark" size="subtext1" id={name}>
        {label}
      </Text>
      <TextArea
        {...props}
        aria-labelledby={name}
        className="lv-labeled-textarea"
        count={countConfig}
        maxLength={500}
      />
    </StyledFlex>
  );
};

const makeCountConfig = ({ count, maxLength, minLength = 0 }) => ({
  count,
  max: maxLength,
  show: ({ count }) => (
    <StyledTextAreaCountContainer>
      <StyledTextAreaCount
        color={count >= minLength ? "dark" : "negative"}
        size="body1"
      >
        {count}
      </StyledTextAreaCount>
      {!!maxLength && (
        <StyledTextAreaCount color="dark" size="body1">
          {` / ${maxLength}`}
        </StyledTextAreaCount>
      )}
    </StyledTextAreaCountContainer>
  ),
});
