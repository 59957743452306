import { HubspotResponse } from "./hubspot";
import { SalesforceResponse } from "./salesforce";

export interface CRMResponse {
  has_gong_access: boolean;
  has_hubspot_access: boolean;
  has_salesforce_access: boolean;
  gong_data: null;
  hubspot_data: HubspotResponse | null;
  salesforce_data: SalesforceResponse[];
}
