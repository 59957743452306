import clsx from "clsx";

import { usePersonalization, useSearch } from "~src/customHooks";
import {
  PanelBlockHeader,
  PanelBlock,
  PanelBlockVariant,
} from "~src/layout/MainPanel/PanelBlock";
import { SearchInput, NoDataDisplay } from "~src/component/Molecules";
import { PERSONALIZATION_HEADER } from "~src/strings";
import { ConditionalSwitch } from "~src/component/Utilities";
import { CompanySubTab, ProfileSubTab } from "~src/component/typings";

import { JobAggregate } from "./JobAggregate";
import { EmptyResults } from "./EmptyResults";
import { JobResults } from "./JobResults";

const { JOBS } = PERSONALIZATION_HEADER;

export const JobOpenings = () => {
  const {
    currentPersonalization: {
      company: {
        jobs: { aggregate, jobs = [], loading: jobsLoading },
      },
      profile: { loading },
    },
  } = usePersonalization();

  const { isDisabled, searchTerm, filteredItems, handleSearch } = useSearch(
    jobs,
    ["title"]
  );

  const countConfig = makeCountConfig(filteredItems.length);
  const isLoading = jobsLoading || loading;
  const isEmptyResults = !filteredItems?.length && !isLoading;

  return (
    <ConditionalSwitch>
      {jobs.length === 0 && aggregate.length === 0 && !isLoading && (
        <NoDataDisplay
          mainText="We couldn't find any Job Openings"
          ctaButtonNames={[
            ProfileSubTab.SOCIAL_FEED,
            CompanySubTab.TECH_STACK,
            CompanySubTab.NEWS,
          ]}
        />
      )}
      <PanelBlock variant={PanelBlockVariant.table} loading={isLoading}>
        <PanelBlockHeader headerText={JOBS} loading={isLoading} />
        <SearchInput
          countConfig={countConfig}
          disabled={isDisabled}
          handleSearch={handleSearch}
          loading={isLoading}
          placeholder="Search job openings..."
          searchTerm={searchTerm}
        />
        <div
          className={clsx("lav-panel-table-container", {
            "--hidden": !!searchTerm,
          })}
        >
          <JobAggregate aggregate={aggregate} loading={isLoading} />
        </div>
        <PanelBlock variant={PanelBlockVariant.list} loading={isLoading}>
          {isEmptyResults ? <EmptyResults /> : <JobResults />}
        </PanelBlock>
      </PanelBlock>
    </ConditionalSwitch>
  );
};

const makeCountConfig = (count: number) => ({
  count,
  formatter: (count: number) =>
    `${count} ${count === 1 ? "job opening" : "job openings"}`,
});
