import { useEffect, useLayoutEffect } from "react";
import { Flex } from "antd";
import styled from "styled-components";

import { Avatar, Icon } from "@lavender-ai/lav-components";

import { PaywallModal, UserDataAvatar } from "~src/component/Molecules";
import { Steps } from "~src/component/Organisms/NoRecipients";
import { useScrollContext, usePanelTabContext } from "~src/contexts";
import {
  useLvIdentifier,
  usePersonalizationPanel,
  usePrevious,
  useShouldDisplayPaywall,
} from "~src/customHooks";
import { PanelLayout } from "~src/layout/MainPanel/Panel/PanelLayout/PanelLayout";

import { PERSONALIZATION_PANEL } from "~src/strings";
import { extractUserDataForProfile } from "../utils";
import { PersonalizationContent } from "~src/component/Organisms";
import { useAppDispatch, useAppSelector } from "~src/redux/hooks";
import { LavenderIcon } from "~src/component/typings";
import { emailAction } from "~src/redux";

const { LEARN_ABOUT, LEAVING_THIS_PANEL_OPEN, HOW_TO_PERSONALIZE } =
  PERSONALIZATION_PANEL;

const StyledNoRecipientsContent = styled(Flex)`
  padding-top: 48px;
  min-width: var(--lv-expanded-panel-size);
  .ant-badge.lav-components-badge {
    height: 90px;
  }
`;

const NoRecipientsContent = () => {
  const user = useAppSelector(
    ({
      userAccount: {
        settings: { user },
      },
    }) => user
  );
  const { avatar } = extractUserDataForProfile(user);
  // optional chaining to avoid error in Storybook
  const fallbackSrc = chrome.runtime?.getURL?.("images/static/Avatar.png");
  const src = avatar || fallbackSrc;

  const openToLeft = useAppSelector(
    ({ config }) =>
      config.settings?.selectors?.composeContainers?.[0]?.menu.panelOpenToRight
  );
  return (
    <StyledNoRecipientsContent align="center" gap="large" vertical>
      <UserDataAvatar
        badge={{
          count: (
            <Avatar
              shape="square"
              style={{
                border: "3px solid var(--color-base-White)",
                borderRadius: "13.715px",
                boxShadow:
                  "2.572px 3.429px 0px 0px var(--color-neutral-blue-100)",
                transform: "rotate(6.022deg)",
                position: "relative",
                left: "45px",
                top: "-35px",
                width: "44px",
                height: "44px",
              }}
              icon={
                <Icon
                  color="white"
                  fill="none"
                  size="medium"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.54295"
                  variant={LavenderIcon.IconSearch}
                />
              }
            />
          ),
        }}
        src={src}
        {...(src !== fallbackSrc && { fallbackSrc })}
        shape="square"
        size="small"
        style={{
          borderRadius: "16px",
          height: "72px",
          transform: "rotate(-3deg)",
          width: "72px",
        }}
        usePlaceholderFallback
      />
      <Flex
        align="flex-start"
        gap={32}
        style={{
          alignSelf: "stretch",
          background: "var(--lv-panel-background-color)",
          padding: "0px 40px",
        }}
        vertical
      >
        <Steps
          header={LEARN_ABOUT}
          subheader={HOW_TO_PERSONALIZE}
          steps={[
            {
              icon: LavenderIcon.IconAt,
              text: `Start by adding an email to the <b>“Recipients”</b> input on the
              ${openToLeft ? "left" : "right"}.`,
            },
            {
              icon: LavenderIcon.IconPointer,
              text: `<b>Next</b>, click into the <b>“Subject”</b> or Email body to
              begin search.`,
            },
            {
              icon: LavenderIcon.IconSparkles,
              text: LEAVING_THIS_PANEL_OPEN,
            },
          ]}
        />
      </Flex>
    </StyledNoRecipientsContent>
  );
};

export const PersonalizationPanel = () => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const { didUserSwitchProfileInView, recipientsArray } =
    usePersonalizationPanel();

  const { tab } = usePanelTabContext();
  const prevTab = usePrevious(tab);
  const contextNode = useScrollContext();
  const shouldShowPaywall = useShouldDisplayPaywall("profile");

  useEffect(() => {
    if (shouldShowPaywall || recipientsArray.length === 0) {
      return;
    }

    dispatch(emailAction.tagPersonalizationAssistantUsed({ id: lvIdentifier }));
  }, [dispatch, lvIdentifier, recipientsArray.length, shouldShowPaywall]);

  useLayoutEffect(() => {
    if (
      ((prevTab && tab && prevTab !== tab) || didUserSwitchProfileInView) &&
      // Remove this part of the condition to enable for the no recipients view when we want it
      recipientsArray.length > 0 &&
      contextNode !== null
    ) {
      console.debug(
        "RUNNING LAYOUT EFFECT: RESETTING SCROLL TO TOP, tab: ",
        tab,
        "prevTab: ",
        prevTab
      );
      contextNode.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [
    tab,
    prevTab,
    contextNode,
    didUserSwitchProfileInView,
    recipientsArray.length,
  ]);

  return (
    <PanelLayout
      rootClassName="lv-personalization-box"
      style={{
        width: "var(--lv-expanded-panel-size)",
      }}
    >
      {recipientsArray.length > 0 ? (
        <>
          {shouldShowPaywall && <PaywallModal view="profile" />}
          <PersonalizationContent />
        </>
      ) : (
        <NoRecipientsContent />
      )}
    </PanelLayout>
  );
};
