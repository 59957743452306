import { styled } from "styled-components";

import { imgSources } from "./images";
import { CSS_PREFIX } from "./MobileDevice.types";
import ContentEditable from "react-contenteditable";
import { WatchPaywall } from "./WatchPaywall";

import { WATCH_DEVICE_WIDTH, WATCH_DEVICE_HEIGHT } from "../../constants";
import { useShouldDisplayPaywall } from "~src/customHooks";
import { DragHandle } from "./DragHandle";
import { useAppSelector } from "~src/redux";
import DOMPurify from "dompurify";
import { useState } from "react";
import { Flex } from "antd";
import { InsertType } from "~src/redux/typings";

const WatchDevice = styled.div`
  /* Layout */
  width: ${WATCH_DEVICE_WIDTH};
  height: ${WATCH_DEVICE_HEIGHT};
`;

const Bezel = styled.div`
  /* Layout */
  width: 200px;
  height: 339px;
  position: absolute;
  top: -2px;
  right: -5px;

  /* Appearance */
  background-image: url(${imgSources.watch});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  pointer-events: none;
`;

const Notification = styled.div`
  /* Layout */
  margin: 31% auto;
  background: #fff;
  width: 87%;
  height: 208px;
  max-height: 208px;
  border-radius: 2rem;
  overflow: hidden;
  & svg {
    margin: 6px;
  }
  &.hide {
    display: none;
  }
  background-color: #000;
  color: #fff;
`;

const NotificationHeader = styled.div`
  /* Layout */
  position: relative;
  width: 91%;
  font-size: 11px;
  padding: 6px 4px 4px;
  height: 0;
  z-index: 10;
`;

const MessageContainerScroll = styled.div`
  /* Layout */
  margin: 4px;
  max-height: 200px;
  overflow-y: scroll;
  /* Appearance */
  font-size: 12px;
  font-weight: 500;
  /* Appearance */
  background: transparent;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MailIcon = styled.img`
  display: inline-block;
  background-color: transparent;
  border-radius: 100%;
  position: absolute;
  top: 12px;
  left: 5%;
  z-index: 2;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 12px;
  left: 5%;
  z-index: 2;
  img {
    height: 100%;
  }
`;

const TimeAgo = styled.div`
  margin-top: 21%;
  display: inline-block;
  float: right;
  font-weight: 300;
  color: #a3a4a7;
  font-size: 10px;
  font-family: system-ui;
`;

const NotificationBackground = styled.div`
  position: relative;
  padding: 11% 5% 7%;
  border-radius: 20px;
  background-color: #ffffffaa;
  margin-top: 10%;
  /* backdrop-filter: blur(10px); */
  margin-top: 14%;
  margin-bottom: 14%;
  min-height: 140px;
  /* .dark-mode &{ */
  background-color: rgba(58, 58, 58, 80%);
  /* } */
`;

const Name = styled.div`
  text-align: left;
  font-size: 12px;
  font-family: system-ui;
`;

const EmailSubjectEditable = styled(ContentEditable)`
  text-align: left;
  display: block;
  width: 100%;
  /* Appearance */
  outline: none;
  font-family: system-ui;
  line-height: initial;
  border: none;
  outline: none;
  resize: none;
  color: #fff;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmailSubject = styled.div`
  text-align: left;
  display: block;
  width: 100%;
  /* Appearance */
  outline: none;
  font-family: system-ui;
  line-height: initial;
  border: none;
  outline: none;
  resize: none;
  color: #fff;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmailBodyEditable = styled(ContentEditable)`
  /* Layout */
  text-align: left;
  padding: 0;
  width: fit-content;

  /* Appearance */
  background: transparent;
  outline: none;
  & [data-smartmail="gmail_signature"] {
    color: #888888;
  }
  & [data-smartmail="gmail_signature"] a,
  & [data-smartmail="gmail_signature"] a:visited {
    color: #15c;
  }
  //INFO: overwrite Gmail
  .dark-mode &.Am {
    background: inherit;
  }
  font-weight: 300 !important;
  font-family: system-ui !important;
  &.blur {
    filter: blur(0.3rem);
  }

  //INFO: disable images
  & img {
    display: none;
  }
  line-height: 125% !important;
  font-size: 12px !important;
  position: relative !important;
  br {
    display: none;
  }
  .gmail_signature,
  .sl-signature,
  .outreach-signature,
  div#Signature,
  div[data-smartmail="gmail_signature"],
  .gmail_signature_prefix {
    display: none !important;
  }
  overflow-wrap: break-word;
`;

const EmailBody = styled.div`
  /* Layout */
  text-align: left;
  padding: 0;
  width: fit-content;
  min-height: 100px;

  /* Appearance */
  background: transparent;
  outline: none;
  & [data-smartmail="gmail_signature"] {
    color: #888888;
  }
  & [data-smartmail="gmail_signature"] a,
  & [data-smartmail="gmail_signature"] a:visited {
    color: #15c;
  }
  //INFO: overwrite Gmail
  .dark-mode &.Am {
    background: inherit;
  }
  font-weight: 300 !important;
  font-family: system-ui !important;
  &.blur {
    filter: blur(0.3rem);
  }

  //INFO: disable images
  & img {
    display: none;
  }
  line-height: 125% !important;
  font-size: 12px !important;
  position: relative !important;
  br {
    display: none;
  }
  .gmail_signature,
  .sl-signature,
  .outreach-signature,
  div#Signature,
  div[data-smartmail="gmail_signature"],
  .gmail_signature_prefix {
    display: none !important;
  }
  overflow-wrap: break-word;
`;

const Warning = styled(Flex)`
  position: absolute;
  top: 150px;
  left: 102%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  width: max-content;
  padding: 4px 8px;
  min-width: 32px;
  min-height: 32px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
`;

export const WatchContainer = ({
  firstName,
  lastName,
  subjectText,
  emailBody,
  editMailboxSubject,
  editMailBody,
}: {
  $darkMode: boolean;
  firstName: string;
  lastName: string;
  subjectText: string;
  emailBody: string;
  editMailboxSubject: (newSubject: string) => void;
  editMailBody: (editMailBody: string) => void;
}) => {
  const [showWarning, setShowWarning] = useState(false);

  const triggerWarning = () => {
    setShowWarning(true);
    setTimeout(() => {
      setShowWarning(false);
    }, 2500);
  };

  const { platformDisplayName, insertType } = useAppSelector(
    (state) =>
      state.config.settings || {
        platformDisplayName: "this platform",
        insertType: InsertType.Copy,
      }
  );
  return (
    <div className={`${CSS_PREFIX}-watch`}>
      <WatchDevice>
        <DragHandle type="watch" />
        <Bezel />
        <Notification>
          <MessageContainerScroll>
            <NotificationHeader>
              <MailIcon src={imgSources.mailIcon} />
              <TimeAgo>now</TimeAgo>
            </NotificationHeader>
            <NotificationBackground>
              <Name>
                {firstName}
                &nbsp;
                {lastName}
              </Name>
              {insertType === InsertType.Insert ? (
                <EmailSubjectEditable
                  html={subjectText}
                  onChange={(e) => {
                    editMailboxSubject(e.target.value);
                  }}
                  defaultValue={subjectText}
                />
              ) : (
                <EmailSubject>{subjectText}</EmailSubject>
              )}
              {useShouldDisplayPaywall("mobilePreview") ? (
                <WatchPaywall />
              ) : (
                <>
                  {insertType === InsertType.Insert ? (
                    <EmailBodyEditable
                      html={emailBody}
                      onChange={(e) => {
                        editMailBody(e.currentTarget.innerHTML);
                      }}
                    />
                  ) : (
                    <EmailBody
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(emailBody),
                      }}
                      onClick={() => {
                        triggerWarning();
                      }}
                    />
                  )}
                </>
              )}
            </NotificationBackground>
          </MessageContainerScroll>
          {showWarning && (
            <Warning>Editing Disabled for {platformDisplayName}</Warning>
          )}
        </Notification>
      </WatchDevice>
    </div>
  );
};
