import { EditEmailResponse, EditEmailSuccessResponse } from "~src/api/typings";

import { NetworkStatus, Recommendations } from ".";

export type FixItAllState = Record<
  lvIdentifier,
  {
    abort: () => void;
    recommendations: Recommendations;
    fixItAllSuggestion: FixItAllSuggestion;
    fetchFixItAllStatus: NetworkStatus;
    fetchFixItAllError: string;
    promptName?: string;
    stage: string;
  }
>;

export interface FixItAllSuggestionPayload {
  controller: AbortController;
  lvIdentifier: lvIdentifier;
  chromeId: chromeId;
  body: string;
  promptName?: string; // email transformers
  recommendations: Recommendations;
  replyFlag?: boolean;
  stage: string;
  subject: string;
}

export interface FixItAllSuggestionData {
  data: EditEmailResponse;
  lvIdentifier: lvIdentifier;
}

export type FixItAllSuggestion = Required<
  Pick<EditEmailSuccessResponse, "subject" | "email">
> &
  Pick<EditEmailSuccessResponse, "bypassed_ai">;
