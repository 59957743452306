import { useEffect } from "react";
import { Form } from "antd";

import { TextArea } from "@lavender-ai/lav-components";

import { RankedFeedType } from "~src/api/typings";
import { useValuePropGeneration } from "~src/customHooks";
import { selectUserAccountProfileCompany, useAppSelector } from "~src/redux";
import { VALUE_PROP_FORM } from "~src/strings";

interface Props {
  id: string;
  section: RankedFeedType;
  title: string;
}

export const Item = ({ id, section, title }: Props) => {
  const { getValuePropGenerationValue, isUserValuePropGenerationOn } =
    useValuePropGeneration();
  const companyName = useAppSelector(selectUserAccountProfileCompany);
  const generatedValuePropValue = getValuePropGenerationValue(section, id);
  const placeholder: string = companyName || "your company";
  const form = Form.useFormInstance();
  const name = ["categories", section, title];
  const initialFormValue = form.getFieldValue(name);

  useEffect(() => {
    if (initialFormValue !== generatedValuePropValue) {
      form.setFields([
        {
          name,
          value: generatedValuePropValue,
          touched: false,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedValuePropValue, isUserValuePropGenerationOn]);

  return (
    <Form.Item rules={[{ required: true }]} name={name} noStyle>
      <TextArea
        autoSize={{ minRows: 3, maxRows: 6 }}
        placeholder={VALUE_PROP_FORM.INPUTS.WHY_IS_IT_IMPORTANT(placeholder)}
      />
    </Form.Item>
  );
};
