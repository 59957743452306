import { useCallback } from "react";
import { styled } from "styled-components";
import { Flex } from "antd";

import {
  Switch,
  SwitchProps,
  Text,
  Tooltip,
  TooltipProps,
} from "@lavender-ai/lav-components";

import { useTooltip } from "~src/customHooks";

interface Props
  extends Pick<
      SwitchProps,
      "checked" | "defaultChecked" | "disabled" | "onChange"
    >,
    Pick<SwitchProps, "defaultChecked" | "disabled"> {
  highlightLabelOnHover?: boolean;
  label: string;
  tooltipProps?: Pick<
    TooltipProps,
    "placement" | "open" | "overlayInnerStyle" | "title"
  >;
}

const StyledToggleWrapper = styled(Flex)<{
  $highlightLabelOnHover?: boolean;
}>`
  ${({ $highlightLabelOnHover }) =>
    $highlightLabelOnHover &&
    `
      .ant-tooltip-inner {
        transform: translate(0px, 2px);
      }
      .ant-tooltip-arrow {
        transform: translateX(-50%) translateY(calc(100% + 2px)) rotate(180deg);
      }
    `}
`;

const StyledSwitchContainer = styled(Flex)<{
  $highlightLabelOnHover?: boolean;
}>`
  ${({ $highlightLabelOnHover }) =>
    $highlightLabelOnHover &&
    `
      background: transparent;
      border-radius: var(--radius-md);
      padding: var(--size-4) var(--size-6);
      cursor: pointer;
      &:has(:hover) {
        background: var(--color-neutral-purple-200, #DEE1E8);
      }
    `}
`;

export const Toggle = ({
  checked,
  defaultChecked,
  disabled,
  highlightLabelOnHover,
  label,
  onChange,
  tooltipProps,
}: Props) => {
  const { hoverRef, open: tooltipOpen } = useTooltip();
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onChange && onChange(!checked, e);
    },
    [checked, onChange]
  );

  return (
    <StyledToggleWrapper $highlightLabelOnHover={highlightLabelOnHover}>
      <Tooltip
        getPopupContainer={(trigger: HTMLElement) => {
          return trigger;
        }}
        open={highlightLabelOnHover ? tooltipOpen : undefined}
        overlayInnerStyle={tooltipProps?.overlayInnerStyle}
        placement={tooltipProps?.placement}
        title={tooltipProps?.title}
      >
        <StyledSwitchContainer
          align="center"
          gap="small"
          onClick={highlightLabelOnHover ? onClick : undefined}
          ref={hoverRef}
          $highlightLabelOnHover={highlightLabelOnHover}
        >
          <Switch
            checked={checked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            size="small"
          />
          <Text size="body1">{label}</Text>
        </StyledSwitchContainer>
      </Tooltip>
    </StyledToggleWrapper>
  );
};
