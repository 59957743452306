import { store } from "~src/redux";

// INFO : Get the To value out of the Element
export const getElementListValue = (
  elementList: HTMLElement[],
  attrField: string
): Array<string> => {
  // INFO : Convert the NodeList to an Array
  const elementArray: HTMLElement[] = Array.from(elementList);
  return elementArray.map((element) => {
    if (attrField) {
      return getAttributeValue(element, attrField);
    }
    return element.innerHTML || "";
  });
};

export const getElementValue = (
  element: HTMLElement,
  attrField: string
): string => {
  if (attrField) {
    return getAttributeValue(element, attrField);
  }
  return element?.innerHTML || (element as HTMLInputElement)?.value || "";
};

export const getAttributeValue = (
  element: HTMLElement,
  attrField: string
): string => {
  // INFO : If the attribute field is a comma separated list then we need to check each attribute
  if (attrField.includes(",")) {
    const attrFields = attrField.split(",").map((value) => value.trim());

    for (const attr of attrFields) {
      let value: string | null | undefined;
      if (attr === "span") {
        // hubspot templates have nested spans
        value = element.firstChild?.textContent;
        if (value !== null && value !== undefined) {
          return value || "";
        }
      }
      value = element.getAttribute(attr) || element.dataset[attr];
      if (!value && attr === "innerHTML") {
        value = element.innerHTML;
      }
      if (!value && attr === "innerText") {
        value = element.innerText;
      }
      // Needed for hubspot recipient email in new message
      if (!value && attr === "hovertext") {
        value = simulateHover(element);
      }
      if (value !== null && value !== undefined) {
        return value;
      }
    }
  }
  if (attrField === "hovertext") {
    const value = simulateHover(element);
    return value ?? "";
  }
  return (
    element.getAttribute(attrField) ||
    element.dataset[attrField] ||
    element[attrField] ||
    ""
  );
};

const simulateHover = (element: HTMLElement | null): string | undefined => {
  const state = store.getState();
  const { platform } = state.config.settings ?? {};
  const { addressToNestedElem, addressToHoverSelector } =
    state.config.settings?.selectors ?? {};
  if (platform === "hubspot") {
    return hoverOnHubspot(element, addressToHoverSelector ?? "");
  }
  if (platform === "outlook") {
    //this doesn't return. Picks up email on next pass from data-attr
    hoverOnOutlook(
      element,
      addressToNestedElem ?? "",
      addressToHoverSelector ?? ""
    );
  }

  return undefined;
};

const hoverOnHubspot = (
  element: HTMLElement | null,
  addressToHoverSelector: string
): string | undefined => {
  if (element !== null) {
    const getTooltipEmail = (): string | undefined => {
      const tooltip = document.querySelector(addressToHoverSelector ?? "");
      return tooltip?.textContent ?? undefined;
    };
    const mouseEnterEvent = new MouseEvent("mousemove", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    element.dispatchEvent(mouseEnterEvent);

    const email = getTooltipEmail();
    element.setAttribute("data-lavender-email", email || "");
    return email;
  }
};

const hoverOnOutlook = async (
  element: HTMLElement | null,
  addressToNestedElem: string,
  addressToHoverSelector: string
) => {
  if (element !== null) {
    let event = new MouseEvent("dblclick", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    const namedTag = element.querySelector(addressToNestedElem);
    namedTag?.dispatchEvent(event);

    await WaitForElement(addressToHoverSelector);
    // need to build a time delay and convert to async to retrieve name once
    const emailElem = document?.querySelector(addressToHoverSelector ?? "");
    let emailContent = emailElem?.textContent;
    emailContent = emailContent?.replace("Email", "").trim();
    element.setAttribute("data-lavender-email", emailContent || "");
    const closeEl = document?.querySelector(
      '[data-testid="NavigationCloseButton"]'
    );
    let event2 = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    closeEl?.dispatchEvent(event2);
    //close modal
    return emailContent ?? undefined;
  }
};

export const WaitForElement = async (selector: string) => {
  let i = 0;
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const element = document.querySelector(selector);
      if (element) {
        clearInterval(interval);
        resolve(element);
      }
      if (i > 30) {
        clearInterval(interval);
        resolve(null);
      }
      i++;
    }, 100);
  });
};
