import styled from "styled-components";

import { PropsForStyling } from "../../../../component/typings";

export const StyledPanelListItem = styled.div<PropsForStyling<Props>>`
  text-align: left;
  ${({ $isCartView }) => ($isCartView ? "padding: 0" : "padding: 0 12px")};

  ${({ $hover }) =>
    $hover &&
    `
    &:hover {
      border-radius: var(--radius-md, 8px);
      background: var(--lv-panel-background-color);
      cursor: pointer;
    }
  `}

  .lv-panel-list-item-inner-container {
    display: flex;
    gap: 12px;
    ${({ $isCartView }) => $isCartView && "align-items: center"};
    ${({ $dense, $isCartView }) =>
      $dense || $isCartView ? "padding: 16px 0" : "padding: 0px 0"};
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  hover?: boolean;
  isCartView?: boolean;
  dense?: boolean;
}

export const PanelListItem = ({
  children,
  hover,
  isCartView,
  dense,
  ...HTMLAttributes
}: Props) => {
  return (
    <StyledPanelListItem
      className="lav-panel-list-item"
      $dense={dense}
      $hover={hover}
      $isCartView={isCartView}
      {...HTMLAttributes}
    >
      {children}
    </StyledPanelListItem>
  );
};
