import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getFeatureFlag } from "../api";
import { FeaturesState } from "./typings";
import { RootState, store } from ".";

export const INIT_FEATURES_STATE: FeaturesState = {};

export const fetchFeatureFlags = createAsyncThunk(
  "features/getFeatureFlag",
  async (chromeId: chromeId) => {
    const storedFeatureFlags = store.getState().features;

    return Object.keys(storedFeatureFlags).length
      ? storedFeatureFlags
      : await getFeatureFlag(chromeId);
  }
);

export const featuresSlice = createSlice({
  initialState: INIT_FEATURES_STATE,
  name: "features",
  reducers: {
    setFeatureOff: (state, action: PayloadAction<string>) => {
      state[action.payload] = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureFlags.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
    });
  },
});

export const featuresReducer = featuresSlice.reducer;
export const featuresAction = featuresSlice.actions;

export const selectFeatures = (state: RootState): FeaturesState =>
  state.features;
