/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const imgSources: {
  envelopeIcon: string;
  mailIcon: string;
  mobileBackground: string;
  dragHandle: string;
  mobileIconHeader: string;
  mobileIconHeaderDarkMode: string;
  mobileSubjectBackground: string;
  mobileSubjectBackgroundDarkMode: string;
  mobileEmailFooter: string;
  mobileEmailFooterDarkMode: string;
  watch: string;
} = {
  envelopeIcon: require("./asset_envelope.svg"),
  mailIcon: require("./mail.png"),
  mobileBackground: require("./mobileBackground.svg"),
  dragHandle: require("./drag-handle.svg"),
  mobileIconHeader: require("./mobileIconHeader.jpg"),
  mobileIconHeaderDarkMode: require("./mobileIconHeaderDarkMode.jpg"),
  mobileSubjectBackground: require("./mobileSubjectBackground.jpg"),
  mobileSubjectBackgroundDarkMode: require("./mobileSubjectBackgroundDarkMode.jpg"),
  mobileEmailFooter: require("./mobileEmailFooter.jpg"),
  mobileEmailFooterDarkMode: require("./mobileEmailFooterDarkMode.jpg"),
  watch: require("./watch.png"),
};
