import { Flex } from "antd";
import { styled } from "styled-components";

import { IconButton, Text } from "@lavender-ai/lav-components";

import { PanelAlert } from "~src/component/Atoms/PanelAlert/PanelAlert";

import { usePersonalization } from "~src/customHooks";

import { SecondaryText } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";
import { CART_HEADER, CART_ALERT } from "~src/strings";
import { icebreakerCount } from "~src/utils";

import { StyledCartHeader } from "../../../Shared/Styled";
import { useCarouselContext } from "~src/component/Organisms/Personalization/CarouselContextProvider";

const StyleCartHeaderWithAlert = styled(StyledCartHeader)`
  .ant-alert.ant-alert-error.ant-alert-banner {
    height: 40px;
    margin-bottom: 16px;
  }
`;

export const Header = () => {
  const { reset } = useCarouselContext();
  const {
    currentPersonalization: { cart },
  } = usePersonalization();

  const cartCount = icebreakerCount(cart);

  return (
    <StyleCartHeaderWithAlert align="flex-start" gap={4} vertical>
      <Flex
        align="center"
        className="lv-cart-header"
        justify="flex-start"
        gap={12}
      >
        <IconButton
          icon={LavenderIcon.IconChevronLeft}
          variant="secondary"
          onClick={reset}
        />
        <>
          <Text color="dark" size="subtext3">
            {CART_HEADER.ADD_CONTEXT}
          </Text>
          <SecondaryText
            className="lv-cart-header-secondary-text"
            $light
            size="body2"
          >
            {CART_HEADER.SLASH_SELECTED(cartCount)}
          </SecondaryText>
        </>
      </Flex>
      <PanelAlert
        message={CART_ALERT.SELECT_AT_LEAST_ONE}
        type="error"
        textColor="black"
        textSize="body2"
      />
    </StyleCartHeaderWithAlert>
  );
};
