import { Button, Text } from "@lavender-ai/lav-components";
import { Flex } from "antd";
import { styled } from "styled-components";

import { LavenderIcon } from "~src/component/typings";
import { NavigationWrapper } from "../Views/NavigationWrapper";
import { ImageFallbackAvatar } from "~src/component/Molecules";
import { Tech } from "~src/redux/typings";
import { TagList } from "~src/component/Atoms";
import { CLEARBIT_LOGO_URL } from "~src/constants";

interface Props {
  tech: Tech;
}

export const TechStackModal = ({ tech }: Props) => {
  return (
    <NavigationWrapper>
      <br />
      <TechStackPanel gap={"middle"} vertical>
        <ImageFallbackAvatar
          src={`${CLEARBIT_LOGO_URL}${tech.title}.com`}
          alt="event-avatar"
          size="small"
          fallback={`${CLEARBIT_LOGO_URL}${tech.title?.split(" ")[0]}.com`}
          fallbackLetter={tech.title?.charAt(0)}
        />

        <Text size={"subtext3"}>{tech.title}</Text>
        <Text size={"body1"}>{tech.description}</Text>
        <TagList tags={[tech.type]} colored />
        <StyledButton
          size="medium"
          suffix={LavenderIcon.IconArrowUpRight}
          variant={"default"}
          fullWidth={true}
          href={tech.url}
          target="_blank"
        >
          {formatUrl(tech.url)}
        </StyledButton>
      </TechStackPanel>
    </NavigationWrapper>
  );
};

const formatUrl = (url: string) => {
  if (!url) return "";
  const newUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  return newUrl.endsWith("/") ? newUrl.slice(0, -1) : newUrl;
};

const StyledButton = styled(Button)`
  margin: 10px 0px;
  width: -webkit-fill-available;

  span {
    overflow-x: hidden;

    span {
      display: block !important;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const TechStackPanel = styled(Flex)`
  background: white;
  border-radius: var(--radius-lrg, 16px);
  text-align: left;
  margin: 0px 16px;
  padding: 20px 16px;
`;
