import { CRMData, NetworkStatus, RecipientCRMMap } from "~src/redux/typings";
import { createFeedAsyncThunk, parseCRMResponse } from "../helpers";
import { REFRESH_INTERVAL } from "~src/constants";
import { newPendingCRM, newRejectedCRM } from "~src/utils";
import { updateCRMs } from "../actions";
import { getCRMData } from "~src/api";

export const loadCRM = createFeedAsyncThunk<CRMData, { toAddresses: string[] }>(
  "feed/crm",
  async ({ toAddresses }, { dispatch, getState }): Promise<CRMData> => {
    const {
      config: { chromeId },
      feed: { lastUpdated, crms: crmData },
      userAccount: {
        settings: { user },
      },
    } = getState();

    if (!chromeId || user === undefined) {
      return { toAddresses, crmData: {} };
    }

    if (!toAddresses.length) {
      return { toAddresses, crmData };
    }

    const shouldRefetch =
      !lastUpdated || Date.now() - lastUpdated > REFRESH_INTERVAL.FEED;

    const crmDataFetchEmails = toAddresses.filter(
      (email) => !crmData[email] || shouldRefetch
    );

    const pendingCRMData = crmDataFetchEmails.reduce<RecipientCRMMap>(
      (emails, email) => {
        emails[email] = newPendingCRM();
        return emails;
      },
      {}
    );

    dispatch(
      updateCRMs({
        toAddresses,
        crmData: pendingCRMData,
      })
    );

    const resolvedCRMs = await Promise.all(
      crmDataFetchEmails.map(async (email) => {
        try {
          const crmDataResponse = await getCRMData(email, chromeId);
          return {
            status: NetworkStatus.success,
            email,
            data: parseCRMResponse(crmDataResponse),
          };
        } catch {
          return {
            status: NetworkStatus.failed,
            email,
            data: newRejectedCRM(),
          };
        }
      })
    );

    const parsedFullfilledCRMs = resolvedCRMs.reduce<RecipientCRMMap>(
      (acc, { email, data }) => {
        acc[email] = data;
        return acc;
      },
      {}
    );

    dispatch(
      updateCRMs({
        toAddresses,
        crmData: parsedFullfilledCRMs,
      })
    );

    return { toAddresses, crmData: parsedFullfilledCRMs };
  }
);
