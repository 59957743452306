import { useCallback, useMemo } from "react";
import { Root } from "react-dom/client";
import { store } from "~src/redux/store";
import { ComposeContainerMetadata } from "../../redux/typings";
import { CreatePanel } from "../../layout";
import {
  isAuthenticated,
  isRootComposeContainer,
  matchComposeContainerMetadata,
  startAuthenticatedSession,
} from "../../utils";
import { addClassesToPanel } from "./addClassesToPanel";
import { useIsExtensionBlockedCheck } from "./useIsExtensionBlocked";
import { useMainLoopState } from "..";
import {
  createAndInitLVIdentifier,
  createLVIdentifier,
  initializeLVIdentifier,
} from "./createLVIdentifier";
import { getBodyContainer } from "~src/layout/MainPanel/CreatePanel";
import { extractExistingPixel } from "~src/utils/setupTrackingPixelListener";

export const useCheckForNewPanelRenderCallback = ({
  chromeId,
}: Pick<ReturnType<typeof useMainLoopState>, "chromeId">) => {
  const state = store.getState();
  useIsExtensionBlockedCheck({ chromeId });
  const { settings } = state.config;
  const { selectors, platform } = settings ?? {};

  const composeContainerSelectors = useMemo(
    () =>
      selectors?.composeContainers
        ?.map(({ container }) => container.containerSelector)
        .join(", ") || "",
    [selectors?.composeContainers]
  );

  const taskChangeButtons = useMemo(() => new Map<Element, true>(), []);
  const reactRoots = useMemo(() => new Map<HTMLElement, Root | true | undefined>(), []);

  const checkForNewPanels = useCallback(() => {
    if (!selectors?.composeContainers || !platform) {
      return;
    }

    // This polls the DOM for new email body to append the extension
    const composeContainers = document.querySelectorAll<HTMLElement | HTMLIFrameElement>(
      composeContainerSelectors
    );
    let composeContainerMetadata: ComposeContainerMetadata | undefined;

    // Some platforms have multiple menus within same react root.  Need to bypass dedupe logic to allow multiple menus
    const multipleAnchorPlatforms = ["hubspot", "outlook"];

    for (const emailContainer of composeContainers) {
      if (!multipleAnchorPlatforms.includes(platform) && reactRoots.has(emailContainer)) {
        continue;
      }
      console.log(emailContainer);
      // set to true early to prevent race conditions
      reactRoots.set(emailContainer, true);
      composeContainerMetadata = matchComposeContainerMetadata(emailContainer, selectors);

      if (!composeContainerMetadata) {
        continue;
      }

      /* Only add to class if menu anchor is present */
      let menuAnchor: HTMLElement | null = null;
      if (emailContainer.matches(composeContainerMetadata.menu.panelParents)) {
        menuAnchor = emailContainer;
      } else {
        menuAnchor = emailContainer.querySelector(composeContainerMetadata.menu.panelParents);
      }
      if (menuAnchor === null) {
        continue;
      }
      console.log(menuAnchor);
      if (isRootComposeContainer(emailContainer, composeContainerMetadata)) {
        continue;
      }

      let emailBody;
      /* find menu anchor and email body inside of iframe container */
      if (composeContainerMetadata.container.isIframe) {
        const content = emailContainer.querySelector(
          composeContainerMetadata.container.iframeContainerSelector ?? ""
        ) as HTMLIFrameElement;
        emailBody = content?.contentDocument?.body.querySelector(
          selectors.iframeChildSelector ?? ""
        );
      } else {
        emailBody = getBodyContainer(emailContainer);
      }
      if (!emailBody) {
        //page not fully loaded. Will trigger it to try again
        continue;
      }

      addClassesToPanel(emailContainer, composeContainerMetadata);

      // Start authenticated related features like highlighting, polling for email data, and other UI related features,
      let lvIdentifier: lvIdentifier = createLVIdentifier();

      // we can check if there is a pre-existing tracking pixel
      // this ensures that drafts that are closed and re-visted later will keep their
      // original pixel id
      const existingPixel: lvIdentifier | null = extractExistingPixel(emailContainer);
      if (existingPixel !== null) {
        lvIdentifier = existingPixel;
      }

      initializeLVIdentifier(lvIdentifier);

      if (isAuthenticated()) {
        // Get all user data and set up the panel
        void startAuthenticatedSession(
          lvIdentifier,
          emailContainer,
          selectors,
          composeContainerMetadata.container.isIframe,
          composeContainerMetadata.container.iframeContainerSelector
        );
      }
      reactRoots.set(
        emailContainer,
        CreatePanel(emailContainer, composeContainerMetadata, lvIdentifier)
      );

      // Add listener to CRM Task Change to reset panel
      if ((platform === "salesloft" || platform === "outreach") && selectors?.taskChangeSelectors) {
        document.querySelectorAll(selectors.taskChangeSelectors).forEach((button) => {
          if (taskChangeButtons.has(button)) {
            return;
          }
          taskChangeButtons.set(button, true);

          button.addEventListener("click", () => {
            emailContainer.querySelector(".lv-panel-root")?.remove();
            const lvIdentifier = createAndInitLVIdentifier();

            if (isAuthenticated()) {
              // INFO: Get all user data and set up the panel
              void startAuthenticatedSession(
                lvIdentifier,
                emailContainer,
                selectors,
                composeContainerMetadata!.container.isIframe,
                composeContainerMetadata!.container.iframeContainerSelector
              );
            }
            reactRoots.set(
              emailContainer,
              CreatePanel(emailContainer, composeContainerMetadata!, lvIdentifier)
            );
          });
        });
      }
    }
  }, [composeContainerSelectors, platform, reactRoots, selectors]);

  const unmountRemovedPanels = useCallback(() => {
    // Call root.unmount on any panels that are no longer in the DOM
    [...reactRoots.entries()].forEach(([emailContainer, reactRoot]) => {
      if (!emailContainer.isConnected && reactRoot !== undefined && reactRoot !== true) {
        reactRoot.unmount();
        reactRoots.delete(emailContainer);
        // @ts-expect-error checking hidden field for cleanup
      } else if (!reactRoot?._internalRoot) {
        reactRoots.delete(emailContainer);
      }
    });
  }, [composeContainerSelectors, platform, reactRoots, selectors]);

  return [checkForNewPanels, unmountRemovedPanels];
};
