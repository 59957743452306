import { useEffect, useState } from "react";
import { Flex, Card } from "antd";
import { differenceInSeconds } from "date-fns";
import { styled } from "styled-components";

import { Text, Tooltip, Dropdown, Button } from "@lavender-ai/lav-components";

import { IconFrame } from "~src/component/Atoms";
import { MAILCLIENT, TONE_CONVERT } from "~src/constants";
import { useLvIdentifier } from "~src/customHooks";
import {
  OpenLavenderAnywhereIframe,
  OpenLavenderAnywherePopup,
} from "~src/layout/MainPanel/EmailCoachPanel/components/EmailCoach/components/EditorMode/Activate";
import {
  emailAction,
  store,
  useAppSelector,
  selectChromeId,
  selectFeatures,
  highlightsAction,
} from "~src/redux";
import { InsertType, NetworkStatus, Recommendation } from "~src/redux/typings";
import {
  SegmentEventLocations,
  SegmentEventNames,
  segmentEvent,
} from "~src/utils";

import EnvelopeSuccess from "~src/assets/images/envelope-success.svg";
import EnvelopeDanger from "~src/assets/images/envelope-danger.svg";
import EnvelopeWarning from "~src/assets/images/envelope-warning.svg";

interface Props {
  ceq: number;
  writingTime: number;
  readingTime: number;
}

export const Header = ({ ceq, writingTime, readingTime }: Props) => {
  const lvIdentifier = useLvIdentifier();
  const chromeId = useAppSelector(selectChromeId);
  const emailData = useAppSelector((state) => state.emailData[lvIdentifier]);
  const analysisData = useAppSelector(
    (state) => state.emailAnalysis[lvIdentifier]
  );
  const {
    hasPersonalizedScore,
    stage: analysisStage,
    status: analysisStatus,
  } = analysisData;
  const { stageOverwrite } = emailData ?? {};

  // INFO: write time
  const end = Date.now();
  const [diffInSeconds, setDiffInSeconds] = useState<number>(
    differenceInSeconds(end, writingTime)
  );
  const [isOver5Minutes, setIsOver5Minutes] = useState<boolean>(false);

  useEffect(() => {
    const updateValue = () => {
      setDiffInSeconds((prevValue) => {
        if (prevValue >= 299) {
          setIsOver5Minutes(true);
        }
        return prevValue + 1;
      });
    };
    const intervalId = setInterval(updateValue, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const writeTimeDuration = {
    hours: Math.floor(diffInSeconds / 3600),
    minutes: Math.floor((diffInSeconds % 3600) / 60),
    seconds: diffInSeconds % 60,
  };
  // INFO: read time
  const readTimeDuration = {
    hours: Math.floor(readingTime / 3600),
    minutes: Math.floor((readingTime % 3600) / 60),
    seconds: readingTime % 60,
  };

  // Custom function to format duration in hh:mm:ss format
  const formatDurationWithZeroPadding = (duration: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    const hours = duration.hours > 0 ? `${duration.hours}:` : "";
    const minutes = String(duration.minutes).padStart(2, "0");
    const seconds = String(duration.seconds).padStart(2, "0");
    return `${hours}${minutes}:${seconds}`;
  };

  let score: string = "success";
  if (ceq < 90) score = "warning";
  if (ceq < 70) score = "danger";

  const updateStage = ({ key }) => {
    if (key === "reply") {
      segmentEvent(
        SegmentEventNames.ReplySelected,
        SegmentEventLocations.EmailCoach
      );
    } else if (key === "follow_up") {
      segmentEvent(
        SegmentEventNames.FollowUpSelected,
        SegmentEventLocations.EmailCoach
      );
    } else if (key === "cold") {
      segmentEvent(
        SegmentEventNames.ColdEmailSelected,
        SegmentEventLocations.EmailCoach
      );
    }
    store.dispatch(
      emailAction.updateStage({
        id: lvIdentifier,
        stageOverwrite: key as string,
      })
    );
  };

  // If stage was set manually, but the new analysis failed, need to revert the stage to match the
  // analysis
  useEffect(() => {
    if (
      !!stageOverwrite &&
      analysisStage !== stageOverwrite &&
      analysisStatus === NetworkStatus.failed
    ) {
      store.dispatch(
        emailAction.updateStage({
          id: lvIdentifier,
          stageOverwrite: analysisStage,
        })
      );
    }
  }, [analysisStage, analysisStatus, stageOverwrite, lvIdentifier]);

  const stageItems = [
    {
      children: "Cold",
      key: "cold",
    },
    {
      children: "Follow Up",
      key: "follow_up",
    },
    {
      children: "Reply",
      key: "reply",
    },
  ];

  const updateScoringModel = ({ key }) => {
    if (key === "personalized") {
      if (!hasPersonalizedScore) {
        return;
      }
      segmentEvent(
        SegmentEventNames.PersonalizedSelected,
        SegmentEventLocations.EmailCoach
      );
    } else if (key === "best practices") {
      segmentEvent(
        SegmentEventNames.BestPracticesSelected,
        SegmentEventLocations.EmailCoach
      );
    }
    store.dispatch(
      emailAction.updateScoringModel({
        id: lvIdentifier,
        scoringModelOverwrite: key,
      })
    );
  };

  const scoringModelItems = [
    {
      children: (
        <Tooltip
          hideArrow
          title={
            hasPersonalizedScore
              ? "Based off emails your team has sent"
              : "Only for Teams accounts with ~10k emails"
          }
          placement={"right"}
        >
          Personalized
        </Tooltip>
      ),
      key: "personalized",
    },
    {
      children: (
        <Tooltip
          hideArrow
          title="Based off of our entire dataset"
          placement={"right"}
        >
          Best Practices
        </Tooltip>
      ),
      key: "best practices",
    },
    // {
    //   children: "Persona X",
    //   key: "reply",
    //}
  ];

  const { platform, insertType } = useAppSelector((state) => {
    if (state.config.settings) {
      return state.config.settings;
    }
    return { platform: "", insertType: "" };
  });
  const stage = analysisStage;
  const currentObject =
    stageItems.find((s) => s.key === stage) || stageItems[0];

  const scoringModel =
    emailData?.scoringModelOverwrite || analysisData.scoringModel;
  const currentScoringObject =
    scoringModelItems.find((s) => s.key === scoringModel) ||
    scoringModelItems[0];

  const handleWritingTimeMouseEnterEvent = () => {
    segmentEvent(
      SegmentEventNames.WritingTimeHovered,
      SegmentEventLocations.EmailCoach
    );
  };

  const handleReadingTimeMouseEnterEvent = () => {
    segmentEvent(
      SegmentEventNames.ReadingTimeHovered,
      SegmentEventLocations.EmailCoach
    );
  };

  const dispatch = store.dispatch;
  const selectTones = (tone: string) => {
    tone = TONE_CONVERT[tone];
    // get current highlight from redux
    // if recommendationId === tone, then remove highlight
    const currentHighlights = store.getState().highlights[lvIdentifier];
    console.log(currentHighlights);
    dispatch(
      highlightsAction.setHighlights({
        lvIdentifier,
        highlights: [],
        recommendationId: "",
      })
    );
    if (currentHighlights?.recommendationId === tone) {
      return;
    }
    // else add highlight
    const highlights: Array<string> = [];
    for (const sentence of analysisData.highlights.ToneSentences) {
      if (sentence.tones) {
        for (const t of sentence.tones) {
          if (t.tone_id === tone) {
            highlights.push(sentence.text);
          }
          if (
            tone === "friendly" &&
            (t.tone_id === "joy" || t.tone_id === "confident")
          ) {
            highlights.push(sentence.text);
          }
        }
      }
    }
    if (insertType === "copy") {
      const highlightData: Recommendation = {
        current_value: 0,
        effectonscore: 0,
        gpt_rec: "",
        ideal: 0,
        linkedbtn: "",
        lower_bound: 0,
        prompt_name: "",
        recommendation: "",
        rectype: "",
        upper_bound: 0,
        id: tone,
        field: tone,
        text: highlights.join("|"),
      };
      if (platform === MAILCLIENT.OUTLOOK_NATIVE)
        OpenLavenderAnywherePopup(
          chromeId,
          emailData,
          analysisData,
          highlightData
        );
      else
        OpenLavenderAnywhereIframe(
          chromeId,
          emailData,
          analysisData,
          highlightData
        );
      return;
    }
    dispatch(
      highlightsAction.setHighlights({
        lvIdentifier,
        highlightClass: "lv-highlight-yellow",
        highlights,
        recommendationId: tone,
      })
    );
  };

  const { scoringOverwrite } = useAppSelector(selectFeatures);
  return (
    <CoachHeader vertical>
      <Card className={`lv-card coach-header-${score}`}>
        <CoachTitle align="center" justify="space-between">
          <Text color="black" size="subtext2">
            Lavender Email Coach
          </Text>
        </CoachTitle>
        <CoachScore align="center" justify="space-between">
          <Flex vertical align="flex-start">
            <ScoreText className="lv-score-text">{Math.max(ceq, 0)}</ScoreText>
            <Text style={{ color: "var(--color-neutral-purple-900)" }}>
              {score === "success" && (
                <>
                  Wow! Your score is top tier,
                  <strong> you’re ready to send!</strong>
                </>
              )}
              {score === "warning" &&
                "Almost ready to send! Let’s make some improvements"}
              {score === "danger" &&
                "Not the best, but let’s work to improve your score!"}
            </Text>
          </Flex>
          {score === "success" && <EnvelopeImg src={EnvelopeSuccess} />}
          {score === "warning" && <EnvelopeImg src={EnvelopeWarning} />}
          {score === "danger" && <EnvelopeImg src={EnvelopeDanger} />}
        </CoachScore>
        <CoachTime>
          <Tooltip hideArrow placement="top" title="Writing time">
            <span onMouseEnter={handleWritingTimeMouseEnterEvent}>
              <IconFrame
                isTag
                color={isOver5Minutes ? "tart" : "outline"}
                variant="IconWriting"
                text={formatDurationWithZeroPadding(writeTimeDuration)}
              />
            </span>
          </Tooltip>

          <Tooltip hideArrow placement="top" title="Reading time">
            <span onMouseEnter={handleReadingTimeMouseEnterEvent}>
              <IconFrame
                isTag
                color="outline"
                variant="IconEye"
                text={formatDurationWithZeroPadding(readTimeDuration)}
              />
            </span>
          </Tooltip>
          <ScoreAs>
            <Dropdown
              disabled={
                !!stageOverwrite &&
                analysisStage !== stageOverwrite &&
                analysisStatus === NetworkStatus.loading
              }
              placement="bottomRight"
              menuProps={{
                selectable: true,
                selectedKeys: [stage],
                onSelect: updateStage,
                items: stageItems,
                style: {
                  width: 100,
                },
              }}
              size="small"
              variant="default"
            >
              {currentObject?.children}
            </Dropdown>
            {scoringOverwrite && (
              <Dropdown
                placement="bottomRight"
                menuProps={{
                  selectable: true,
                  selectedKeys: [scoringModel],
                  onSelect: updateScoringModel,
                  items: scoringModelItems,
                  style: {
                    width: 100,
                  },
                }}
                size="small"
                variant="default"
              >
                {currentScoringObject?.children}
              </Dropdown>
            )}
          </ScoreAs>
        </CoachTime>
        {InsertType[insertType] === InsertType.Copy && (
          <EditorModeButton
            variant={"primary"}
            size={"medium"}
            state={"initial"}
            suffix={"IconExternalLink"}
            onClick={() => {
              if (platform === MAILCLIENT.OUTLOOK_NATIVE)
                OpenLavenderAnywherePopup(chromeId, emailData, analysisData);
              else
                OpenLavenderAnywhereIframe(chromeId, emailData, analysisData);
            }}
          >
            Open Editor Mode
          </EditorModeButton>
        )}
        {analysisData.analysis?.top_tones?.length > 0 && (
          <TonesContainer className={"tones-container"}>
            <Text
              size={"body1"}
              style={{
                color: "var(--color-neutral-purple-600)",
                textAlign: "center",
              }}
            >
              Tones detected:{" "}
              {(() => {
                if (analysisData.analysis.top_tones.length === 1) {
                  return (
                    <b
                      onClick={() => {
                        selectTones(analysisData.analysis.top_tones[0]);
                      }}
                    >
                      {analysisData.analysis.top_tones[0]}
                    </b>
                  );
                } else if (analysisData.analysis.top_tones.length === 2) {
                  return (
                    <>
                      <b
                        onClick={() => {
                          selectTones(analysisData.analysis.top_tones[0]);
                        }}
                      >
                        {analysisData.analysis.top_tones[0]}
                      </b>{" "}
                      &{" "}
                      <b
                        onClick={() => {
                          selectTones(analysisData.analysis.top_tones[1]);
                        }}
                      >
                        {analysisData.analysis.top_tones[1]}
                      </b>
                    </>
                  );
                } else if (analysisData.analysis.top_tones.length > 2) {
                  // join together like analysisData.tones.join(", ") but with the last one being "&"
                  return (
                    <>
                      {analysisData.analysis.top_tones
                        .slice(0, -1)
                        .map((tone) => (
                          <b
                            onClick={() => {
                              selectTones(tone);
                            }}
                            key={tone}
                          >
                            {tone}
                          </b>
                        ))
                        // @ts-expect-error needs fix
                        .reduce((prev, curr) => [prev, ", ", curr])}
                      {" & "}
                      <b
                        onClick={() => {
                          selectTones(
                            analysisData.analysis.top_tones[
                              analysisData.analysis.top_tones.length - 1
                            ]
                          );
                        }}
                      >
                        {
                          analysisData.analysis.top_tones[
                            analysisData.analysis.top_tones.length - 1
                          ]
                        }
                      </b>
                    </>
                  );
                }
              })()}
            </Text>
          </TonesContainer>
        )}
      </Card>
    </CoachHeader>
  );
};

const EditorModeButton = styled(Button)`
  margin: 8px;
  width: calc(100% - 16px);
  .lav-components-icon {
    stroke: var(--color-lavender-200, #f5f7ff);
  }
`;

const TonesContainer = styled(Flex)`
  border-top: 1px solid var(--color-neutral-blue-100, #f6f8fc);
  padding: 16px;
  align-items: center;
  justify-content: center;
  b {
    text-decoration: underline;
    font-weight: bold;
    color: var(--color-neutral-purple-900);
    cursor: pointer;
  }
`;

const CoachHeader = styled(Flex)`
  width: 100%;
  margin-bottom: 12px;
  .lv-card {
    border: 2px solid #ffffff;
  }
  .lv-card .ant-card-body {
    padding: 0;
  }
  .lv-card.coach-header-success {
    .lv-score-text {
      color: var(--color-primary-lavender);
    }
    border: 2px solid var(--color-secondary-pear, #caf344);
    background-color: var(--color-pear-50, #f5ffc8);
    .tones-container {
      border-color: var(--color-secondary-pear, #caf344);
    }
  }
  .lv-card.coach-header-warning {
    .lv-score-text {
      color: var(--color-utility-Warning, #f6a40a);
    }
  }
  .lv-card.coach-header-danger {
    .lv-score-text {
      color: var(--color-utility-Negative);
    }
  }
`;
const CoachTitle = styled(Flex)`
  padding: 12px 16px 12px 20px;
`;
const ScoreAs = styled(Flex)`
  padding: 0;
  flex: 1;
  white-space: nowrap;
  justify-content: flex-end;
  flex-direction: column;
  gap: 4px;
  align-items: end;
  > span {
    margin-right: 6px;
    display: none !important;
  }
  &:hover > span {
    display: flex !important;
  }
  li.ant-menu-item.lav-components-menu-item .ant-menu-title-content {
    white-space: nowrap;
    text-wrap: nowrap !important;
  }
`;
const CoachScore = styled(Flex)`
  padding: 8px 20px 0 20px;
  gap: 16px;
`;
const CoachTime = styled(Flex)`
  padding: 24px 24px 20px 20px;
  align-items: center;
`;
const ScoreText = styled.div`
  font-family: "Bricolage Grotesque";
  font-size: 84px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: -1px;
  margin-bottom: 20px;
`;
const EnvelopeImg = styled.img`
  rotate: 3deg;
`;
