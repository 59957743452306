import { useCallback } from "react";
import { Flex } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { Text, TextProps } from "@lavender-ai/lav-components";

import {
  ExtensionButton,
  ExtensionButtonMode,
  ExtensionCheckbox,
  ExtensionCheckboxVariant,
} from "~src/component/Atoms";
import {
  PanelAlert,
  PanelAlertVariant,
} from "~src/component/Atoms/PanelAlert/PanelAlert";
import { Item as ValuePropFormItem } from "~src/component/Organisms/Forms/ValuePropForm/Item/Item";
import { PanelListItem } from "~src/layout/MainPanel/PanelBlock";
import { RankedItem } from "~src/api/typings";
import {
  SecondaryText,
  DateTriggerDivider,
  FullWidthFlex,
} from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";
import { SME_CTA } from "~src/strings";
import {
  mainTrigger,
  prettyDate,
  triggerColor,
  prettyTrigger,
} from "~src/utils";

interface CartItemProps {
  hasFormItem?: boolean;
  index: number;
  isDisabled: boolean;
  isExpanded: boolean;
  isSelected: boolean;
  item: RankedItem;
  onExpand: (id: string) => void;
  onSelect: (e: CheckboxChangeEvent, item: RankedItem) => void;
}

export const CartItem = ({
  hasFormItem,
  index,
  isDisabled,
  isExpanded,
  isSelected,
  item,
  onExpand,
  onSelect,
}: CartItemProps) => {
  const trigger = mainTrigger(item.triggers);
  const onDescriptionButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onExpand(item.id);
    },
    [item.id, onExpand]
  );
  const onCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      e.stopPropagation();
      onSelect(e, item);
    },
    [item, onSelect]
  );

  return (
    <PanelListItem isCartView>
      <Flex gap={16} vertical>
        <Flex gap={12} align="center">
          <ExtensionCheckbox
            isChecked={isSelected}
            isDisabled={isDisabled}
            onChange={onCheckboxChange}
            variant={ExtensionCheckboxVariant.compact}
          />
          <FullWidthFlex gap={16} vertical>
            <div>
              <Text color="dark" size="subtext2">
                {item.formattedTitle}
              </Text>
            </div>
            <Flex gap="middle" vertical>
              <Flex align="center" gap={6}>
                {"date" in item && item.date && (
                  <SecondaryText $light size="body1">
                    {prettyDate(item.date)}
                  </SecondaryText>
                )}
                {"date" in item && item.date && trigger && (
                  <DateTriggerDivider size="body1" />
                )}
                <Text
                  color={triggerColor(trigger, index) as TextProps["color"]}
                  size="body1"
                >
                  {prettyTrigger(trigger)}
                </Text>
              </Flex>
              {item.summary && (
                <ExtensionButton
                  isActive={isExpanded}
                  mode={ExtensionButtonMode.chip}
                  onClick={onDescriptionButtonClick}
                  suffix={LavenderIcon.IconChevronDown}
                  text={SME_CTA.SUMMARY}
                  variant="secondary"
                />
              )}
            </Flex>
            {isExpanded && (
              <Flex gap={12} className="lv-panel-item-alert-container" vertical>
                <PanelAlert
                  message={item.summary}
                  variant={PanelAlertVariant.summary}
                >
                  <ExtensionButton
                    mode={ExtensionButtonMode.cta}
                    href={item.url}
                    size="small"
                    suffix={LavenderIcon.IconArrowUpRight}
                    text={SME_CTA.SEE_SOURCE}
                  />
                </PanelAlert>
              </Flex>
            )}
            {hasFormItem && (
              <ValuePropFormItem
                id={item.id}
                section={item.type}
                title={trigger}
              />
            )}
          </FullWidthFlex>
        </Flex>
      </Flex>
    </PanelListItem>
  );
};
