import { useCallback, useEffect, useMemo } from "react";
import { Form, FormInstance } from "antd";

import { ExtensionButton } from "~src/component/Atoms";
import { LabeledInput, LabeledTextArea } from "~src/component/Molecules";

import {
  COMPANY_BIO_INPUT,
  COMPANY_NAME_INPUT,
  COMPANY_WEBSITE_INPUT,
  JOB_TITLE_INPUT,
  MIN_COMPANY_BIO_LENGTH,
  MIN_COMPANY_NAME_LENGTH,
  MIN_JOB_TITLE_LENGTH,
} from "~src/constants";
import {
  useAppSelector,
  selectUserAccountProfileCompany,
  selectUserAccountJobTitle,
  selectUserAccountProfileCompanyBio,
  selectUserAccountProfileCompanyWebsite,
} from "~src/redux";
import { UPDATE_PROFILE_FORM } from "~src/strings";

import { StyledInnerForm, StyledSnackbarContainer } from "../Shared/Styled";

export interface FieldType {
  companyBio: string;
  companyName: string;
  companyWebsite: string;
  jobTitle: string;
}

interface Props {
  form: FormInstance<FieldType>;
  isSaving: boolean;
  snackbarContainerRef?: React.RefObject<HTMLDivElement>;
  submitForm: (values: FieldType) => void;
}

export const UpdateProfile = ({
  form,
  isSaving,
  snackbarContainerRef,
  submitForm,
}: Props) => {
  const companyBio = useAppSelector((state) =>
    selectUserAccountProfileCompanyBio(state)
  );
  const companyName = useAppSelector((state) =>
    selectUserAccountProfileCompany(state)
  );
  const companyWebsite = useAppSelector((state) =>
    selectUserAccountProfileCompanyWebsite(state)
  );
  const jobTitle = useAppSelector((state) => selectUserAccountJobTitle(state));

  const formCompanyBio = Form.useWatch(COMPANY_BIO_INPUT.name, form);
  const formCompanyName = Form.useWatch(COMPANY_NAME_INPUT.name, form);
  const formCompanyWebsite = Form.useWatch(COMPANY_WEBSITE_INPUT.name, form);
  const formJobTitle = Form.useWatch(JOB_TITLE_INPUT.name, form);

  useEffect(() => {
    form.setFieldsValue({
      companyBio: formCompanyBio,
      companyName: formCompanyName,
      companyWebsite: formCompanyWebsite,
      jobTitle: formJobTitle,
    });
  }, [form, formCompanyBio, formCompanyName, formCompanyWebsite, formJobTitle]);

  const formHasChanged = useMemo(() => {
    return (
      (formCompanyBio || "") !== (companyBio || "") ||
      (formCompanyName || "") !== (companyName || "") ||
      (formCompanyWebsite || "") !== (companyWebsite || "") ||
      (formJobTitle || "") !== (jobTitle || "")
    );
  }, [
    companyBio,
    companyName,
    companyWebsite,
    formCompanyBio,
    formCompanyName,
    formCompanyWebsite,
    formJobTitle,
    jobTitle,
  ]);

  const isSubmitDisabled = useCallback(() => {
    return (
      !formHasChanged ||
      !formCompanyBio ||
      formCompanyBio.length < MIN_COMPANY_BIO_LENGTH ||
      !formCompanyName ||
      formCompanyName.length < MIN_COMPANY_NAME_LENGTH ||
      !formCompanyWebsite ||
      !formJobTitle ||
      formJobTitle.length < MIN_JOB_TITLE_LENGTH
    );
  }, [
    formCompanyBio,
    formCompanyName,
    formCompanyWebsite,
    formHasChanged,
    formJobTitle,
  ]);

  const buttonState = useMemo(() => {
    if (isSaving) {
      return "loading";
    }
    return isSubmitDisabled() ? "disabled" : "initial";
  }, [isSaving, isSubmitDisabled]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        companyBio,
        companyName,
        companyWebsite,
        jobTitle,
      }}
      onFinish={submitForm}
    >
      <StyledInnerForm vertical gap={24}>
        <Form.Item
          label={`Your ${JOB_TITLE_INPUT.label}`}
          name={JOB_TITLE_INPUT.name}
          noStyle
          rules={JOB_TITLE_INPUT.rules}
        >
          <LabeledInput
            label={`Your ${JOB_TITLE_INPUT.label}`}
            minLength={MIN_JOB_TITLE_LENGTH}
            name={JOB_TITLE_INPUT.name}
          />
        </Form.Item>
        <Form.Item
          label={`Your ${COMPANY_NAME_INPUT.label}`}
          name={COMPANY_NAME_INPUT.name}
          noStyle
          rules={COMPANY_NAME_INPUT.rules}
        >
          <LabeledInput
            label={`Your ${COMPANY_NAME_INPUT.label}`}
            name={COMPANY_NAME_INPUT.name}
          />
        </Form.Item>
        <Form.Item
          label={COMPANY_WEBSITE_INPUT.label}
          name={COMPANY_WEBSITE_INPUT.name}
          noStyle
          rules={COMPANY_WEBSITE_INPUT.rules}
        >
          <LabeledInput
            label={COMPANY_WEBSITE_INPUT.label}
            name={COMPANY_WEBSITE_INPUT.name}
          />
        </Form.Item>
        <Form.Item
          label={`Your ${COMPANY_BIO_INPUT.label}`}
          name={COMPANY_BIO_INPUT.name}
          noStyle
          rules={COMPANY_BIO_INPUT.rules}
        >
          <LabeledTextArea
            label={`Your ${COMPANY_BIO_INPUT.label}`}
            minLength={MIN_COMPANY_BIO_LENGTH}
            name={COMPANY_BIO_INPUT.name}
          />
        </Form.Item>
      </StyledInnerForm>
      <StyledSnackbarContainer ref={snackbarContainerRef}>
        <Form.Item noStyle label={UPDATE_PROFILE_FORM.SUBMIT}>
          <ExtensionButton
            fullWidth
            state={buttonState}
            variant="primary"
            htmlType="submit"
            text={
              isSaving
                ? UPDATE_PROFILE_FORM.SUBMITTING
                : UPDATE_PROFILE_FORM.SUBMIT
            }
          />
        </Form.Item>
      </StyledSnackbarContainer>
    </Form>
  );
};
