import { NetworkStatus, NewsData, RecipientNewsMap } from "~src/redux/typings";
import { createFeedAsyncThunk, parseNewsResponse } from "../helpers";
import { newPendingNews, newRejectedNews } from "~src/utils";
import { updateNews } from "../actions";
import { getNews } from "~src/api";

export const loadNews = createFeedAsyncThunk<
  NewsData,
  { toAddresses: string[] }
>(
  "feed/news",
  async ({ toAddresses }, { dispatch, getState }): Promise<NewsData> => {
    const {
      config: { chromeId },
      feed: { news: newsData },
      userAccount: {
        settings: { user },
      },
    } = getState();

    if (!chromeId || user === undefined) {
      return { toAddresses, newsData: {} };
    }

    if (!toAddresses.length) {
      return { toAddresses, newsData };
    }

    const newsDataFetchEmails = toAddresses.filter((email) => !newsData[email]);

    const pendingNewsData = newsDataFetchEmails.reduce<RecipientNewsMap>(
      (emails, email) => {
        emails[email] = newPendingNews();
        return emails;
      },
      {}
    );

    dispatch(
      updateNews({
        toAddresses,
        newsData: pendingNewsData,
      })
    );

    const resolvedNews = await Promise.all(
      newsDataFetchEmails.map(async (email) => {
        try {
          const newsDataResponse = await getNews(email, chromeId);
          return {
            status: NetworkStatus.success,
            email,
            data: parseNewsResponse(
              newsDataResponse,
              email
              // user.email !== email
            ),
          };
        } catch {
          return {
            status: NetworkStatus.failed,
            email,
            data: newRejectedNews(),
          };
        }
      })
    );

    const parsedFullfilledNews = resolvedNews.reduce<RecipientNewsMap>(
      (acc, { email, data }) => {
        acc[email] = data;
        return acc;
      },
      {}
    );

    dispatch(
      updateNews({
        toAddresses,
        newsData: parsedFullfilledNews,
      })
    );

    return { toAddresses, newsData: parsedFullfilledNews };
  }
);
