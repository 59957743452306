import { Flex } from "antd";
import { styled } from "styled-components";

import { Input, InputProps, Text } from "@lavender-ai/lav-components";

const StyledFlex = styled(Flex)`
  input.lav-components-input.ant-input {
    color: var(--color-neutral-purple-900);
  }
`;

interface Props extends Omit<InputProps, "size"> {
  name: string;
  label: string;
}

export const LabeledInput = ({ label, name, ...props }: Props) => {
  return (
    <StyledFlex vertical gap="small">
      <Text color="dark" size="subtext1" id={name}>
        {label}
      </Text>
      <Input {...props} aria-labelledby={name} size="medium" />
    </StyledFlex>
  );
};
