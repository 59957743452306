import { WorkHistory } from "~src/redux/typings";

import {
  EventEntityAttribute,
  JobEntityAttribute,
  TechEntityAttribute,
} from "./additionalCompanyData";
import { APIResponseStatus } from "./api";
import { FeedEntity } from "./personalization";

export interface RankedFeedParams {
  email: emailAddress;
  chromeId: chromeId;
}

export interface RankedFeedEmptyInfo {
  alert?: string;
  header?: string;
  caption?: string;
  cta?: string;
}

export interface RankedFeedResponse extends RankedFeedEmptyInfo {
  ranked_feed: RankedFeed;
  rankedFeed: RankedFeed;
  status: APIResponseStatus;
}

export enum RankedFeedType {
  events = "events",
  work_history = "work_history",
  job_openings = "job_openings",
  tech_stack = "tech_stack",
  funding = "funding",
}

export type RankedFeedEvent = RankedFeedItem<FeedEntity<EventEntityAttribute>>;
export type RankedFeedExperience = RankedFeedItem<WorkHistory>;
export type RankedFeedJobOpening = RankedFeedItem<
  FeedEntity<JobEntityAttribute>
>;
export type RankedFeedTech = RankedFeedItem<FeedEntity<TechEntityAttribute>>;

export interface RankedFeedSection<T> {
  items: T[];
  total_score: number;
}
export type RankedFeedEntity<T> = Omit<T, "triggers"> & {
  triggers: PersonalizationTrigger[];
};
export type PersonalizationTrigger = Record<string, number>;

interface RankedFeed {
  [RankedFeedType.events]: RankedFeedSection<RankedFeedEvent> | EmptyObject;
  [RankedFeedType.job_openings]:
    | RankedFeedSection<RankedFeedJobOpening>
    | EmptyObject;
  [RankedFeedType.funding]: RankedFeedSection<RankedFeedEvent> | EmptyObject;
  [RankedFeedType.tech_stack]: RankedFeedSection<RankedFeedTech> | EmptyObject;
  [RankedFeedType.work_history]:
    | RankedFeedSection<RankedFeedExperience>
    | EmptyObject;
}

interface RankedFeedItem<T> {
  score: number;
  Type: RankedFeedType;
  Entity: RankedFeedEntity<T>;
}
