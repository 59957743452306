import { useCallback, useEffect, useRef, useState } from "react";
import { Flex, Form, message } from "antd";
import { styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import {
  useLvIdentifier,
  useStartMyEmail,
  useStartMyEmailGeneration,
  useValuePropGeneration,
} from "~src/customHooks";

import {
  FieldType,
  ValuePropForm,
} from "../../../Forms/ValuePropForm/ValuePropForm";
import { StartMyEmailCTA } from "../../../StartMyEmail/StartMyEmailCTA/StartMyEmailCTA";

import { StartMyEmailPayload } from "~src/api/typings";
import { IconFrame, ItemCollapse } from "~src/component/Atoms";
import { PersonalizationPanelContainer } from "~src/component/Organisms";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  EnhancedFlex,
  PanelBackgroundFlex,
  SecondaryText,
} from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";
import {
  useStartMyEmailMutationContext,
  useStartMyEmailWebsocketContext,
} from "~src/contexts";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import {
  selectChromeId,
  useAppDispatch,
  useAppSelector,
  valuePropsActions,
} from "~src/redux";
import {
  CART_HEADER,
  SME_COLLAPSE,
  SME_CTA,
  SNACKBAR_TEXT,
} from "~src/strings";
import {
  getGenerateValuePropsPayload,
  getUpdatedStartMyEmailCart,
  hasMissingValue,
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
  getUpsertValuePropsPayload,
} from "~src/utils";

import { StyledStartMyEmailModalLayout } from "../../Shared/Styled";

import { Header } from "./Header/Header";
import { Cart } from "../../Shared/Cart/Cart";
import { CustomField } from "~src/component/Organisms/Forms/CustomFieldForm/CustomField";

const StyledSnackbarContainer = styled.div`
  position: relative;
  .ant-message {
    position: absolute;
  }
`;

export const CartModal = () => {
  const dispatch = useAppDispatch();
  const chromeId = useAppSelector(selectChromeId);
  const {
    data: { from },
    next,
  } = useCarouselContext();
  const lvIdentifier = useLvIdentifier();
  const {
    currentPersonalization: {
      profile: { email },
      cart,
    },
    isWebsocketsOn,
  } = useStartMyEmail();
  const { isStartMyEmail2On } = useStartMyEmailGeneration();
  const { startMyEmailMutation } = useStartMyEmailMutationContext();
  const { resetLastStartMyEmailMessage } = useStartMyEmailWebsocketContext();

  const { isUserValuePropGenerationOn, updateValueProps } =
    useValuePropGeneration();

  const [form] = Form.useForm<FieldType>();
  const categories = Form.useWatch("categories", form);
  const needsInput = hasMissingValue(categories);
  const snackbarContainerRef = useRef<HTMLDivElement>(null);
  const [messageApi, contextHolder] = message.useMessage({
    getContainer: () => snackbarContainerRef.current as HTMLElement,
    top: 12,
    maxCount: 1,
  });
  const [isSaving, setIsSaving] = useState(false);

  const showSnackbar = useCallback(
    async (message: string, type: "success" | "error") => {
      await messageApi[type]({
        content: message,
        duration: 2,
        style: {
          border: "1px var(--color-neutral-purple-200)",
          borderRadius: 12,
          position: "relative",
        },
      });
    },
    [messageApi]
  );

  useEffect(() => {
    if (isUserValuePropGenerationOn && from === ModalType.Overlay) {
      void showSnackbar(SNACKBAR_TEXT.VALUE_PROPS_GENERATED, "success");
    }
  }, [from, isUserValuePropGenerationOn, messageApi, showSnackbar]);

  const submitForm = useCallback(
    async ({ categories }: FieldType) => {
      const { custom, ...other } = categories;
      dispatch(
        valuePropsActions.setCurrentCustomValueProps({
          email,
          categories: {
            categories: {
              custom,
            },
          },
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { categories: touchedCategories } = form.getFieldsValue(
        true,
        (field) => !field.name.includes("custom") && field.touched
      );
      if (touchedCategories) {
        segmentEvent(
          SegmentEventNames.ContextAdded,
          SegmentEventLocations.StartMyEmail
        );

        setIsSaving(true);
        const errorMsg = await updateValueProps(
          getUpdatedStartMyEmailCart(cart, other, isUserValuePropGenerationOn),
          getUpsertValuePropsPayload(
            cart,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            touchedCategories,
            isUserValuePropGenerationOn
          ),
          email
        );
        if (errorMsg) {
          void showSnackbar(errorMsg, "error");
          setIsSaving(false);
          return;
        }

        await showSnackbar(SNACKBAR_TEXT.INFO_SAVED, "success");
      }

      try {
        const startMyEmailPayload: StartMyEmailPayload = {
          //TODO: code debt. Too many different types of "cart"
          cart: getGenerateValuePropsPayload(cart, other),
          custom,
          chromeId,
          sessionId: lvIdentifier,
          stream: isWebsocketsOn,
          email,
          n: isStartMyEmail2On ? 2 : 1,
        };
        resetLastStartMyEmailMessage();
        void startMyEmailMutation(startMyEmailPayload);
        next(ModalType.GeneratingEmails, {
          openedBy: email,
          phase: CART_HEADER.GENERATING,
        });
      } catch (e) {
        parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
          request: "startMyEmail",
        });
      }
    },
    [
      cart,
      chromeId,
      dispatch,
      email,
      form,
      isStartMyEmail2On,
      isUserValuePropGenerationOn,
      isWebsocketsOn,
      lvIdentifier,
      next,
      resetLastStartMyEmailMessage,
      showSnackbar,
      startMyEmailMutation,
      updateValueProps,
    ]
  );

  const onCartFormSubmit = useCallback(() => {
    form.submit();
    segmentEvent(
      SegmentEventNames.GenerateEmailsClicked,
      SegmentEventLocations.StartMyEmail
    );
  }, [form]);

  const item = {
    key: "1",
    label: (
      <Flex align="center" gap={12}>
        <IconFrame color="pear" variant={LavenderIcon.IconWand} />
        <Text color="dark" size="subtext3">
          {SME_COLLAPSE.ADD_CONTEXT}
        </Text>
      </Flex>
    ),
    children: (
      <EnhancedFlex gap={12} vertical $margin="-12px 0 0 0">
        <SecondaryText size="body1" $light>
          {SME_COLLAPSE.TRY_ADDING}
        </SecondaryText>
        <CustomField />
      </EnhancedFlex>
    ),
    forceRender: true,
  };

  return (
    <>
      {contextHolder}
      <StyledStartMyEmailModalLayout
        rootClassName="lv-cart-modal-box"
        isFullHeight
      >
        <StyledSnackbarContainer ref={snackbarContainerRef}>
          <Flex justify="space-between" vertical>
            <PersonalizationPanelContainer align="center" gap={12} vertical>
              <Header />
              <ValuePropForm form={form} submitForm={submitForm}>
                <Flex vertical gap={8}>
                  <ItemCollapse item={item} />
                  <Cart hasFormItem />
                </Flex>
              </ValuePropForm>
            </PersonalizationPanelContainer>
          </Flex>
        </StyledSnackbarContainer>
        <PanelBackgroundFlex className="lv-generate-emails-cta-container">
          <StartMyEmailCTA
            isDisabled={isSaving || needsInput}
            onClick={onCartFormSubmit}
            text={SME_CTA.GENERATE_EMAILS}
          />
        </PanelBackgroundFlex>
      </StyledStartMyEmailModalLayout>
    </>
  );
};
