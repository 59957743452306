import { PropsWithChildren } from "react";
import styled from "styled-components";

import { SKELETON_LOADING_ANIMATION_MIXIN } from "../../../Molecules/Message/styles";

const SlimSkeleton = styled("div")`
  ${SKELETON_LOADING_ANIMATION_MIXIN};
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.06) 25%,
    rgba(0, 0, 0, 0.15) 37%,
    rgba(0, 0, 0, 0.06) 63%
  );
  height: 12px;
  background-size: 400% 100%;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
`;

interface Props extends PropsWithChildren {
  loading: boolean;
  width: string | number | undefined;
}

export const LineSkeleton = ({ loading, children, width }: Props) => (
  <>
    {loading ? (
      <SlimSkeleton className={"slim-skeleton"} style={{ width }} />
    ) : (
      children
    )}
  </>
);
