import { sanitize } from "dompurify";

import {
  ChatUser,
  ChatTool,
  Chat,
  FeaturesState,
  NetworkStatus,
  Recommendations,
  User,
} from "~src/redux/typings";

import { ChatRole } from "../api/typings/askChat";
import { AUTOSEND_SUGGESTIONS } from "../constants";
import { SUGGESTIONS } from "../strings";

import { extractBodySubject } from "./email";
import { EditEmailPayload } from "~src/api/typings";

export const extractUserDataForChat = (user: User | undefined): ChatUser =>
  user
    ? (({
        person: {
          avatar = "",
          full_name: displayName = "",
          email,
          location = "",
          timezone = "",
        },
        settings: { google_web_search_tool = false },
      }) => ({
        avatar,
        displayName,
        email,
        location,
        settings: {
          [ChatTool.GoogleWebSearch]: google_web_search_tool,
        },
        timezone,
      }))(user)
    : newChatUser();

export const newChat = (): Chat => ({
  chatThreadId: "",
  messages: [],
  status: NetworkStatus.idle,
});

export const newChatMessage = (
  role: ChatRole,
  content: string,
  query?: string
) => {
  return { role, content, query };
};

export const newChatUser = (): ChatUser => ({
  avatar: "",
  displayName: "",
  email: "",
  location: "",
  settings: {
    [ChatTool.GoogleWebSearch]: false,
  },
  timezone: "",
});

export const isFixItAllTrigger = (message: string, features: FeaturesState) => {
  return message === SUGGESTIONS.FIX_IT_ALL && features.fix_all;
};

export const checkForSuggestions = (
  question: string,
  features: FeaturesState
) => {
  if (
    isFixItAllTrigger(question, features) ||
    AUTOSEND_SUGGESTIONS.includes(question)
  ) {
    return true;
  }

  const lcQuestion = question.toLowerCase();
  if (
    (lcQuestion.includes("write") || lcQuestion.includes("edit")) &&
    (lcQuestion.includes("email") || lcQuestion.includes("message"))
  ) {
    return true;
  }

  return false;
};

export const getCeqColor = (ceq: number) => {
  if (!ceq) {
    return "black";
  }
  ceq = Math.round(ceq);
  if (ceq >= 90) {
    return "lavender";
  } else if (ceq >= 80) {
    return "marigold";
  } else {
    return "tart";
  }
};

export const createChatEditEmailData = (
  chromeId: chromeId,
  response: string,
  recs: Recommendations,
  stage?: string // TODO
): EditEmailPayload => {
  const { subject, body } = extractBodySubject(response);
  return {
    body: sanitize(body, { USE_PROFILES: { html: true } }),
    chromeId,
    subject,
    recs: JSON.stringify(recs),
    stage: stage || "cold",
  };
};
