import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { isFetchBaseQueryError, isLavenderAPIError } from "~src/typeGuards";

interface LogMessage {
  identifier: keyof typeof ErrorIdentifiers;
  message: string;
  stack: string | undefined;
  additionalData: Record<string, unknown>;
}

export const ErrorIdentifiers = Object.freeze({
  API_ERROR: "API_ERROR",
  WS_JSON_PARSE_ERROR: "WS_JSON_PARSE_ERROR",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
});

export const parseAndLogError = (
  error: unknown,
  identifier: keyof typeof ErrorIdentifiers,
  additionalData: Record<string, unknown> = {}
): LogMessage | null => {
  if (error instanceof Error) {
    const logMessage: LogMessage = {
      identifier,
      message: error.message,
      stack: error.stack,
      additionalData,
    };
    if (logMessage.message !== "canceled") {
      console.error("Error Log:", JSON.stringify(logMessage, null, 2));
    }
    return logMessage;
  } else if (isFetchBaseQueryError(error)) {
    const logMessage: LogMessage = {
      identifier,
      message: messageFromBaseQueryError(error),
      stack: "No stack trace",
      additionalData,
    };
    if (logMessage.message !== "canceled") {
      console.error("Error Log:", JSON.stringify(logMessage, null, 2));
    }
    return logMessage;
  }
  return null;
};

const messageFromBaseQueryError = (error: FetchBaseQueryError) => {
  if (isLavenderAPIError(error.data)) {
    return error.data.message;
  }
  if (typeof error.data === "string") {
    return error.data;
  }
  if (typeof error.data === "object") {
    try {
      return JSON.stringify(error.data);
    } catch (error) {}
  }
  return "An unexpected error occurred";
};
