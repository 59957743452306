import { useMemo } from "react";
import { DEFAULT_ICEBREAKERS_DATA } from "~src/constants";

import {
  selectChromeId,
  useAppSelector,
  useGetRankedFeedQuery,
} from "~src/redux";

// Hook to modularize fetching ranked feed and reading it from the cache
//TODO: switch to lazy query and consider "isUninitialized" a loading state so that can be called sequentially with
// other state and don't need to check dependencies loading each time.
export const useRankedFeedApi = (
  email: emailAddress,
  skip: boolean = false
) => {
  const chromeId = useAppSelector(selectChromeId);
  const queryParams = useMemo(
    () => ({
      email,
      chromeId,
    }),
    [email, chromeId]
  );
  const {
    currentData = DEFAULT_ICEBREAKERS_DATA,
    isError,
    isFetching,
    isLoading,
    refetch,
  } = useGetRankedFeedQuery(queryParams, { skip });

  return {
    data: currentData,
    isError,
    isLoading: isLoading || isFetching,
    refetch,
  };
};
