import { FC, useEffect, useRef } from "react";
import styled from "styled-components";

import { MobileDeviceProps } from "./MobileDevice.types";
import { MobileContainer } from "./Phone";
import { WatchContainer } from "./Watch";
import { useAppSelector } from "../../redux/hooks";
import { useDraggable, useLvIdentifier } from "../../customHooks";
import {
  LAVENDER_ANYWHERE,
  OUTLOOK_NATIVE,
  MOBILE_DEVICE_WIDTH,
  MOBILE_DEVICE_HEIGHT,
  WATCH_DEVICE_WIDTH,
  WATCH_DEVICE_HEIGHT,
  MOBILE_DEVICE,
  WATCH_DEVICE,
} from "../../constants";

const DeviceContainer = styled.div<{ startx: number; starty: number }>`
  /* Layout */
  &.lv-${MOBILE_DEVICE}-device {
    width: ${MOBILE_DEVICE_WIDTH}px;
    height: ${MOBILE_DEVICE_HEIGHT}px;
    left: ${(props) => props.startx}px;
    top: ${(props) => props.starty}px;
  }
  &.lv-${WATCH_DEVICE}-device {
    width: ${WATCH_DEVICE_WIDTH}px;
    height: ${WATCH_DEVICE_HEIGHT}px;
    left: ${(props) => props.startx}px;
    top: ${(props) => props.starty}px;
  }
  top: 20px;
  position: absolute;
  z-index: 10001;

  /* INFO: for Outlook Native and Outlook Anywhere show mobile near the menu*/
  &.lv-${LAVENDER_ANYWHERE}-platform, &.lv-${OUTLOOK_NATIVE}-platform {
    left: unset;
    right: calc(var(--lv-menu-size) + 10px);
  }
`;

export const MobileDevice: FC<MobileDeviceProps> = ({
  variant = MOBILE_DEVICE,
  darkMode = false,
  firstName = "",
  lastName = "",
  subjectText = "",
  emailBody = "",
  editMailboxSubject,
  editMailBody,
  startX = 0,
  startY = 0,
}) => {
  const draggableRef = useRef<HTMLDivElement>(null);
  const lvIdentifier = useLvIdentifier();

  const { initDraggable, removeDraggable } = useDraggable(draggableRef);

  useEffect(() => {
    if (draggableRef.current) {
      initDraggable();
    }

    return () => {
      removeDraggable();
    };
  }, [initDraggable, removeDraggable]);
  const { subject, body } = useAppSelector(
    (state) => state.emailData[lvIdentifier] ?? { subject: "", body: "" }
  );

  const platform = useAppSelector((state) => state.config.settings?.platform);
  useEffect(() => {
    editMailboxSubject(subject);
    editMailBody(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DeviceContainer
      ref={draggableRef}
      className={`lv-${platform}-platform lv-${variant}-device`}
      startx={startX}
      starty={startY}
    >
      {variant === MOBILE_DEVICE ? (
        <MobileContainer
          $darkMode={darkMode}
          firstName={firstName}
          lastName={lastName}
          subjectText={subjectText}
          emailBody={emailBody}
          editMailboxSubject={editMailboxSubject}
          editMailBody={editMailBody}
        />
      ) : (
        <WatchContainer
          $darkMode={darkMode}
          firstName={firstName}
          lastName={lastName}
          subjectText={subjectText}
          emailBody={emailBody}
          editMailboxSubject={editMailboxSubject}
          editMailBody={editMailBody}
        />
      )}
    </DeviceContainer>
  );
};
