import { createContext, useCallback, useContext, useMemo } from "react";

import { useLvIdentifier, useWebsocketConnection } from "~src/customHooks";

import {
  APIResponseStatus,
  StartMyEmailCart,
  StartMyEmailWSResponse,
} from "~src/api/typings";
import {
  selectLastStartMyEmailMessageBySessionId,
  startMyEmailActions,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { filterStartMyEmailMessages } from "~src/utils";

interface Props {
  children: React.ReactNode;
}

export interface IStartMyEmailWebsocketContext {
  generatingEmailsData: StartMyEmailCart | undefined;
  generatingEmailsStatus: APIResponseStatus | undefined;
  resetLastStartMyEmailMessage: () => void;
}

const initStartMyEmailWebsocketContext: IStartMyEmailWebsocketContext = {
  generatingEmailsData: undefined,
  generatingEmailsStatus: undefined,
  resetLastStartMyEmailMessage: () => {
    /* empty */
  },
};

const StartMyEmailWebsocketContext = createContext(
  initStartMyEmailWebsocketContext
);

export const StartMyEmailWebsocketContextProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  useWebsocketConnection<StartMyEmailWSResponse>(
    filterStartMyEmailMessages,
    undefined,
    (data) => {
      dispatch(
        startMyEmailActions.setLastStartMyEmailJsonMessage({
          sessionId: lvIdentifier,
          message: data,
        })
      );
    }
  );

  const startMyEmailLastJSONMessage = useAppSelector((state) =>
    selectLastStartMyEmailMessageBySessionId(state, lvIdentifier)
  );

  const generatingEmailsData = startMyEmailLastJSONMessage?.generated;
  const generatingEmailsStatus = startMyEmailLastJSONMessage?.status;

  const resetLastStartMyEmailMessage = useCallback(() => {
    dispatch(
      startMyEmailActions.resetLastStartMyEmailJsonMessage({
        sessionId: lvIdentifier,
      })
    );
  }, [dispatch, lvIdentifier]);

  const startMyEmailMutationContext: IStartMyEmailWebsocketContext = useMemo(
    () => ({
      generatingEmailsData,
      generatingEmailsStatus,
      resetLastStartMyEmailMessage,
    }),
    [generatingEmailsData, generatingEmailsStatus, resetLastStartMyEmailMessage]
  );

  return (
    <StartMyEmailWebsocketContext.Provider value={startMyEmailMutationContext}>
      {children}
    </StartMyEmailWebsocketContext.Provider>
  );
};

export const useStartMyEmailWebsocketContext = () => {
  const context = useContext(StartMyEmailWebsocketContext);

  if (!context) {
    throw new Error(
      "useStartMyEmailWebsocketContext must be used within a StartMyEmailWebsocketContextProvider"
    );
  }

  return context;
};
