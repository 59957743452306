import { FeaturesState } from "~src/redux/typings";

export interface GetFeatureFlagResponse {
  status: "success";
  result?: FeaturesState;
}

export type FeatureFlags = Record<FeatureFlagEnum, true>;

export enum FeatureFlagEnum {
  effect_on_score = "effect_on_score",
  fix_all = "fix_all",
  google_web_search_tool = "google_web_search_tool",
  metric_engine = "metric engine",
  scoringOverwrite = "scoringOverwrite",
  sme_2 = "sme_2",
  sme_router = "sme_router",
  sme_value_props_generation = "sme_value_props_generation",
  track_toggle = "track_toggle",
  websockets = "websockets",
}
