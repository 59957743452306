export type viewState =
  | "default"
  | "settings"
  | "gif"
  | "chatGPT"
  | "mobilePreview"
  | "profile"
  | "emailCoach"
  | "frameworks"
  | "startMyEmail"
  | "intercom";
export type MainPanelState = Record<lvIdentifier, MainPanelStateItem>;
export interface MainPanelStateItem {
  isOpen: isOpenState;
  view: viewState;
  showBackGround: boolean;
  settingsAnimation: isOpenState;
  extensionSettingsAnimation: isOpenState;
}

type isOpenState = "open" | "closed" | "initial";
