import { Flex, Form } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { css, styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { IconFrame, ItemCollapse, LoadMoreButton } from "~src/component/Atoms";
import {
  PanelBlockContainer,
  StyledPanelBlockContainer,
} from "~src/component/Organisms/Personalization/Shared";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
  LoadableText,
} from "~src/component/Utilities";
import {
  useLoadMore,
  useRouteToCartModal,
  useStartMyEmail,
  useValuePropGeneration,
  useFeatureFlag,
  useNews,
  useTechStacks,
  useRankedFeedApi,
} from "~src/customHooks";
import {
  PanelBlock,
  PanelBlockVariant,
  StyledPanelBlock,
  PanelListContainer,
  PanelListItem,
  StyledPanelListItem,
} from "~src/layout/MainPanel/PanelBlock";

import { FeatureFlagEnum, RankedFeedType, RankedItem } from "~src/api/typings";
import { getComponentByType } from "~src/component/Organisms/Modals/Shared";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  EnhancedFlex,
  PanelBackgroundFlex,
  SecondaryText,
} from "~src/component/Styled";
import { LavenderIcon, PropsForStyling } from "~src/component/typings";
import { RANKED_FEED_TYPES } from "~src/constants";
import { useAppDispatch, valuePropsActions } from "~src/redux";
import { CustomCategories } from "~src/redux/typings";
import {
  CART_HEADER,
  SME_CAPTION,
  SME_COLLAPSE,
  SME_CTA,
  SME_SUBHEADER,
} from "~src/strings";
import {
  extractFirstName,
  hasItems,
  icebreakerCount,
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
  getAllRankfedFeedItems,
} from "~src/utils";

import { FullWidthFlex } from "../../Styled/FullWidthFlex";
import { StartMyEmailCTA } from "../../StartMyEmailCTA/StartMyEmailCTA";
import { Header } from "./Header/Header";
import { PersonalizationAssistant } from "./PersonalizationAssistant";
import { CustomFieldForm } from "~src/component/Organisms/Forms";
import { CustomField } from "~src/component/Organisms/Forms/CustomFieldForm/CustomField";

const IceBreakersPanelFlex = styled(Flex)<
  PropsForStyling<{ isLoading: boolean }>
>`
  ${StyledPanelBlockContainer} {
    ${({ $isLoading: isLoading }) =>
      isLoading &&
      css`
        width: var(--lv-expanded-panel-size);
        padding: 0 20px;
      `}
  }
  &:has(.lv-icebreakers-cta-tooltip.ant-tooltip-open) {
    ${StyledPanelBlock}:first-child {
      border: 1px solid var(--color-lavender-200);
      box-shadow: 0px 0px 0px 3px var(--color-lavender-50);
    }
  }

  &:not(:has(.lv-icebreakers-cta-tooltip.ant-tooltip-open)) {
    ${StyledPanelBlock}:first-child {
      border: 1px solid transparent;
    }
  }

  .lv-generate-emails-cta-container {
    border-top: 1px solid var(--color-neutral-purple-100);
    padding: 16px 0;
    padding-bottom: 0;
    position: sticky;
    bottom: 0;
    // INFO: this is to stop the component from shrinking when the panel is collapsed
    width: var(--lv-expanded-panel-size);
    z-index: 1071;
  }

  .toggle-tooltip--root& {
    min-width: 275px;
  }

  ${FullWidthFlex} {
    padding: 0 20px 16px 20px;

    .lv-panel-list-container {
      padding: 0;
      .ant-skeleton {
        padding: 16px 0;
        .ant-skeleton-paragraph {
          margin: 0;
        }
        .ant-skeleton-header {
          padding-top: 0;
        }
      }
    }
  }

  ${StyledPanelListItem} {
    padding: 0;
  }

  div:has(> .lv-load-more-button) {
    margin: 16px 0 0 0 !important;
  }
`;

export const IceBreakers = () => {
  const dispatch = useAppDispatch();
  const {
    currentPersonalization: {
      cart,
      profile: { email, loading: profileLoading, name },
    },
  } = useStartMyEmail();
  const {
    events: { items: eventItems },
  } = cart;

  const { news } = useNews();
  const { techStacks } = useTechStacks();
  const areDependenciesLoading =
    profileLoading ||
    !news[email] ||
    news[email]?.loading ||
    !techStacks[email] ||
    techStacks[email]?.loading;

  const {
    data: { alert, cta, rankedFeed },
    isLoading,
  } = useRankedFeedApi(email, areDependenciesLoading);

  const { next } = useCarouselContext();
  const { handleRouteToCartModal } = useRouteToCartModal();
  const { isValuePropGenerationOn } = useValuePropGeneration();
  const isRankedFeedLoading = areDependenciesLoading || isLoading || !!alert;
  const displayName = extractFirstName(name || "") || email || "";
  const hasSmeRouting = useFeatureFlag(FeatureFlagEnum.sme_router);
  const cartCount = icebreakerCount(cart);
  const [form] = Form.useForm<CustomCategories>();

  const allItems = getAllRankfedFeedItems(rankedFeed);
  const { currentItems, loadMore, hasMore } = useLoadMore(allItems);

  const currentItemsByType = useMemo(() => {
    return RANKED_FEED_TYPES.reduce<Record<RankedFeedType, RankedItem[]>>(
      (acc, type) => {
        acc[type] = currentItems.filter((item) => item.type === type);
        return acc;
      },
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      {} as Record<RankedFeedType, RankedItem[]>
    );
  }, [currentItems]);

  const renderItems = useMemo(() => {
    return Object.entries(currentItemsByType)
      .map(([type, items]) => {
        return !isRankedFeedLoading && items.length > 0
          ? getComponentByType(RankedFeedType[type], items)
          : null;
      })
      .filter((item) => item !== null);
  }, [currentItemsByType, isRankedFeedLoading]);

  const submitForm = useCallback(
    (categories: CustomCategories) => {
      dispatch(
        valuePropsActions.setCurrentCustomValueProps({
          email,
          categories,
        })
      );
    },
    [dispatch, email]
  );

  const onCTAClick = useCallback(() => {
    segmentEvent(
      SegmentEventNames.GenerateValuePropsClicked,
      SegmentEventLocations.StartMyEmail
    );
    form.submit();
    handleRouteToCartModal();
  }, [form, handleRouteToCartModal]);

  useEffect(() => {
    if (!hasSmeRouting) {
      return;
    }
    const smeCount = eventItems.filter((item) => item.showCart === true).length;
    if (smeCount > 0) {
      if (cta) {
        next(ModalType.ProfileForm, {
          openedBy: email,
          cta,
        });
      } else {
        next(ModalType.Cart, {
          openedBy: email,
          phase: CART_HEADER.ADD_CONTEXT,
        });
      }
    }
  }, [next, email, hasSmeRouting, cta, eventItems]);

  const item = {
    key: "1",
    label: (
      <Flex align={isRankedFeedLoading ? "flex-end" : "center"} gap={12}>
        <IconFrame
          color="pear"
          variant={LavenderIcon.IconWand}
          isLoading={isRankedFeedLoading}
        />
        <Text color="dark" size="subtext3">
          <LoadableText
            height="8px"
            loading={isRankedFeedLoading}
            text={SME_COLLAPSE.ADD_CONTEXT}
          />
        </Text>
      </Flex>
    ),
    children: (
      <EnhancedFlex gap={12} vertical $margin="-12px 0 0 0">
        <SecondaryText size="body1" $light>
          {SME_COLLAPSE.TRY_ADDING}
        </SecondaryText>
        <CustomFieldForm form={form} submitForm={submitForm}>
          <CustomField />
        </CustomFieldForm>
      </EnhancedFlex>
    ),
    forceRender: true,
  };

  return (
    <IceBreakersPanelFlex
      justify="space-between"
      vertical
      $isLoading={isRankedFeedLoading}
    >
      <Header displayName={displayName} />
      <PanelBlockContainer gap="8px">
        {(isRankedFeedLoading || hasItems(rankedFeed)) && (
          <ItemCollapse collapsible={!isRankedFeedLoading} item={item} />
        )}
        {(isRankedFeedLoading || hasItems(rankedFeed)) && (
          <PanelBlock variant={PanelBlockVariant.list}>
            <EnhancedFlex $padding="20px 16px" gap="12px">
              <IconFrame
                color="lavender"
                isLoading={isRankedFeedLoading}
                variant={LavenderIcon.IconSparkles}
              />
              <Flex vertical gap="12px">
                <Text color="dark" size="subtext3">
                  <LoadableText
                    height="10.5px"
                    loading={isRankedFeedLoading}
                    text={SME_SUBHEADER.ICEBREAKERS}
                  />
                </Text>
                <SecondaryText size="body1" $light>
                  <LoadableText
                    height="8px"
                    loading={isRankedFeedLoading}
                    text={SME_CAPTION.SELECTED(cartCount)}
                  />
                </SecondaryText>
              </Flex>
            </EnhancedFlex>
            <FullWidthFlex vertical>
              {isRankedFeedLoading ? <Loading /> : renderItems}
              {hasMore && (
                <LoadMoreButton
                  buttonText={SME_CTA.MORE_SUGGESTIONS}
                  onClick={loadMore}
                />
              )}
            </FullWidthFlex>
          </PanelBlock>
        )}
        <PersonalizationAssistant displayName={displayName} />
      </PanelBlockContainer>
      {hasItems(rankedFeed) && !isRankedFeedLoading && (
        <PanelBackgroundFlex className="lv-generate-emails-cta-container">
          <StartMyEmailCTA
            count={cartCount}
            isDisabled={cartCount === 0}
            onClick={onCTAClick}
            showValuePropGenerationToggle={isValuePropGenerationOn}
            text={SME_CTA.GENERATE_EMAILS}
          />
        </PanelBackgroundFlex>
      )}
    </IceBreakersPanelFlex>
  );
};

const Loading = () => {
  return (
    <PanelListContainer>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
    </PanelListContainer>
  );
};
