import { useEffect, useState } from "react";
import { Flex, Space } from "antd";
import { Button, Icon, Text } from "@lavender-ai/lav-components";

import { ImageFallbackAvatar } from "~src/component/Molecules";
import {
  usePersonalization,
  useFeatureFlag,
  useRankedFeedApi,
} from "~src/customHooks";

import { SecondaryText } from "~src/component/Styled";
import { CART_HEADER } from "~src/strings";
import { eventAvatarUrl, prettyDate } from "~src/utils";

import { NavigationWrapper } from "../Views/NavigationWrapper";

import { ModalType, useCarouselContext } from "../CarouselContextProvider";

export const NewsModal = () => {
  const [generateStatus, setGenerateStatus] = useState("Generate Email");
  const { data, next } = useCarouselContext();
  const hasSmeRouting = useFeatureFlag("sme_router");
  const {
    addItemToCartContext,
    currentPersonalization: {
      profile: { email },
    },
    resetCartContext,
  } = usePersonalization();
  const {
    data: { cta },
  } = useRankedFeedApi(email, true);

  const onCTAClick = () => {
    if (generateStatus === "Start My Email") {
      if (cta) {
        next(ModalType.ProfileForm, {
          openedBy: email,
          cta,
        });
      } else {
        next(ModalType.Cart, {
          openedBy: email,
          phase: CART_HEADER.ADD_CONTEXT,
        });
      }
    } else {
      setGenerateStatus("Adding...");
      setTimeout(() => {
        addItemToCartContext({ ...data, showCart: true });
        setGenerateStatus("Start My Email");
      }, 1000);
    }
  };

  useEffect(() => {
    resetCartContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let generateIcon;
  if (generateStatus === "Generate Email") generateIcon = "IconWand";
  if (generateStatus === "Start My Email") generateIcon = "IconMailBolt";
  return (
    <NavigationWrapper>
      <Space direction={"vertical"} style={{ padding: "20px" }}>
        <Space
          size={20}
          style={{
            background: "white",
            borderRadius: "var(--radius-lrg, 16px)",
            padding: 20,
            textAlign: "left",
          }}
          direction={"vertical"}
        >
          <Flex gap={24} vertical>
            <ImageFallbackAvatar
              alt="Source"
              size="large"
              fallback={data.fallbackLogo}
              src={eventAvatarUrl(data)}
              style={{ border: "1px solid var(--color-neutral-blue-200)" }}
            />
            <Text size="subtext4">{data.title}</Text>
          </Flex>
          <Space direction={"horizontal"} size={4}>
            {data.date && (
              <SecondaryText $light size={"body1"}>
                {prettyDate(data.date)}
              </SecondaryText>
            )}
            {data.date && data.source && (
              <SecondaryText $light size={"body1"}>
                •
              </SecondaryText>
            )}
            {data.source && (
              <SecondaryText $light size={"body1"}>
                {data.source}
              </SecondaryText>
            )}
          </Space>
          <Text size={"body2"} style={{ fontStyle: "italic" }}>
            {data.point}
          </Text>
          <Text size={"body2"}>{data.summary}</Text>
          <Space direction={"vertical"} size={8} style={{ width: "100%" }}>
            <Button
              size="medium"
              variant={"default"}
              fullWidth={true}
              href={data.url}
              target="_blank"
            >
              Read Full Article
              <Icon variant={"IconArrowUpRight"} size="extra-small" />
            </Button>
            {hasSmeRouting && data.trigger && data.trigger !== "none" && (
              <Button
                prefix={generateIcon}
                prefixColor="white"
                size="medium"
                variant={"primary"}
                fullWidth={true}
                onClick={onCTAClick}
                state={generateStatus === "Adding..." ? "loading" : "initial"}
              >
                {generateStatus}
              </Button>
            )}
          </Space>
        </Space>
      </Space>
    </NavigationWrapper>
  );
};
