import styled from "styled-components";
import React, { useEffect } from "react";
import { Flex, Space } from "antd";
import {
  differenceInHours,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";

import { Icon, Text, Tooltip } from "@lavender-ai/lav-components";

import { TopBar } from ".";
import { useLvIdentifier } from "~src/customHooks";
import {
  fetchOpenEmailTotals,
  openEmailTrackingAction,
  readTheRoomEmailSummaryAction,
} from "~src/redux";
import { useAppDispatch, useAppSelector } from "~src/redux/hooks";
import { OpenEmailType } from "~src/redux/typings";
import { MAILCLIENT } from "~src/constants";
import errorEmail from "~src/assets/images/envelope-error.svg";

export const OpenEmailCount = () => {
  const chromeId = useAppSelector((state) => state.config.chromeId);
  const lvIdentifier = useLvIdentifier();
  const openTotal = useAppSelector(
    (state) => state.openEmailTracking[lvIdentifier]?.openTotal
  );
  const dispatch = useAppDispatch();

  const settings = useAppSelector((state) => state.config.settings);

  // make API call to get the open email total data
  useEffect(() => {
    void dispatch(fetchOpenEmailTotals({ chromeId, lvIdentifier }));

    if (settings?.platform === MAILCLIENT.OUTLOOK_NATIVE) {
      dispatch(openEmailTrackingAction.open({ lvIdentifier }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle icon active look
  const isOpen = useAppSelector(
    (state) => state.openEmailTracking[lvIdentifier]?.isOpen
  );

  // close read the room email summary if open
  useEffect(() => {
    if (isOpen && settings?.platform !== MAILCLIENT.OUTLOOK_NATIVE) {
      dispatch(readTheRoomEmailSummaryAction.close({ lvIdentifier }));
    }
  }, [dispatch, isOpen, lvIdentifier]);
  if (openTotal === undefined) {
    return null;
  }

  return (
    <span
      className={`lv-email-thread-icon-hit-box ${
        isOpen ? "lv-email-thread-icon-active" : "lv-email-thread-icon-hover"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(openEmailTrackingAction.toggle({ lvIdentifier }));
      }}
    >
      <Flex
        className="lv-openEmailContainerCount"
        justify="space-around"
        align="center"
      >
        <Tooltip placement="bottom" title="Activity" hideArrow>
          <Space size="small">
            <Icon color="lavender" size="small" variant="IconMailOpened" />
            <Text color="black" size="subtext2">
              {openTotal}
            </Text>
          </Space>
        </Tooltip>
      </Flex>
      {<OpenEmailPopup openTotal={openTotal} />}
    </span>
  );
};
interface OpenEmailPopupProps {
  openTotal: number;
}

const OpenEmailPopup: React.FC<OpenEmailPopupProps> = ({
  openTotal,
}: OpenEmailPopupProps) => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const closePopup = (e) => {
    e.stopPropagation();
    dispatch(openEmailTrackingAction.close({ lvIdentifier }));
  };

  const rowData = useAppSelector(
    (state) => state.openEmailTracking[lvIdentifier]?.rows ?? []
  );

  const rows = createRows(rowData, lvIdentifier);
  //INFO: handle animation
  const isOpen = useAppSelector(
    (state) => state.openEmailTracking[lvIdentifier]?.isOpen
  );

  return (
    <Flex
      vertical={true}
      className={`lv-email-thread-popup-${
        isOpen ? "open" : "close"
      } lv-email-thread-open-email-container`}
    >
      <TopBar
        title={`Email Opened ${openTotal} ${
          openTotal === 1 ? "Time" : "Times"
        }`}
        onClose={closePopup}
      />
      <Flex vertical={true} align="center" className="lv-email-thread-scroll">
        {rows}
        {openTotal === 0 && (
          <Flex vertical={true} justify="center">
            <div style={{ margin: "auto", marginTop: "36px" }}>
              <EnvelopeErrorImg
                src={errorEmail}
                alt="Error"
                style={{ width: "62px" }}
              />
            </div>
            <Text size="subtext3" style={{ margin: "12px auto 0" }}>
              No opens detected
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const createRows = (rowData: OpenEmailType[], lvIdentifier) => {
  const sortedRowData = [...rowData].sort(
    (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
  );
  return sortedRowData.map((row, index) => {
    const deviceIcon =
      row.device === "desktop" ? "IconDeviceDesktop" : "IconDeviceMobile";
    const now = new Date();
    const date2 = new Date(row.time);
    const hours = differenceInHours(now, date2);
    const minutes = differenceInMinutes(now, date2);
    const days = differenceInDays(now, date2);
    let time: string;
    if (days === 1) {
      time = `Yesterday`;
    } else if (days > 1) {
      time = `${days}d`;
    } else if (hours >= 1) {
      time = `${hours}h`;
    } else if (minutes >= 1) {
      time = `${differenceInMinutes(now, date2)}m`;
    } else {
      time = `${differenceInSeconds(now, date2)}s`;
    }
    return (
      <Flex
        className="lv-email-thread-open-email-row"
        justify="space-between"
        align="center"
        key={`${lvIdentifier}-lv-open-email-row-${index}`}
      >
        <span className="lv-email-thread-open-email-icon">
          <Icon variant={deviceIcon} size="small" color="black" />
        </span>
        <Text size="subtext2" color="black">
          Read your message
        </Text>
        <Text size="body2" color="black">
          {time}
        </Text>
      </Flex>
    );
  });
};

const EnvelopeErrorImg = styled.img`
  rotate: -3deg;
`;
