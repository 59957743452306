import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { CarouselRef } from "antd/lib/carousel";

import { ChatModal } from "./modals/ChatModal";
import { JobsModal } from "./modals/JobsModal";
import { NewsModal } from "./modals/NewsModal";
import { NotesModal } from "./modals/NotesModal";
import { TechStackModal } from "./modals/TechStackModal";
import { PodcastsModal } from "./modals/PodcastsModal";
import { GongCallPanel } from "./Views/Profile/Gong/GongCallPanel";

import { SlideContainer, SlideChild } from "~src/component/Atoms";
import { StyledCarouselWrapper } from "~src/component/Styled";
import { Tech } from "~src/redux/typings";

import {
  StartMyEmailMutationContextProvider,
  StartMyEmailWebsocketContextProvider,
  useGlobalSearchContext,
} from "~src/contexts";
import { CartModal } from "~src/component/Organisms/Modals/StartMyEmail/CartModal/CartModal";
import { GeneratingEmailsModal } from "~src/component/Organisms/Modals/StartMyEmail/GeneratingEmailsModal/GeneratingEmailsModal";
import { CartEmptiedModal } from "~src/component/Organisms/Modals/StartMyEmail/CartEmptiedModal/CartEmptiedModal";
import { OverlayModal } from "~src/component/Organisms/Modals/StartMyEmail/OverlayModal/OverlayModal";
import { ProfileForm } from "~src/component/Organisms/Modals/StartMyEmail/ProfileForm/ProfileForm";

export enum ModalType {
  //SME
  "Cart" = "Cart",
  "CartEmptied" = "CartEmptied",
  "GeneratingEmails" = "GeneratingEmails",
  "Overlay" = "Overlay",
  "PodcastDetails" = "PodcastDetails",
  "ProfileForm" = "ProfileForm",
  //Personalization
  "NewsEventsDetails" = "NewsEventsDetails",
  "Tech Stack" = "Tech Stack",
  "Job Openings" = "Job Openings",
  "Notes" = "Notes",
  "Chat" = "Chat",
  "GongCallPanel" = "GongCallPanel",
  //Shared
  "Empty" = "Empty",
}
type modalType =
  //SME
  | ModalType.Cart
  | ModalType.CartEmptied
  | ModalType.GeneratingEmails
  | ModalType.Overlay
  | ModalType.ProfileForm
  //Personalization
  | "Tech Stack"
  | "Job Openings"
  | "Notes"
  | ModalType.Chat
  | "GongCallPanel"
  | ModalType.PodcastDetails
  | ModalType.NewsEventsDetails
  //Shared
  | "Empty";

export interface CarouselContextType {
  next: (t: modalType, newData: any) => void;
  prev: (prevModal: PrevModal) => void;
  data?: any;
  modalType: modalType;
  prevModal: PrevModal;
  setPrevModal: (prevModal) => void;
  reset: () => void;
}

interface PrevModal {
  prevType: modalType;
  prevData: any;
}

const initialState: CarouselContextType = {
  data: {},
  next: (_t, _d) => {},
  prev: (_prevModal) => {},
  modalType: "Empty",
  prevModal: {
    prevType: "Empty",
    prevData: {},
  },
  setPrevModal: (_prevModal) => {},
  reset: () => {},
};

const CarouselContext = createContext<CarouselContextType>(initialState);

export const CarouselContextProvider = ({ children }: PropsWithChildren) => {
  const carouselRef = useRef<CarouselRef>(null);
  const [data, setData] = useState<unknown>(initialState.data);
  const [modalType, setModalType] = useState<modalType>(initialState.modalType);
  const [prevModal, setPreviousModal] = useState<PrevModal>(
    initialState.prevModal
  );
  const next = useCallback((t: modalType, d: unknown) => {
    carouselRef.current?.next();
    setData(d);
    setModalType(t);
  }, []);
  const prev = useCallback((p: PrevModal) => {
    carouselRef?.current?.prev();
    setData(p.prevData);
    setModalType(p.prevType);
  }, []);
  const setPrevModal = useCallback((p: PrevModal) => {
    setPreviousModal(p);
  }, []);
  const reset = useCallback(() => {
    prev(initialState.prevModal);
  }, [prev]);
  const carouselContextValue = useMemo(
    () => ({
      next,
      prev,
      data,
      modalType,
      prevModal,
      reset,
      setPrevModal,
    }),
    [next, prev, data, modalType, prevModal, reset, setPrevModal]
  );

  const { isModalOpen } = useGlobalSearchContext();
  // INFO: This Contnext Provider is used to render the React components in a carousel, along with the detail view of some of the components.
  return (
    <CarouselContext.Provider value={carouselContextValue}>
      <StyledCarouselWrapper className="lv-ant-d-carousel-container">
        <SlideContainer>
          <SlideChild
            noSlide
            stage={modalType !== ModalType.Notes ? "active" : "previous"}
          >
            <div
              style={{
                width: 375,
                ...(isModalOpen ? { height: "100%", overflow: "hidden" } : {}),
              }}
            >
              {children}
            </div>
            <div style={{ width: 375 }}>
              {modalType === ModalType.Chat && <ChatModal />}
            </div>
          </SlideChild>
          <SlideChild stage={modalType === "Job Openings" ? "active" : "next"}>
            <JobsModal />
          </SlideChild>
          <SlideChild stage={modalType === "Notes" ? "active" : "next"}>
            <NotesModal />
          </SlideChild>
          <SlideChild stage={modalType === "GongCallPanel" ? "active" : "next"}>
            <GongCallPanel />
          </SlideChild>
          <SlideChild
            stage={modalType === ModalType.PodcastDetails ? "active" : "next"}
          >
            <PodcastsModal />
          </SlideChild>
          <SlideChild stage={modalType === "Tech Stack" ? "active" : "next"}>
            <TechStackModal tech={data as Tech} />
          </SlideChild>
          <SlideChild
            stage={
              modalType === ModalType.NewsEventsDetails ? "active" : "next"
            }
          >
            <NewsModal />
          </SlideChild>
          <SlideChild stage={modalType === "Job Openings" ? "active" : "next"}>
            <JobsModal />
          </SlideChild>
          <StartMyEmailMutationContextProvider>
            <StartMyEmailWebsocketContextProvider>
              <SlideChild
                stage={modalType === ModalType.Cart ? "active" : "next"}
              >
                <CartModal />
              </SlideChild>
              <SlideChild
                noSlide
                stage={
                  modalType === ModalType.GeneratingEmails ? "active" : "next"
                }
              >
                <GeneratingEmailsModal />
              </SlideChild>
            </StartMyEmailWebsocketContextProvider>
          </StartMyEmailMutationContextProvider>
          <SlideChild
            noSlide
            stage={modalType === ModalType.CartEmptied ? "active" : "next"}
          >
            <CartEmptiedModal />
          </SlideChild>
          <SlideChild
            stage={modalType === ModalType.Overlay ? "active" : "next"}
          >
            <OverlayModal />
          </SlideChild>
          <SlideChild
            stage={modalType === ModalType.ProfileForm ? "active" : "next"}
          >
            <ProfileForm />
          </SlideChild>
        </SlideContainer>
      </StyledCarouselWrapper>
    </CarouselContext.Provider>
  );
};

export const useCarouselContext = (): CarouselContextType =>
  useContext(CarouselContext);
