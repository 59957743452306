import { XOR } from "ts-xor";

import { ErrorResponse } from "./api";

export interface EditEmailPayload {
  body: string;
  chromeId: chromeId;
  subject: string;
  promptName?: string;
  recs: string;
  stage: string;
}

export interface EditEmailSuccessResponse {
  bypassed_ai?: boolean;
  email: string;
  subject: string;
  status: "success";
}

export type EditEmailResponse = XOR<EditEmailSuccessResponse, ErrorResponse>;
