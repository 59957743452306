import { HumanticStatus, RankedFeedType } from "../api/typings";
import {
  type PersonalizationTabContext,
  CompanySubTab,
  PersonalizationTab,
  ProfileSubTab,
} from "../component/typings/personalization";
import {
  type PersonalizationCompanyData,
  PersonalizationProfilePersonalityData,
  PersonalizationRankedData,
  PersonalizationCompanyDataJobs,
} from "../redux/typings";

export const PERSONALIZATION_SUB_TABS = { ...ProfileSubTab, ...CompanySubTab };
export const INIT_PERSONALIZATION_TAB_CONTEXT: PersonalizationTabContext = {
  [PersonalizationTab.FEED]: {
    tab: PersonalizationTab.FEED,
    subTab: undefined,
  },
  [PersonalizationTab.PROFILE]: {
    tab: PersonalizationTab.PROFILE,
    subTab: ProfileSubTab.ABOUT,
  },
  [PersonalizationTab.COMPANY]: {
    tab: PersonalizationTab.COMPANY,
    subTab: CompanySubTab.ABOUT,
  },
};
export const INIT_PERSONALIZATION_TAB = PersonalizationTab.FEED;
export const RANKED_FEED_TYPES = Object.freeze([
  RankedFeedType.events,
  RankedFeedType.job_openings,
  RankedFeedType.tech_stack,
  RankedFeedType.work_history,
  RankedFeedType.funding,
]);
export const DEFAULT_RANKED_FEED_SECTION = { items: [], total_score: 0 };
export const DEFAULT_RANKED_FEED = RANKED_FEED_TYPES.reduce((acc, type) => {
  acc[type] = DEFAULT_RANKED_FEED_SECTION;
  return acc;
  // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
}, {} as PersonalizationRankedData);
export const DEFAULT_CART: PersonalizationRankedData = DEFAULT_RANKED_FEED;
const DEFAULT_JOB_OPENINGS: PersonalizationCompanyDataJobs = {
  loading: false,
  jobs: undefined,
  aggregate: [],
};
export const DEFAULT_COMPANY: PersonalizationCompanyData = {
  bio: "",
  domain: "",
  location: "",
  logo: "",
  employeesRange: "",
  name: "",
  raised: 0,
  industries: [],
  investments: [],
  investors: new Map(),
  social_medias: {
    crunchbase: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    website: "",
  },
  jobs: DEFAULT_JOB_OPENINGS,
};
export const DEFAULT_COMPANY_TWITTER = {};
export const DEFAULT_PROFILE_TWITTER = {};
export const DEFAULT_RECENT_ACTIVITY = {};
export const DEFAULT_PERSONALITY: PersonalizationProfilePersonalityData = {
  loading: false,
  sorter_segment: "",
  communication_advice: {
    description: [],
    adjectives: [],
    what_to_avoid: [],
    what_to_say: [],
  },
  disc_assessment: {
    dominance: "",
    influence: "",
    steadiness: "",
    calculativeness: "",
  },
  ocean_assessment: {
    agreeableness: "",
    conscientiousness: "",
    extraversion: "",
    emotional_stability: "",
    openness: "",
  },
  status: HumanticStatus.IN_PROGRESS,
};
export const DEFAULT_PROFILE_COMPANY = {
  company_bio: "",
  company_name: "",
  url: "",
};

// INFO: 8 categories selected from https://docs.predictleads.com/#job-opening-categories
export const JOB_CATEGORIES = [
  "engineering",
  "finance",
  "human_resources",
  "design",
  "marketing",
  "operations",
  "sales",
  "support",
];
