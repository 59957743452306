import {
  TechAggregateData,
  TechAggregateDataAttribute,
} from "../api/typings/additionalCompanyData";
import { PersonalizationTechAggregateData } from "../redux/typings";

export const parseTechAggregateData = (
  data: TechAggregateData | null
): PersonalizationTechAggregateData[] => {
  return data === null
    ? []
    : (
        Object.entries(data).filter(
          ([_, value]) => Array.isArray(value) && value.length > 0
        ) as [keyof TechAggregateData, TechAggregateDataAttribute[]][]
      ).map(([category, tech]) => ({
        category,
        tech: tech.map(({ attributes: { description, title, url }, type }) => ({
          description,
          title,
          url,
          type,
        })),
      }));
};
