import { isAxiosError } from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sanitize } from "dompurify";

import { editEmail } from "~src/api";
import { EditEmailPayload } from "~src/api/typings";
import { EMPTY_ARRAY } from "~src/utils";

import {
  FixItAllState,
  FixItAllSuggestionData,
  FixItAllSuggestionPayload,
  NetworkStatus,
} from "./typings";
import { AppDispatch, RootState } from "./store";
import { createAppSelector } from "./selectors";
import {
  DEFAULT_FIX_IT_ALL_SUGGESTION,
  AI_GEN_CONTENT_FALLBACK_ERR,
} from "~src/constants";
import { parseAndLogError, ErrorIdentifiers } from "~src/logger";

const initialState: FixItAllState = {} as FixItAllState;

export const getFixItAll = createAsyncThunk<
  FixItAllSuggestionData | undefined,
  FixItAllSuggestionPayload,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  "fetchFixItAll",
  async (
    {
      controller,
      lvIdentifier,
      chromeId,
      body,
      subject,
      recommendations,
      promptName,
      stage,
    },
    { dispatch }
  ) => {
    const signal = controller.signal;
    const payload: EditEmailPayload = {
      body: sanitize(body, { USE_PROFILES: { html: true } })
        .replace(/<br>/g, "\n")
        .replace(/<br\/>/g, "\n"),
      chromeId,
      subject,
      recs: JSON.stringify(recommendations),
      promptName,
      stage,
    };
    dispatch(
      fixItAllSlice.actions.setPendingFixItAllSession({
        abort: () => {
          void dispatch(abortFixItAllSession({ controller, lvIdentifier }));
        },
        controller,
        lvIdentifier,
        chromeId,
        body,
        subject,
        recommendations,
        promptName,
        stage,
      })
    );
    try {
      const data = await editEmail(payload, signal);
      console.debug(data);
      return {
        lvIdentifier,
        data,
      };
    } catch (e) {
      const parsedErr = parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
        request: "fetchFixItAll",
      });
      if (parsedErr?.message === "canceled") {
        return undefined;
      }
      if (isAxiosError(e)) {
        throw new Error(
          e.response?.data.message ?? AI_GEN_CONTENT_FALLBACK_ERR
        );
      } else {
        throw new Error(AI_GEN_CONTENT_FALLBACK_ERR);
      }
    }
  }
);

export const abortFixItAllSession = createAsyncThunk<
  null,
  { controller: AbortController; lvIdentifier: string },
  { dispatch: AppDispatch; state: RootState }
>(
  "fixItAll/abortFixItAllSession",
  ({ controller, lvIdentifier }, { dispatch }) => {
    controller.abort();
    dispatch(fixItAllSlice.actions.resetFixItAllSession({ lvIdentifier }));
    return null;
  }
);

const fixItAllSlice = createSlice({
  name: "selectedFixItAll",
  initialState,
  reducers: {
    resetFixItAllSession(
      state,
      { payload }: PayloadAction<{ lvIdentifier: string }>
    ) {
      const { lvIdentifier } = payload;
      state[lvIdentifier] = {
        recommendations: EMPTY_ARRAY,
        abort: () => {},
        fetchFixItAllError: "",
        fixItAllSuggestion: DEFAULT_FIX_IT_ALL_SUGGESTION,
        fetchFixItAllStatus: NetworkStatus.idle,
        promptName: "",
        stage: "",
      };
    },
    setPendingFixItAllSession(
      state,
      {
        payload,
      }: PayloadAction<FixItAllSuggestionPayload & { abort: () => void }>
    ) {
      const { abort, lvIdentifier, recommendations, promptName, stage } =
        payload;
      state[lvIdentifier] = {
        recommendations,
        abort,
        fetchFixItAllError: "",
        fixItAllSuggestion: DEFAULT_FIX_IT_ALL_SUGGESTION,
        fetchFixItAllStatus: NetworkStatus.loading,
        promptName,
        stage,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFixItAll.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        const {
          lvIdentifier,
          data: { bypassed_ai, email = "", subject = "" },
        } = payload;
        state[lvIdentifier].fixItAllSuggestion = {
          bypassed_ai,
          email,
          subject,
        };
        state[lvIdentifier].fetchFixItAllError = "";
        state[lvIdentifier].abort = () => {};
        state[lvIdentifier].fetchFixItAllStatus = NetworkStatus.success;
      })
      .addCase(getFixItAll.rejected, (state, { error, meta }) => {
        const { lvIdentifier } = meta.arg;
        state[lvIdentifier].abort = () => {};
        state[lvIdentifier].fixItAllSuggestion = DEFAULT_FIX_IT_ALL_SUGGESTION;
        state[lvIdentifier].fetchFixItAllStatus = NetworkStatus.failed;
        state[lvIdentifier].fetchFixItAllError =
          error.message ?? AI_GEN_CONTENT_FALLBACK_ERR;
      });
  },
});

export const fixItAllRecommendationAction = fixItAllSlice.actions;
export const fixItAllReducer = fixItAllSlice.reducer;

const selectFixItAll = (state: RootState) => state.fixItAll;
const selectSessionId = (_state: RootState, sessionId: lvIdentifier) =>
  sessionId;
export const selectFixItAllAbortBySessionId = createAppSelector(
  [selectFixItAll, selectSessionId],
  (fixItAll, sessionId) => fixItAll[sessionId]?.abort ?? (() => {})
);
export const selectFixItAllRecommendationsBySessionId = createAppSelector(
  [selectFixItAll, selectSessionId],
  (fixItAll, sessionId) => fixItAll[sessionId]?.recommendations ?? EMPTY_ARRAY
);
export const selectFixItAllPromptNameBySessionId = createAppSelector(
  [selectFixItAll, selectSessionId],
  (fixItAll, sessionId) => fixItAll[sessionId]?.promptName ?? ""
);
export const selectFixItAllStatusBySessionId = createAppSelector(
  [selectFixItAll, selectSessionId],
  (fixItAll, sessionId) =>
    fixItAll[sessionId]?.fetchFixItAllStatus ?? NetworkStatus.idle
);
export const selectFixItAllErrorBySessionId = createAppSelector(
  [selectFixItAll, selectSessionId],
  (fixItAll, sessionId) =>
    fixItAll[sessionId]?.fetchFixItAllError ?? AI_GEN_CONTENT_FALLBACK_ERR
);
export const selectFixItAllSuggestionBySessionId = createAppSelector(
  [selectFixItAll, selectSessionId],
  (fixItAll, sessionId) =>
    fixItAll[sessionId]?.fixItAllSuggestion ?? DEFAULT_FIX_IT_ALL_SUGGESTION
);
