import React, { useCallback, useEffect, useRef, useState } from "react";
import { Flex } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { styled } from "styled-components";

import {
  RecipientMenuGroup,
  StartMyEmailMenuButton,
} from "~src/component/Organisms";

import {
  BadgeWrapper,
  Button,
  Icon,
  Switch,
  Text,
  Tooltip,
} from "@lavender-ai/lav-components";

import { LavenderLogo } from "~src/component/Atoms/LavenderLogo";
import { MobileDeviceMenu } from "~src/component/MobileDevice";
import {
  useLvIdentifier,
  usePersonalization,
  useFeatureFlag,
} from "~src/customHooks";

import { useAppDispatch, useAppSelector } from "~src/redux/hooks";
import { LavenderIcon } from "~src/component/typings";
import { EmailScore, Spinner } from "~src/component/Lottie";

import {
  getBodyLength,
  getSystemsData,
  getWritingData,
} from "./EmailCoachPanel/utils";

import { networkRequest, URLS } from "~src/api";
import { CENTERED_SVG_MIXIN } from "~src/component/Styled";
import { MIN_EMAIL_BODY_WORD_COUNT } from "~src/constants";
import { EmailCoachView, useEmailCoachCarouselContext } from "~src/contexts";
import { EmailAnalysis, NetworkStatus, viewState } from "~src/redux/typings";
import {
  emailAction,
  fetchEmailAnalysis,
  mainPanelAction,
  selectUserAccountEmail,
  store,
} from "~src/redux";
import {
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
} from "~src/utils/segmentEvent";

import { OutlookMenuDropdown } from "./menu/OutlookMenuDropdown";

export const Menu = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    ({ mainPanel }) => mainPanel[lvIdentifier].isOpen
  );
  const userAccountEmail = useAppSelector((state) =>
    selectUserAccountEmail(state)
  );
  const hasSmeRouting = useFeatureFlag("sme_router");
  const canToggleAnalytics = useFeatureFlag("track_toggle");
  const menuRef = useRef(null);
  const view = useAppSelector(({ mainPanel }) => mainPanel[lvIdentifier].view);
  const {
    currentPersonalization: {
      cart: {
        events: { items: eventItems },
      },
    },
  } = usePersonalization();

  // Gather analytics data from Email Coach
  const emailCoachData = useAppSelector(
    (state) => state.emailAnalysis[lvIdentifier] as unknown as EmailAnalysis
  );

  /* Kick off analysis from beginning and start listening for changes */
  // INFO: fetch data from backend, only when the user has stopped typing in the email body
  const emailData = useAppSelector((state) => state.emailData[lvIdentifier]);

  // INFO: Handles destroyed bodies
  const hasBodyPaused = emailData?.hasBodyPaused || false;
  const body = emailData?.body || "";
  // const pendingFields = emailData?.pendingFields;
  const hasSubjectPaused = emailData?.hasSubjectPaused || false;
  const subject = emailData?.subject || "";
  const stageOverwrite = emailData?.stageOverwrite || "";
  const scoringModel = emailData?.scoringModelOverwrite || false;

  // INFO: we need to the get the text from the html body without the hightlighted HTML tags and styles to compare with
  // the previous body
  const stringBody = getStringFromEmailBody(body);
  const bodyRef = useRef("");
  const subjectRef = useRef("");
  const stageOverwriteRef = useRef("");
  const scoringModelRef = useRef("");
  const writingData = getWritingData(lvIdentifier);
  const bodyLength: number = getBodyLength(writingData.body);
  const state = store.getState();
  const platform = state.config.settings?.platform;

  useEffect(() => {
    if (platform === "lavenderAnywhereIframe") {
      if (window.email_data) {
        //how to force reload the email
        // dispatch(emailAction.deleteEmailData(lvIdentifier));
        showPanel(undefined, "emailCoach");
      } else {
        showPanel(undefined, "startMyEmail");
      }
    }
  }, []);

  useEffect(() => {
    // INFO: check if the body or subject has changed
    if (emailCoachData?.status === NetworkStatus.loading) return;
    if (
      ((bodyRef.current !== stringBody && hasBodyPaused) ||
        (hasSubjectPaused && subjectRef.current !== subject) ||
        scoringModelRef.current !== scoringModel ||
        stageOverwriteRef.current !== stageOverwrite) &&
      isOpen
    ) {
      // INFO: Due to async nature of Gmail loading a draft email on the screen, the wait time can sometimes be long
      // enough to be considered a pause in typing so we need to check if the body is long enough to be send to the
      // backend for analysis
      if (bodyLength >= MIN_EMAIL_BODY_WORD_COUNT) {
        void dispatch(
          fetchEmailAnalysis({
            lvIdentifier,
            systemData: getSystemsData(lvIdentifier),
            writingData: getWritingData(lvIdentifier),
          })
        );
      }
      // INFO: update the bodyRef with the new body and subjectRef with new subject to avoid infinite loop
      bodyRef.current = getStringFromEmailBody(body) || "";
      subjectRef.current = subject || "";
      stageOverwriteRef.current = stageOverwrite || "";
      // @ts-expect-error - needs fix
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      scoringModelRef.current = scoringModel || false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasBodyPaused,
    hasSubjectPaused,
    isOpen,
    stringBody,
    lvIdentifier,
    dispatch,
    writingData,
    bodyLength,
    emailCoachData,
    stageOverwrite,
    scoringModel,
  ]);

  const postFinalAnalysis = useCallback(() => {
    // we only want to check if the body is long enough for analysis
    // we do not need to check if the body has changed, as it most likely hasn't since the last analysis
    // the user is clicking `send` and most likely hasn't changed text
    if (bodyLength < MIN_EMAIL_BODY_WORD_COUNT) {
      return;
    }

    void dispatch(
      fetchEmailAnalysis({
        lvIdentifier,
        systemData: getSystemsData(lvIdentifier),
        writingData: getWritingData(lvIdentifier),
      })
    );

    // mark email as sent via pixel
    void networkRequest.post(URLS.pixelSent2, { pixel_id: lvIdentifier });
  }, [bodyLength, dispatch, lvIdentifier]);

  // add listener to send button to do a final analysis on email send
  useEffect(() => {
    const state = store.getState();
    const sendButtonSelector = state.config.settings?.selectors?.sendButton;
    if (!sendButtonSelector) {
      return;
    }

    const sendButtons = document.querySelectorAll(sendButtonSelector);
    if (sendButtons.length < 1) {
      return;
    }

    sendButtons.forEach((sendButton) => {
      sendButton.addEventListener("click", postFinalAnalysis);
    });

    return () => {
      sendButtons.forEach((sendButton) => {
        sendButton.removeEventListener("click", postFinalAnalysis);
      });
    };
  }, [postFinalAnalysis]);

  useEffect(() => {
    const state = store.getState();
    const platform = state.config.settings?.platform;
    if (platform === "lavenderAnywhereIframe") {
      if (window.email_data) {
        //how to force reload the email
        // dispatch(emailAction.deleteEmailData(lvIdentifier));
        showPanel(undefined, "emailCoach");
      } else {
        showPanel(undefined, "startMyEmail");
      }
    }
  }, []);

  const [isAnimating, setAnimating] = useState(false);
  const showPanel = useCallback(
    (e: React.MouseEvent<HTMLElement> | undefined, newView: viewState) => {
      if (isAnimating) {
        //can't select another panel until this one finished loading
        return;
      }
      setAnimating(true);
      e?.stopPropagation();
      dispatch(mainPanelAction.setView({ lvIdentifier, view: newView }));
      if (isOpen !== "open") {
        dispatch(mainPanelAction.open(lvIdentifier));
      } else if (view === newView) {
        dispatch(mainPanelAction.close(lvIdentifier));
      }
      const timeoutId = setTimeout(() => {
        setAnimating(false);
      }, 420);

      const VIEW_STATE_TO_SEGMENT_NAME: Record<
        Exclude<viewState, "default" | "intercom">,
        SegmentEventNames
      > = {
        emailCoach:
          view === newView
            ? SegmentEventNames.EmailCoachClickedClosed
            : SegmentEventNames.EmailCoachClickedOpen,
        chatGPT: SegmentEventNames.ChatGPTClicked,
        settings: SegmentEventNames.SettingsClicked,
        frameworks: SegmentEventNames.FrameworksClicked,
        gif: SegmentEventNames.GifsClicked,
        startMyEmail: SegmentEventNames.StartMyEmailClicked,
        mobilePreview: SegmentEventNames.MobilePhoneClicked,
        profile: SegmentEventNames.ProfileClicked,
      };

      segmentEvent(
        VIEW_STATE_TO_SEGMENT_NAME[newView],
        SegmentEventLocations.ExtensionMainMenu,
        {
          action: view === newView ? "close" : "open",
        }
      );
      //gmail replies fix. Resize triggers rebuilding.
      if (platform === "gmail") {
        window.dispatchEvent(new Event("resize"));
      }
      return () => {
        clearTimeout(timeoutId);
      };
    },
    [isAnimating, dispatch, lvIdentifier, isOpen, view, platform]
  );

  const { settings, status } = useAppSelector(({ userAccount }) => userAccount);
  useEffect(() => {
    settings.user === undefined &&
      status !== NetworkStatus.loading &&
      showPanel(undefined, "settings");
  }, [settings.user, showPanel, status]);

  const hasEnoughBody = bodyLength >= MIN_EMAIL_BODY_WORD_COUNT;

  const { view: emailCoachView, prev: prevEmailCoach } =
    useEmailCoachCarouselContext();

  const chatGPTMouseEnterEvent = () => {
    segmentEvent(
      SegmentEventNames.ChatGPTHovered,
      SegmentEventLocations.ChatGPT
    );
  };
  const [isWindowSmall, setIsWindowSmall] = useState(
    platform === "outlookNative" ? false : window.innerWidth < 750
  );

  useEffect(() => {
    if (platform === "outlookNative") {
      return;
    }
    const handleResize = () => {
      setIsWindowSmall(window.innerWidth < 750);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleIncludeInAnalytics = useCallback(
    (checked: boolean) => {
      void dispatch(
        emailAction.toggleAnalytics({
          id: lvIdentifier,
          includeInAnalytics: checked,
        })
      );
    },
    [dispatch, lvIdentifier]
  );

  let smeCount = 0;
  if (hasSmeRouting) {
    smeCount =
      view !== "profile"
        ? 0
        : eventItems.filter((item) => item.showCart === true).length;
  }

  let tooltipPlacement: TooltipPlacement = "right";
  if (platform === "outlookNative") {
    tooltipPlacement = "bottom";
  }

  const emailCoachBadgeClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (view === "emailCoach" && emailCoachView === EmailCoachView.fixItAll) {
        prevEmailCoach();
        segmentEvent(
          SegmentEventNames.EmailCoachClickedOpen,
          SegmentEventLocations.ExtensionMainMenu
        );
      } else {
        showPanel(e, "emailCoach");
      }
    },
    [emailCoachView, prevEmailCoach, showPanel, view]
  );

  return (
    <div className="lv-menu" ref={menuRef}>
      <Flex
        vertical={true}
        justify="space-between"
        align="center"
        style={{
          height: "96%",
          maxWidth: "calc(var(--lv-menu-size) - 21px)",
          margin: "10px",
        }}
      >
        <section>
          {/*TODO: if window is < 500px alert icon  with tooltip saying expand for better experience*/}

          {isWindowSmall ? (
            <Tooltip
              hideArrow
              title="Expand the window for a better experience"
              placement={"right"}
            >
              <Icon
                size={"large"}
                color={"warning"}
                variant={LavenderIcon.IconAlertTriangleFilled}
              />
            </Tooltip>
          ) : (
            <Tooltip hideArrow placement="right" title="Lavender.AI">
              <LavenderLogo size={36} />
            </Tooltip>
          )}
          <hr className="lv-menu-divider" />
          {/*{user !== undefined && (*/}
          {/*  <FlexMenuItem>*/}
          {/*    <RecipientMenuGroup />*/}
          {/*  </FlexMenuItem>*/}
          {/*)}*/}
          <FlexMenuItem>
            <EmailBadge count={smeCount}>
              <Tooltip
                placement="left"
                title={smeCount > 0 ? "Added to Start My Email" : ""}
                open={smeCount > 0}
              >
                {/*todo: if is open*/}
                <StartMyEmailMenuButton showPanel={showPanel} />
              </Tooltip>
            </EmailBadge>
          </FlexMenuItem>
          <FlexMenuItem>
            <Tooltip hideArrow placement={tooltipPlacement} title="Email Coach">
              {/*todo: if is open*/}
              {(settings.user === undefined ||
                (emailCoachData?.status === NetworkStatus.idle &&
                  !hasEnoughBody) ||
                emailCoachData?.status === NetworkStatus.failed) && (
                <Button
                  onClick={emailCoachBadgeClick}
                  radius="md"
                  size="large"
                  state={
                    view === "emailCoach" && isOpen === "open"
                      ? "focus"
                      : "initial"
                  }
                  icon={
                    view === "emailCoach" &&
                    isOpen === "open" &&
                    platform !== "outlookNative"
                      ? LavenderIcon.IconChevronsRight
                      : LavenderIcon.IconBeaker
                  }
                  variant="plain"
                />
              )}
              {((emailCoachData?.status === NetworkStatus.idle &&
                hasEnoughBody) ||
                emailCoachData?.status === NetworkStatus.loading) && (
                <Flex onClick={emailCoachBadgeClick}>
                  <Spinner width={40} height={40} />
                </Flex>
              )}
              {emailCoachData?.status === NetworkStatus.success && (
                <ScoreBadge onClick={emailCoachBadgeClick}>
                  <EmailScore
                    width={40}
                    height={40}
                    score={emailCoachData?.analysis?.dynamic_ceq || 0}
                  />
                  <Text color="black" size="subtext3">
                    {emailCoachData?.analysis?.dynamic_ceq < 50
                      ? "!"
                      : emailCoachData?.analysis?.dynamic_ceq}
                  </Text>
                </ScoreBadge>
              )}
            </Tooltip>
          </FlexMenuItem>
          {/*todo: if is open*/}
          <RecipientMenuGroup />
          {!userAccountEmail.endsWith("ibm.com") && (
            <FlexMenuItem>
              {/*todo: if is open*/}
              <Tooltip hideArrow placement={tooltipPlacement} title="ChatGPT">
                <div onMouseEnter={chatGPTMouseEnterEvent}>
                  <Button
                    aria-label="ChatGPT"
                    onClick={(e) => {
                      showPanel(e, "chatGPT");
                    }}
                    radius="md"
                    size="large"
                    state={
                      view === "chatGPT" && isOpen === "open"
                        ? "focus"
                        : "initial"
                    }
                    icon={
                      view === "chatGPT" &&
                      isOpen === "open" &&
                      platform !== "outlookNative"
                        ? LavenderIcon.IconChevronsRight
                        : LavenderIcon.IconBrandOpenAI
                    }
                    variant="plain"
                  />
                </div>
              </Tooltip>
            </FlexMenuItem>
          )}
          <MobileDeviceMenu />
        </section>
        {platform === "outlookNative" ? (
          <OutlookMenuDropdown showPanel={showPanel} />
        ) : (
          <section>
            <MobileDeviceMenu />
            <FlexMenuItem>
              <Tooltip
                hideArrow
                placement={tooltipPlacement}
                title="Frameworks"
              >
                <Button
                  aria-label="Frameworks"
                  icon={
                    view === "frameworks" &&
                    isOpen === "open" &&
                    platform !== "outlookNative"
                      ? LavenderIcon.IconChevronsRight
                      : "IconBox"
                  }
                  radius="md"
                  size="large"
                  state={
                    view === "frameworks" && isOpen === "open"
                      ? "focus"
                      : "initial"
                  }
                  onClick={(e) => {
                    showPanel(e, "frameworks");
                  }}
                  variant="plain"
                />
              </Tooltip>
            </FlexMenuItem>
            <FlexMenuItem>
              <Tooltip hideArrow placement={tooltipPlacement} title="GIFs">
                <Button
                  aria-label="GIFs"
                  onClick={(e) => {
                    showPanel(e, "gif");
                  }}
                  radius="md"
                  size="large"
                  state={
                    view === "gif" && isOpen === "open" ? "focus" : "initial"
                  }
                  icon={
                    view === "gif" && isOpen === "open"
                      ? LavenderIcon.IconChevronsRight
                      : LavenderIcon.IconGif
                  }
                  variant="plain"
                />
              </Tooltip>
            </FlexMenuItem>
            <hr className="lv-menu-divider" />
            <FlexMenuItem>
              <Tooltip hideArrow placement={tooltipPlacement} title="Settings">
                <Button
                  aria-label="Settings"
                  onClick={(e) => {
                    showPanel(e, "settings");
                  }}
                  radius="md"
                  size="large"
                  state={
                    view === "settings" && isOpen === "open"
                      ? "focus"
                      : "initial"
                  }
                  icon={
                    view === "settings" && isOpen === "open"
                      ? LavenderIcon.IconChevronsRight
                      : LavenderIcon.IconSettings
                  }
                  variant="plain"
                />
              </Tooltip>
            </FlexMenuItem>
            {canToggleAnalytics && (
              <Tooltip
                hideArrow
                placement="right"
                title={
                  <>
                    <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Toggle Analytics
                    </div>
                    <div style={{ fontSize: "12px" }}>
                      Toggle if this email should be included in your Lavender
                      reporting & analytics.
                    </div>
                  </>
                }
              >
                <Switch
                  size="small"
                  defaultChecked
                  checked={emailData?.includeInAnalytics ?? true}
                  onChange={toggleIncludeInAnalytics}
                />
              </Tooltip>
            )}
          </section>
        )}
      </Flex>
    </div>
  );
};

const getStringFromEmailBody = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent;
};

const FlexMenuItem = styled(Flex)`
  height: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
`;
const EmailBadge = styled(BadgeWrapper)`
  clear: both;
  .ant-badge-count {
    transform: translate(15%, -15%) !important;
    background: var(--color-pear-300, #caf344);
    span {
      color: var(--color-primary-lavender, #6a2be9);
      font-weight: 600;
    }
  }
`;
const ScoreBadge = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  > span {
    ${CENTERED_SVG_MIXIN}
  }
`;
