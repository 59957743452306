import { PaywallModal } from "~src/component/Molecules";
import { useEmailCoachCarouselContext } from "~src/contexts";
import { useLvIdentifier, useShouldDisplayPaywall } from "~src/customHooks";
import { useAppSelector } from "~src/redux";

import { EmailCoach, EmailCoachCarousel } from "./components";
import { HighlightContextProvider } from "./contexts";

export const EmailCoachPanel = () => {
  const lvIdentifier = useLvIdentifier();

  // INFO: get data from backend
  const analyticsData = useAppSelector((state) => state.emailAnalysis[lvIdentifier]);
  const emailWritingData = useAppSelector((state) => state.emailData[lvIdentifier]);

  const { view } = useEmailCoachCarouselContext();

  return (
    <HighlightContextProvider>
      <EmailCoachCarousel>
        {useShouldDisplayPaywall("emailCoach") && <PaywallModal view="emailCoach" />}
        <EmailCoach view={view} analyticsData={analyticsData} emailWritingData={emailWritingData} />
      </EmailCoachCarousel>
    </HighlightContextProvider>
  );
};
