import { FormInstance, Form } from "antd";

import { CustomCategories, ValueProps } from "~src/redux/typings";

export interface FieldType {
  categories: ValueProps;
}

interface Props {
  children: React.ReactNode;
  form: FormInstance<CustomCategories>;
  submitForm: (values: CustomCategories) => void;
}

export const CustomFieldForm = ({ children, form, submitForm }: Props) => {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={submitForm}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "100%",
      }}
    >
      {children}
    </Form>
  );
};
