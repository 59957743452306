import { Flex } from "antd";
import { memo, useMemo } from "react";
import styled from "styled-components";

interface Props {
  items: string[];
}

const StyledFlex = styled(Flex)`
  text-align: left;
  padding-bottom: 20px;
  padding-top: 2px;
  padding-inline-start: 20px;
  margin-top: 16px;
  margin-bottom: 0;
`;

const UlListInternal = ({ items }: Props) => {
  const listItems = useMemo(
    () => items.map((item: string) => <li key={item}>{item}</li>),
    [items]
  );

  return (
    <StyledFlex component="ul" gap={16} vertical>
      {listItems}
    </StyledFlex>
  );
};

export const UlList = memo(UlListInternal);
