import type {
  AdditionalCompanyTechItem,
  CommunicationAdviceResponse,
  CompanyBase,
  CompanyInvestment,
  HumanticStatus,
  JobEntityAttribute,
  PersonalizationTrigger,
  RankedFeedSection,
  RankedFeedType,
  RecentActivity,
  SingleNote,
  RankedFeedEmptyInfo,
  RankedFeedEntity,
} from "~src/api/typings";
import {
  Job,
  Person,
  PersonBase,
  SocialMedia,
  WorkHistory,
} from "./userAccount";
import { CallOutlineItem, Collaboration } from "./gong";

export interface SetLoadingArgs {
  email: emailAddress;
}

export type RecipientPersonalizationMap = Record<
  emailAddress,
  IPersonalization | undefined
>;

export interface PersonalizationData {
  toAddresses: emailAddress[];
  personalizations: RecipientPersonalizationMap;
}

export interface LoadPersonalizationsPayload {
  toAddresses: string[];
}

export interface Party {
  name: string;
}

export interface Step {
  text: string;
}
export interface GongData {
  metaData: {
    id: string;
    title: string;
    url: string;
    started: string;
    duration: number;
  };
  content: {
    brief: string;
    outline: CallOutlineItem[];
  };
  collaboration: Collaboration;
  // TODO: remove checks below we can verify all calls have parties, current api responses do not
  parties: Party[] | undefined;
  nextSteps: Step[] | undefined;
}

export interface IPersonalization {
  profile: PersonalizationProfileData;
  recent_activity: RecentActivity;
  company: PersonalizationCompanyData;
  notes: SingleNote[];
  cart: PersonalizationRankedData;
}

export interface PersonalizationProfileData extends Required<PersonBase> {
  avatar: string;
  bio: string;
  education: string;
  email: string;
  job: Required<Job>;
  loading: boolean;
  name: string;
  social_medias?: SocialMedia;
  location: string;
  timezone: string;
  workHistory: WorkHistory[];
}

export interface PersonalizationCompanyData extends Required<CompanyBase> {
  employeesRange: string;
  name: string;
  raised: number;
  industries: string[];
  investments: CompanyInvestment[];
  investors: Map<string, string> | EmptyObject;
  social_medias: Required<SocialMedia>;
  jobs: PersonalizationCompanyDataJobs;
}

export interface PersonalizationProfilePersonalityData {
  loading: boolean;
  sorter_segment: Person["sorter_segment"];
  communication_advice: CommunicationAdviceData;
  disc_assessment: DiscAssessmentCategorizedData;
  ocean_assessment: OceanAssessmentCategorizedData;
  status: HumanticStatus;
}

type CommunicationAdviceData = Pick<
  CommunicationAdviceResponse,
  "description" | "adjectives" | "what_to_avoid" | "what_to_say"
>;

export interface DiscAssessmentCategorizedData {
  calculativeness: string;
  dominance: string;
  influence: string;
  steadiness: string;
}

export interface OceanAssessmentCategorizedData {
  agreeableness: string;
  openness: string;
  extraversion: string;
  emotional_stability: string;
  conscientiousness: string;
}

export interface PersonalizationCompanyDataJobs
  extends Omit<AddJobsToPersonalizationsData, "email"> {
  loading: boolean;
}

export interface PersonalizationCompanyDataTech {
  tech?: AdditionalCompanyTechItem[];
  aggregate: PersonalizationTechAggregateData[];
  loading: boolean;
}

export interface PersonalizationEvent {
  // INFO: keeping categories because our current news triggers are more like categories. They are not personalized
  // to a user. Down the road, we may want to redefine triggers as a subset of categories or distinct all together.
  categories?: string[];
  formattedTitle: string;
  triggers: PersonalizationTrigger[];
  score: number;
  type: RankedFeedType;
  date?: string;
  id: string;
  title: string;
  source?: string;
  summary: string;
  url?: string;
  showCart?: boolean;
}

export interface PersonalizationExperience
  extends Omit<PersonalizationEvent, "type">,
    RankedFeedEntity<WorkHistory> {
  score: number;
  type: RankedFeedType.work_history;
}

export interface PersonalizationTechStack
  extends Omit<PersonalizationEvent, "type"> {
  type: RankedFeedType.tech_stack;
}

export interface PersonalizationJobOpening
  extends Omit<PersonalizationEvent, "type"> {
  type: RankedFeedType.job_openings;
}

export interface PersonalizationRankedData {
  [RankedFeedType.events]: RankedFeedSection<PersonalizationEvent>;
  [RankedFeedType.funding]: RankedFeedSection<PersonalizationEvent>;
  [RankedFeedType.job_openings]: RankedFeedSection<PersonalizationJobOpening>;
  [RankedFeedType.tech_stack]: RankedFeedSection<PersonalizationTechStack>;
  [RankedFeedType.work_history]: RankedFeedSection<PersonalizationExperience>;
}

export interface AddDataToPersonalizationsArgs {
  email: emailAddress;
  page?: number;
}

export interface JobEntityAttributeData
  extends Omit<JobEntityAttribute, "categories"> {
  category: string;
  categories: string[];
}

export interface AddJobsToPersonalizationsData {
  aggregate: PersonalizationJobAggregateData[];
  jobs: JobEntityAttributeData[] | undefined;
}

export interface PersonalizationJobAggregateData {
  breakdown: Record<string, number>;
  key: string;
  category: string;
  percent: string;
  roles: number;
}

export interface PersonalizationTechAggregateData {
  category: string; // title-cased keyof TechAggregateData
  tech: Tech[];
}

export interface Tech {
  description: string;
  title: string;
  url: string;
  type: string;
}

export interface AddPersonalityToPersonalizationsData {
  personality: PersonalizationProfilePersonalityData;
}

export interface IcebreakersData extends Required<RankedFeedEmptyInfo> {
  rankedFeed: PersonalizationRankedData;
}

export interface AddTechToPersonalizationsData {
  aggregate: PersonalizationTechAggregateData[];
}
