import { XOR } from "ts-xor";

import { ErrorResponse } from "./api";

export interface SuccessResponse {
  ranked_data: AdditionalCompanyCategorized;
  data: AdditionalCompanyCategorized;
  jobs_aggregate: JobsAggregateData | null;
  tech_aggregate: TechAggregateData | null;
  success: string;
}

//INFO: ErrorResppnse if email is a bad domain - shouldn't happen since this request is after email verification
export type Response = SuccessResponse | ErrorResponse;

export interface AdditionalCompanyCategorized {
  events: AdditionalCompanyEvents;
  jobs: AdditionalCompanyJobs;
}

interface BaseItem {
  id: string;
}

export interface AdditionalCompanyEventItem extends BaseItem {
  attributes: FeedEntityAttribute;
  type: "event";
  relationships: object;
}

export interface AdditionalCompanyJobItem extends BaseItem {
  attributes: JobEntityAttribute;
  type: "job_opening";
  relationships: object;
}

export type FeedEntityAttribute = XOR<
  TechEntityAttribute,
  EventEntityAttribute
>;

export interface EventEntityAttribute extends BaseEntityAttribute {
  additional_data: FeedEntityAdditionalData;
  formattedTitle: string;
  found_at: string;
}

export interface TechEntityAttribute extends BaseEntityAttribute {
  domain: string;
  first_seen_at: string;
  last_seen_at: string;
  behind_firewall?: boolean;
  description?: string;
}

export interface BaseEntityAttribute {
  categories: string[] | null;
  title: string;
  url: string;
}

interface FeedEntityAdditionalData {
  article_author: string;
  article_body: string;
  article_published_at: string;
  article_sentence: string;
  article_source: string;
  article_title: string;
  confidence: string;
  date: string;
  location: string;
  location_data: LocationData; // all values empty string
}

export interface LocationData {
  country: string;
  city: string;
  state: string;
  fuzzy_match: boolean;
}

export interface AdditionalCompanyTechItem extends BaseItem {
  attributes: TechEntityAttribute;
  type: "technology";
}

export interface JobEntityAttribute extends BaseEntityAttribute {
  additional_data: {
    job_title_seniority: JobTitleSeniority;
    tags: string[] | null;
    location_data: LocationData;
  };
  contract_types: string[];
  description: string;
  first_seen_at: string;
  last_seen_at: string;
  job_opening_closed: boolean;
  last_processed_at: string;
  location: string;
  salary: string;
  salary_data: object;
}

export enum JobTitleSeniority {
  Owner = "owner",
  Founder = "founder",
  CLevel = "c_level",
  Partner = "partner",
  President = "president",
  Director = "director",
  Manager = "manager",
  NonManager = "non_manager",
  Head = "head",
  VicePresident = "vice_president",
}

export interface JobsAggregateData {
  engineering: JobsAggregateDataAttribute;
  finance: JobsAggregateDataAttribute;
  human_resources: JobsAggregateDataAttribute;
  design: JobsAggregateDataAttribute;
  marketing: JobsAggregateDataAttribute;
  operations: JobsAggregateDataAttribute;
  sales: JobsAggregateDataAttribute;
  support: JobsAggregateDataAttribute;
}

export interface TechAggregateData {
  accounting_and_finance: TechAggregateDataAttribute[];
  analytics: TechAggregateDataAttribute[];
  builtwith: TechAggregateDataAttribute[];
  cloud: TechAggregateDataAttribute[];
  design: TechAggregateDataAttribute[];
  email: TechAggregateDataAttribute[];
  frameworks: TechAggregateDataAttribute[];
  mobile: TechAggregateDataAttribute[];
  sales: TechAggregateDataAttribute[];
  security: TechAggregateDataAttribute[];
}

interface AdditionalCompanyBase {
  bson_id: string;
  domain?: string;
  included: object[] | null;
  last_updated: string;
}

interface AdditionalCompanyJobs extends AdditionalCompanyBase {
  data: AdditionalCompanyJobItem[] | null;
  meta: AdditionalCompanyMetaBase & { total_jobs_count: number };
}

interface AdditionalCompanyEvents extends AdditionalCompanyBase {
  data: AdditionalCompanyEventItem[] | null;
  meta: AdditionalCompanyMetaBase;
}

interface AdditionalCompanyMetaBase {
  count: number;
  message?: string;
  message_type?: string;
}

// INFO: https://docs.predictleads.com/#job-opening-seniority
export interface JobsAggregateDataAttribute {
  [JobTitleSeniority.Manager]?: number;
  [JobTitleSeniority.NonManager]?: number;
  [JobTitleSeniority.Head]?: number;
  [JobTitleSeniority.VicePresident]?: number;
  [JobTitleSeniority.Owner]?: number;
  [JobTitleSeniority.Founder]?: number;
  [JobTitleSeniority.CLevel]?: number;
  [JobTitleSeniority.Partner]?: number;
  [JobTitleSeniority.Director]?: number;
  [JobTitleSeniority.President]?: number;
}

export interface TechAggregateDataAttribute {
  attributes: TechAggregateDataAttributeAttributes;
  id: string;
  type: string;
}

interface TechAggregateDataAttributeAttributes {
  categories: null;
  domain: string;
  first_seen_at: string;
  last_seen_at: string;
  url: string;
  title: string;
  behind_firewall: boolean;
  description: string;
}
