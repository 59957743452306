import { Flex, Space } from "antd";

import { Avatar, Button, Icon, Tag, Text } from "@lavender-ai/lav-components";

import { prettyDate } from "~src/utils";
import { JobEntityAttributeData } from "~src/redux/typings";
import { usePersonalization } from "~src/customHooks";
import { getDomainUrl } from "~src/utils/email";
import { SecondaryText } from "../../../Styled";
import { NavigationWrapper } from "../Views/NavigationWrapper";
import { useCarouselContext } from "../CarouselContextProvider";

export const JobsModal = () => {
  const { data } = useCarouselContext();
  const job: JobEntityAttributeData = data;
  const {
    currentPersonalization: { company },
  } = usePersonalization();

  //todo: change backend and redux/reducers to get url & summary
  const domain = getDomainUrl(job.url);
  return (
    <NavigationWrapper>
      <br />
      <Space
        size={20}
        style={{
          background: "white",
          margin: "0 20px 20px 20px",
          borderRadius: "var(--radius-lrg, 16px)",
          padding: 20,
          textAlign: "left",
        }}
        direction={"vertical"}
      >
        <Avatar
          size="large"
          src={company.logo}
          style={{
            border: "1px solid var(--color-neutral-purple-200, #E1E0E4)",
          }}
        />
        <SecondaryText $light size={"body1"}>
          {data.first_seen_at && prettyDate(job?.first_seen_at)}
        </SecondaryText>
        <Text size={"subtext4"}>{job?.title}</Text>
        <div
          style={{
            maxHeight: 240,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Space size={8} direction={"vertical"}>
            {job?.description?.split("\n").map((paragraph, idx) => {
              return (
                <Text key={idx} size={"body2"}>
                  {paragraph}
                </Text>
              );
            })}
          </Space>
        </div>
        <Space direction={"vertical"} size={8} style={{ width: "100%" }}>
          <Button
            size="medium"
            variant={"default"}
            fullWidth={true}
            href={job?.url}
            target="_blank"
          >
            <Flex justify="center" align="center">
              {domain} &nbsp;
              <Icon variant={"IconArrowUpRight"} size="extra-small" />
            </Flex>
          </Button>
        </Space>
        {job?.categories?.length > 0 && (
          <Space size={8} direction={"vertical"}>
            <Text size="subtext2">Categories</Text>
            <Flex
              style={{
                display: "flex",
                alignItems: "flex-start",
                alignContent: "flex-start",
                gap: 8,
                flexWrap: "wrap",
              }}
            >
              {job?.categories?.map((category, idx) => (
                <Tag size={"small"} key={idx} color={"lavender"}>
                  {category}
                </Tag>
              ))}
            </Flex>
          </Space>
        )}
        {job?.additional_data?.tags && (
          <Space size={8} direction={"vertical"}>
            <Text size="subtext2">Tags</Text>
            <Flex
              style={{
                display: "flex",
                alignItems: "flex-start",
                alignContent: "flex-start",
                gap: 6,
                flexWrap: "wrap",
              }}
            >
              {job?.additional_data?.tags.map((tag, idx) => (
                <Tag size={"small"} key={idx} color={"gray"}>
                  {tag}
                </Tag>
              ))}
            </Flex>
          </Space>
        )}
      </Space>
    </NavigationWrapper>
  );
};
