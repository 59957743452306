import { createSlice } from "@reduxjs/toolkit";

const initialState: Array<string> = [];

const highlightsSlice = createSlice({
  name: "selectedRecommendation",
  initialState,
  reducers: {
    setHighlights: (
      state,
      {
        payload: { lvIdentifier, highlights, highlightClass, recommendationId },
      }: {
        payload: {
          lvIdentifier: lvIdentifier;
          highlights: Array<string>;
          highlightClass?: string;
          recommendationId?: string;
        };
      }
    ) => {
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          highlights,
          highlightClass,
          recommendationId,
        },
      };
    },
  },
});

export const highlightsAction = highlightsSlice.actions;
export const highlightsReducer = highlightsSlice.reducer;
