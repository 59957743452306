import { FC } from "react";

import { FRONTEND_URLS } from "~src/constants";
import { useAppSelector } from "~src/redux/hooks";

export const StripeIframe: FC = () => {
  const chromeId = useAppSelector((state) => state.config.chromeId);
  const user = useAppSelector((state) => state.userAccount.settings.user);
  if (!user) return null;

  return (
    <iframe
      src={`${FRONTEND_URLS.dashboard}/extension-plans-and-billing?chromeId=${chromeId}&billingPlan=${user.billing_plan}`}
      style={{
        width: "100%",
        height: "180px",
        border: "none",
      }}
      referrerPolicy="no-referrer"
    />
  );
};
