import { useRef, useEffect } from "react";
import { Flex } from "antd";

import { useChat } from "~src/customHooks";
import { AssistantMessage } from "~src/component/Molecules/Message/AssistantMessage/AssistantMessage";
import { LavenderMessage } from "~src/component/Molecules/Message/LavenderMessage/LavenderMessage";
import { UserMessage } from "~src/component/Molecules/Message/UserMessage/UserMessage";

import { ChatMessage, ChatRole } from "~src/api/typings/askChat";
import { CHAT_GPT_ERRORS, CHAT_GPT_PANEL } from "~src/strings";
import { NetworkStatus, Recommendations } from "~src/redux/typings";
import { AssistantAvatar } from "~src/component/Atoms";
import { CopyButton } from "~src/component/Molecules";

const { FALLBACK_ASK_CHAT, FALLBACK_ANALYZE_CHAT } = CHAT_GPT_ERRORS;
const { ANALYZING_AND_SCORING } = CHAT_GPT_PANEL;

interface Props {
  onClick: (text: string) => void;
  onFixAllClick: (text: string, content: string, recs: Recommendations) => void;
}

export const Conversation = ({ onClick, onFixAllClick }: Props) => {
  const {
    chat: { messages, status, score, recs, error },
    user: { avatar, displayName, email },
  } = useChat();
  const lastMessage = messages[messages.length - 1];
  const userMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastMessage.role === ChatRole.Assistant && userMessageRef.current) {
      userMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [lastMessage]);

  return (
    <Flex
      align="flex-start"
      gap={24}
      style={{
        alignSelf: "stretch",
        padding: "24px 16px",
        textAlign: "left",
        // INFO: this is to stop the component from shrinking when the panel is collapsed
        width: "var(--lv-expanded-panel-size)",
      }}
      vertical
    >
      {messages.map(({ role, content, query }: ChatMessage, idx: number) => (
        <div key={idx} ref={role === ChatRole.User ? userMessageRef : null}>
          {role === ChatRole.User && (
            <UserMessage
              avatar={avatar}
              name={displayName || email}
              content={content}
            />
          )}
          {role === ChatRole.Assistant && (
            <AssistantMessage
              actions={
                content
                  ? [<CopyButton key="copy" copyText={content} />]
                  : undefined
              }
              avatar={<AssistantAvatar size="small" />}
              content={content ? content.replace(/\n/g, "<br>") : ""}
              query={query}
            />
          )}
        </div>
      ))}
      {status === NetworkStatus.loading &&
        lastMessage.role === ChatRole.User && (
          <AssistantMessage
            avatar={<AssistantAvatar size="small" status={status} />}
            content="..."
            status={status}
          />
        )}
      {status === NetworkStatus.loading &&
        lastMessage.role === ChatRole.Assistant && (
          <LavenderMessage
            content={ANALYZING_AND_SCORING}
            status={status}
            onClick={onClick}
            onFixAllClick={onFixAllClick}
          />
        )}
      {status === NetworkStatus.success &&
        score &&
        lastMessage.role === ChatRole.Assistant && (
          <LavenderMessage
            content={lastMessage.content}
            score={score}
            recs={recs}
            status={status}
            onClick={onClick}
            onFixAllClick={onFixAllClick}
          />
        )}
      {status === NetworkStatus.failed &&
        lastMessage.role === ChatRole.Assistant && (
          <LavenderMessage
            content={error || FALLBACK_ANALYZE_CHAT}
            status={status}
            onClick={onClick}
            onFixAllClick={onFixAllClick}
          />
        )}
      {status === NetworkStatus.failed &&
        lastMessage.role === ChatRole.User && (
          <AssistantMessage
            avatar={<AssistantAvatar size="small" status={status} />}
            content={error || FALLBACK_ASK_CHAT}
            status={status}
          />
        )}
    </Flex>
  );
};
