import { Flex } from "antd";
import { Button, PaywallBanner, Text } from "@lavender-ai/lav-components";

import { FRONTEND_URLS } from "~src/constants";

export const WatchPaywall = () => (
  <PaywallBanner
    variant="pear"
    style={{
      gap: 10,
      flexDirection: "column",
      padding: 8,
      alignItems: "start",
    }}
  >
    <Flex justify="center" align="center">
      <Text size="subtext2">Unlock more personalized data, in an instant!</Text>
    </Flex>
    <Button
      prefix="IconLockOpen"
      prefixColor="white"
      fullWidth={false}
      size="small"
      variant="primary"
      onClick={() => {
        window.open(
          `${FRONTEND_URLS.dashboard}/app/settings/billing`,
          "_blank"
        );
      }}
    >
      <div style={{ marginLeft: -4 }}>Upgrade</div>
    </Button>
  </PaywallBanner>
);
