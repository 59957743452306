import { useEffect, useState } from "react";
import { Flex } from "antd";

import { ChatGPTPanel } from "./ChatGPTPanel";
import { EmailCoachPanel } from "./EmailCoachPanel/EmailCoachPanel";
import { GifPanel } from "./GifPanel";
import { PersonalizationPanel } from "./PersonalizationPanel";
import { StartMyEmailPanel } from "./StartMyEmailPanel";

import { SlideContainer } from "../../component/Atoms/SlideContainer";
import {
  useAuthentication,
  useLvIdentifier,
  usePersonalization,
} from "../../customHooks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FrameworksPanel } from "./FrameworksPanel";
import { SettingsCarousel, IntercomPanel } from "./SettingsCarousel";
import { handlePanelAnimation, isFeaturePayWalled } from "./utils";
import { fixFooterWidth } from "~src/platform/gmail";
import {
  addCompanyJobs,
  loadCRM,
  loadNews,
  loadPersonalizations,
  loadTechStack,
  loadTweets,
} from "~src/redux/feed";

export const Panel = () => {
  const {
    currentPersonalization: {
      profile: { email: currentEmail = "" },
    },
  } = usePersonalization();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAuthentication();
  const lvIdentifier = useLvIdentifier();
  const showBackGround = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].showBackGround
  );
  const isOpen = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].isOpen
  );
  const defaultClass = "lv-panel";
  const [classNames, changeClass] = useState(defaultClass);

  useEffect(() => {
    if (currentEmail.length < 1) {
      return;
    }

    async function run() {
      await dispatch(loadPersonalizations({ toAddresses: [currentEmail] }));
      await Promise.all([
        dispatch(loadCRM({ toAddresses: [currentEmail] })),
        dispatch(loadNews({ toAddresses: [currentEmail] })),
        dispatch(loadTweets({ toAddresses: [currentEmail] })),
        dispatch(loadTechStack({ toAddresses: [currentEmail] })),
        dispatch(addCompanyJobs({ email: currentEmail })),
      ]);
    }

    void run();
  }, [currentEmail, dispatch]);

  useEffect(() => {
    handlePanelAnimation(isOpen, changeClass, defaultClass);
    setTimeout(fixFooterWidth, 420);
  }, [isOpen]);

  const view = useAppSelector((state) => state.mainPanel[lvIdentifier].view);

  if (!isAuthenticated) {
    return null;
  }
  const isPayWalled = isFeaturePayWalled("Panel");
  if (isPayWalled) {
    return null;
  }

  // INFO: for initial state don't do any open or close animation just show it closed
  return (
    <div className={classNames}>
      <Flex
        className={`lv-fill-height ${
          showBackGround ? "lv-panel-background" : ""
        }`}
      >
        <SlideContainer>
          {view === "settings" && <SettingsCarousel />}
          {view === "startMyEmail" && <StartMyEmailPanel />}
          {view === "profile" && <PersonalizationPanel />}
          {view === "frameworks" && <FrameworksPanel />}
          {view === "chatGPT" && <ChatGPTPanel />}
          {view === "gif" && <GifPanel />}
          {view === "frameworks" && <FrameworksPanel />}
          {view === "intercom" && <IntercomPanel />}
          {view === "emailCoach" && <EmailCoachPanel />}
        </SlideContainer>
        {/*
          // INFO: There is some React hook issue with conditional rendering so we have to do this "view===" check instead of passing a boolean to show
            (This follows the rules of hooks https://legacy.reactjs.org/docs/hooks-rules.html old doc but it still relevant)
          */}
        {/*
          //INFO: The useEffect in this component will call a setState to update the classNames to include the lv-open-animation class which will trigger the animation
          // This will cause a second render of the component which will then render the EmailCoachCarousel component when the view is still equal to "emailCoach"
          // to prevent an extra api call we will check if the classNames includes the lv-open-animation class
          // to determine if the EmailCoachCarousel component is open to the user's view to make the api call
          // This way we can encapsulate the api call to the EmailCoachCarousel component to help with debugging and not look at the parent component,
          // which is loosely related to the EmailCoachCarousel component. This can help with other developers who are not familiar with the codebase.
        */}
      </Flex>
    </div>
  );
};
