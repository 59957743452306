import { EventsList } from "~src/component/Organisms/Events";
import { WorkHistoryList } from "~src/component/Organisms/WorkHistory/WorkHistoryList";
import { PanelListContainer } from "~src/layout/MainPanel/PanelBlock";

import { RankedFeedType, RankedItem } from "~src/api/typings";

import { PersonalizationEvent, WorkHistory } from "~src/redux/typings";
import {
  arePersonalizationEventItems,
  arePersonalizationTechStackItems,
  arePersonalizationJobOpeningsItems,
} from "~src/typeGuards";
import { areWorkHistoryItems } from "~src/typeGuards/cart";

interface BaseProps {
  isLoading?: boolean;
}
interface NewsProps extends BaseProps {
  items: PersonalizationEvent[];
}

const News = ({ isLoading, items }: NewsProps) => {
  return (
    <PanelListContainer>
      <EventsList
        dense
        events={items}
        loading={isLoading}
        showCartAction
        showTags
      />
    </PanelListContainer>
  );
};

interface ExperienceProps extends BaseProps {
  items: WorkHistory[];
}

const Experience = ({ isLoading, items }: ExperienceProps) => {
  return (
    <PanelListContainer>
      <WorkHistoryList loading={isLoading} showCartAction workHistory={items} />
    </PanelListContainer>
  );
};

export const getComponentByType = (
  type: RankedFeedType,
  items: RankedItem[],
  loading?: boolean
) => {
  switch (type) {
    case RankedFeedType.events:
    case RankedFeedType.tech_stack:
    case RankedFeedType.job_openings:
    case RankedFeedType.funding:
      return (
        <News
          key={type}
          isLoading={loading}
          items={
            !loading &&
            (arePersonalizationEventItems(items) ||
              arePersonalizationJobOpeningsItems(items) ||
              arePersonalizationTechStackItems(items))
              ? items
              : []
          }
        />
      );
    case RankedFeedType.work_history:
      return (
        <Experience
          key={type}
          isLoading={loading}
          items={!loading && areWorkHistoryItems(items) ? items : []}
        />
      );
    default:
      return null;
  }
};
