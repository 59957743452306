import { ICRMContext, useCRMContext } from "~src/contexts";
import { NetworkStatus, RecipientCRMMap } from "~src/redux/typings";
import { useAppSelector } from "~src/redux";
import { useMemo } from "react";

interface IUseCRM extends ICRMContext {
  loading: boolean;
  status: NetworkStatus;
  lastUpdated: number | null;
  crms: RecipientCRMMap;
}

export const useCRM = (): IUseCRM => {
  const {
    crms: crmsObj,
    status = NetworkStatus.idle,
    lastUpdated,
  } = useAppSelector(({ feed }) => feed);

  const crms = useMemo(() => crmsObj, [crmsObj]);

  const { currentCRM, resetCRMContext, setCRMContext } = useCRMContext();

  return {
    loading: status === NetworkStatus.loading,
    status,
    lastUpdated,
    crms,
    currentCRM,
    resetCRMContext,
    setCRMContext,
  };
};
