import { URLS, networkRequest } from "~src/api";
import { store } from "~src/redux/store";

import {
  SegmentEventDescriptions,
  SegmentEventLocations,
  SegmentEventNames,
} from "./constants";

export const segmentEvent = (
  name: SegmentEventNames,
  location: SegmentEventLocations,
  extraData?: Record<string, string | number | boolean>
) => {
  const { chromeId } = store.getState().config;
  if (!process.env.BACKEND_API?.includes("trylavender.com") || !chromeId) {
    return;
  }

  void networkRequest.post(URLS.segmentEvent, {
    ...extraData,
    chromeId,
    description: SegmentEventDescriptions[name],
    location,
    name,
  });
};
