import { useContext } from "react";
import { styled } from "styled-components";
import { Flex, Card, Space } from "antd";

import { Button, Text, Icon, IconProps } from "@lavender-ai/lav-components";

import { mainPanelAction } from "~src/redux";
import { useAppDispatch, useAppSelector } from "~src/redux/hooks";
import { FRONTEND_URLS } from "~src/constants";
import { useLvIdentifier } from "~src/customHooks";
import { CarouselContext } from ".";
import {
  SegmentEventLocations,
  SegmentEventNames,
  extractUserDataForProfile,
  segmentEvent,
} from "~src/utils";

import { StyledCard } from "./Styled";
import { UserDataAvatar } from "~src/component/Molecules";
import { viewState } from "~src/redux/typings";

export const UserSettings = () => {
  const user = useAppSelector((state) => state.userAccount.settings.user);
  //INFO: for setting up the link to extension settings view );
  const { next } = useContext(CarouselContext);

  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].isOpen
  );
  const view: viewState = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].view
  );

  if (!user) return null;
  const { avatar } = extractUserDataForProfile(user);
  const { first_name, last_name, email } = user;

  const showIntercom = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    segmentEvent(
      SegmentEventNames.SupportClicked,
      SegmentEventLocations.SettingsInExtension
    );
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "intercom" }));
    if (isOpen !== "open") {
      dispatch(mainPanelAction.open(lvIdentifier));
    } else if (view === "intercom") {
      dispatch(mainPanelAction.close(lvIdentifier));
    }
  };

  return (
    <Flex
      vertical
      justify="space-between"
      align="center"
      style={{
        backgroundColor: "#F6F8FC",
        padding: 24,
        width: "calc(375px - 48px)",
      }}
    >
      <Flex
        vertical
        justify="space-between"
        align="center"
        style={{ padding: "20px 0 40px 0", width: "100%" }}
      >
        <div>
          <UserDataAvatar
            src={avatar}
            size="extra-large"
            usePlaceholderFallback
          />
        </div>
        <div>
          <Title vertical align="center" justify="space-between">
            <Text color="black" size="subtext4">
              {first_name}&nbsp;{last_name}
            </Text>
            <Text color="black" size="body1">
              {email}
            </Text>
          </Title>
          <Flex justify="space-between" gap={8}>
            <Button
              size="small"
              state="initial"
              variant="secondary"
              onClick={showIntercom}
            >
              Support
            </Button>
            <Button
              size="small"
              state="initial"
              variant="secondary"
              onClick={() => {
                segmentEvent(
                  SegmentEventNames.ProductTourClicked,
                  SegmentEventLocations.SettingsInExtension
                );
              }}
            >
              Product Tour
            </Button>
          </Flex>
        </div>
      </Flex>
      <Space size={8} direction={"vertical"} style={{ width: "100%" }}>
        <SettingsCard
          title="Email & Extension"
          body="Lavender Anywhere, Spelling & Grammar, Tool Tips, Performance Mode."
          onClick={() => {
            segmentEvent(
              SegmentEventNames.EmailAndExtensionClicked,
              SegmentEventLocations.SettingsInExtension
            );
            next("extension_settings");
          }}
          clickIcon={
            <Icon color="black" size="extra-small" variant="IconChevronRight" />
          }
          prefixIcon="IconMailCog"
        />
        <SettingsCard
          title="Account & Personalization"
          body="Update company info, competitors, tech stack & more. Our AI assistant will use to personalize the emails it generates."
          onClick={onPersonalizationDataClick}
          clickIcon={
            <Icon color="black" size="extra-small" variant="IconArrowUpRight" />
          }
          prefixIcon="IconDatabaseSearch"
        />
        <SettingsCard
          title="Integrations"
          body="Connect to tools like Outreach, Salesloft, Google Calendar, Outlook & more."
          onClick={onIntegrationsClick}
          clickIcon={
            <Icon color="black" size="extra-small" variant="IconArrowUpRight" />
          }
          prefixIcon="IconApps"
        />
        <SettingsCard
          title="Plans & Billing"
          body="Update your plan & payment method."
          onClick={() => {
            segmentEvent(
              SegmentEventNames.PlansAndBillingClicked,
              SegmentEventLocations.SettingsInExtension
            );
            window.open(FRONTEND_URLS.billing, "_blank"); // next("billing");
          }}
          clickIcon={
            <Icon color="black" size="extra-small" variant="IconArrowUpRight" />
          }
          prefixIcon="IconCreditCard"
        />
        <SettingsCard
          title="Email Signature"
          body="Adding your signature will instruct our AI to exclude it from processing & analytics."
          onClick={onEmailSignatureClick}
          clickIcon={
            <Icon color="black" size="extra-small" variant="IconArrowUpRight" />
          }
          prefixIcon="IconSignature"
        />
        <SettingsCard
          title="Security"
          body="Manage your connected accounts, cookie preferences & more."
          onClick={onSecurityClick}
          clickIcon={
            <Icon color="black" size="extra-small" variant="IconArrowUpRight" />
          }
          prefixIcon="IconShieldLock"
        />
      </Space>
    </Flex>
  );
};

const Title = styled(Flex)`
  height: 34px;
  margin: 15px 0;
`;

const onPersonalizationDataClick: React.MouseEventHandler<HTMLElement> = (
  e
) => {
  e.preventDefault();
  segmentEvent(
    SegmentEventNames.AccountAndPersonalizationClicked,
    SegmentEventLocations.SettingsInExtension
  );
  window.open(`${FRONTEND_URLS.dashboard}/app/settings`, "_blank");
};
const onIntegrationsClick: React.MouseEventHandler<HTMLElement> = (e) => {
  e.preventDefault();
  segmentEvent(
    SegmentEventNames.IntegrationsClicked,
    SegmentEventLocations.SettingsInExtension
  );
  window.open(`${FRONTEND_URLS.dashboard}/app/settings/integrations`, "_blank");
};
const onSecurityClick: React.MouseEventHandler<HTMLElement> = (e) => {
  e.preventDefault();
  segmentEvent(
    SegmentEventNames.SecurityClicked,
    SegmentEventLocations.SettingsInExtension
  );
  window.open(`${FRONTEND_URLS.dashboard}/app/settings/account`, "_blank");
};
const onEmailSignatureClick: React.MouseEventHandler<HTMLElement> = (e) => {
  e.preventDefault();
  segmentEvent(
    SegmentEventNames.EmailSignatureClicked,
    SegmentEventLocations.SettingsInExtension
  );
  window.open(`${FRONTEND_URLS.dashboard}/app/settings/profile`, "_blank");
};

const StyledSettingsSwitchCard = styled(StyledCard)`
  box-sizing: border-box;
  padding: 20px 8px 20px 16px;
  width: 100%;
`;

interface SettingsCardProps {
  title: string;
  body: string;
  onClick: React.MouseEventHandler<HTMLElement> | undefined;
  clickIcon: React.ReactNode;
  prefixIcon: IconProps["variant"];
}

const SettingsCard = ({
  title,
  body,
  onClick,
  clickIcon,
  prefixIcon,
}: SettingsCardProps) => {
  return (
    <StyledSettingsSwitchCard
      className="lv-settings-card lv-cta"
      onClick={onClick}
    >
      <Button
        htmlType="button"
        icon={prefixIcon}
        radius="md"
        size="medium"
        state="initial"
        variant="secondary"
      />
      <Card
        title={
          <Text color="black" size="subtext2" style={{ margin: "unset" }}>
            {title}
          </Text>
        }
        extra={clickIcon}
        style={{ border: "none", width: "100%" }}
        headStyle={{
          borderBottom: "none",
          padding: "0 0 0 24px",
          minHeight: "unset",
          height: "10px",
          width: "98%",
        }}
        bodyStyle={{
          marginTop: "12px",
          padding: "0 0 0 24px",
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "18px",
          letterSpacing: "-0.25px",
          textAlign: "left",
          width: "calc(100% - 24px)",
        }}
      >
        <Text size="body1">{body}</Text>
      </Card>
    </StyledSettingsSwitchCard>
  );
};
