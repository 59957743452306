import { useState } from "react";
import { Flex, Card } from "antd";
import { Text, Tag } from "@lavender-ai/lav-components";
import styled from "styled-components";

import { IconFrame } from "~src/component/Atoms";
import { LavenderIcon } from "~src/component/typings";
import { Recommendation } from "~src/redux/typings";

import { CategorySection } from "../../../Shared/Styled/CategorySection";

interface Props {
  recommendations: Recommendation[];
}

export const WhatsDoneRight = ({ recommendations }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filtered: Recommendation[] = recommendations.filter((r) => {
    if (r.effectonscore < 0.1) {
      return true;
    }
    return false;
  });

  const [selected, setSelected] = useState<Recommendation | undefined>(
    undefined
  );

  return filtered?.length <= 0 ? null : (
    <CategorySection
      className={`lv-email-category category-success`}
      vertical
      style={{ width: "100%", marginBottom: "12px" }}
    >
      <Card className="lv-card">
        <CategoryHeader>
          <IconFrame color="pear" variant={LavenderIcon.IconCheck} />
          <Flex vertical>
            <Text color="black" size="subtext4">
              What you&apos;ve done right
            </Text>
          </Flex>
        </CategoryHeader>
        <CoachTags>
          {recommendations.map((f) => {
            if (f.effectonscore > 0) {
              // eslint-disable-next-line array-callback-return
              return null;
            }
            return (
              <Tag
                onClick={() => {
                  setSelected(f);
                }}
                color={selected?.id === f.id ? "dark" : "gray"}
                variant="base"
                key={`f-${f.id}`}
              >
                {f.rectype}
              </Tag>
            );
          })}
        </CoachTags>
        {selected && (
          <CoachTagsDescription vertical={true}>
            <Text
              size="body2"
              style={{
                color: "var(--color-neutral-purple-900, #140C22)",
              }}
              dangerouslySetInnerHTML={{
                __html: "🎉 &nbsp;" + selected?.recommendation,
              }}
            ></Text>
          </CoachTagsDescription>
        )}
      </Card>
    </CategorySection>
  );
};

const CategoryHeader = styled(Flex)`
  padding: 20px 16px;
  align-items: center;
`;
const CoachTags = styled.div`
  padding: 0 16px;
  > span {
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
  }
`;
const CoachTagsDescription = styled(Flex)`
  padding: 16px 16px 24px 16px;
`;
