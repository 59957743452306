import { networkRequest, URLS } from "~src/api";
import { TechStackResponse } from "./typings";

export const getTechStack = async (
  email: string,
  chromeId: chromeId
): Promise<TechStackResponse> => {
  const { data } = await networkRequest.post<TechStackResponse>(
    URLS.techStack,
    {
      email,
      chrome_id: chromeId,
    }
  );

  return data;
};
