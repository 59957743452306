import { css, styled } from "styled-components";

import {
  Avatar,
  AvatarProps,
  Icon,
  IconProps,
  Tag,
  TagProps,
} from "@lavender-ai/lav-components";
import { SKELETON_GRADIENT_MIXIN } from "~src/component/Molecules/Message/styles";

const iconColorMap = {
  lavender: "var(--color-lavender-600)",
  marigold: "var(--color-marigold-700)",
  outline: "var(--color-neutral-purple-600)",
  pear: "var(--color-pear-700)",
  peony: "var(--color-peony-600)",
  tart: "var(--color-tart-600)",
};

const textColorMap = {
  ...iconColorMap,
  outline: "var(--color-neutral-purple-900)",
};

interface StyledIconFrameProps {
  color: AvatarProps["color"] | TagProps["color"];
  isLoading?: boolean;
}

const createStyledIconFrame = (BaseComponent) => styled(
  BaseComponent
)<StyledIconFrameProps>`
  &.lav-components-avatar {
    flex-shrink: 0;

    ${({ $isLoading: isLoading }) =>
      isLoading &&
      css`
        background-color: transparent !important;
        border-color: transparent;
        border-radius: 15px;
        display: inline-block;
        pointer-events: none;
        ${SKELETON_GRADIENT_MIXIN}
      `};
  }

  &.lav-components-tag {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 4px;

    ${({ $isLoading: isLoading }) =>
      isLoading &&
      css`
        background-color: transparent !important;
        border-color: transparent;
        pointer-events: none;
        ${SKELETON_GRADIENT_MIXIN}
      `};

    ${({ color, $isLoading: isLoading }) =>
      color === "outline" &&
      !isLoading &&
      `
      border: 1px solid var(--color-neutral-purple-200) !important;
      background-color: var(--color-base-White) !important;
    `}
  }

  svg.lav-components-icon {
    ${({ color, $isLoading: isLoading }) =>
      (isLoading || (color && iconColorMap[color])) &&
      `
      color: ${isLoading ? "transparent" : iconColorMap[color!]};
      stroke: ${isLoading ? "transparent" : iconColorMap[color!]};
    `}
  }

  .lv-icon-frame-text {
    ${({ color, $isLoading: isLoading }) =>
      (isLoading || (color && iconColorMap[color])) &&
      `
      color: ${isLoading ? "transparent" : iconColorMap[color!]};
    `}
  }
`;

const StyledIconFrame = createStyledIconFrame(Avatar);
const StyledTagIconFrame = createStyledIconFrame(Tag);

interface CommonProps {
  color?: AvatarProps["color"] | TagProps["color"];
  isTag?: boolean;
  iconSize?: IconProps["size"];
  isLoading?: boolean;
  variant: IconProps["variant"];
}

interface AvatarContainerProps extends CommonProps {
  avatarShape?: string;
  avatarSize?: string;
}

interface TagContainerProps extends CommonProps {
  text?: string;
}

type Props = AvatarContainerProps | TagContainerProps;

function isTagContainerProps(props: Props): props is TagContainerProps {
  return !!props.isTag;
}

export const IconFrame = (props: Props) => {
  const { isLoading, variant } = props;

  if (isTagContainerProps(props)) {
    const { color = "outline", iconSize = "mini", text } = props;
    return (
      <StyledTagIconFrame
        className="lv-icon-frame"
        color={color}
        $isLoading={isLoading}
      >
        <Icon size={iconSize} variant={variant} />
        {text && <div className="lv-icon-frame-text">{text}</div>}
      </StyledTagIconFrame>
    );
  }

  const {
    color = "neutral-100",
    avatarShape = "square",
    avatarSize = "extra-small",
    iconSize = "extra-small",
  } = props;

  return (
    <StyledIconFrame
      className="lv-icon-frame"
      color={color}
      shape={avatarShape}
      icon={<Icon size={iconSize} variant={variant} />}
      size={avatarSize}
      type="soft"
      $isLoading={isLoading}
    />
  );
};
