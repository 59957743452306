import { useCallback, useEffect, useRef, useState } from "react";
import { Form, message } from "antd";

import {
  UpdateProfile,
  FieldType as UpdateProfileFieldType,
} from "~src/component/Organisms/Forms/UpdateProfile/UpdateProfile";
import {
  ConfirmInfo,
  FieldType as ConfirmInfoFieldType,
} from "~src/component/Organisms/Forms/ConfirmInfo/ConfirmInfo";
import {
  useStartMyEmail,
  useIsMounted,
  usePersonalization,
  useRankedFeedApi,
  useRouteToCartModal,
} from "~src/customHooks";

import { PersonalizationPanelContainer } from "~src/component/Organisms";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import {
  selectChromeId,
  selectUserAccountEmail,
  useAppSelector,
  useUpdateProfileSettingsMutation,
  useUpdateSettingsMutation,
} from "~src/redux";
import {
  CONFIRM_INFO_FORM,
  SNACKBAR_TEXT,
  UPDATE_PROFILE_FORM,
} from "~src/strings";
import { delay } from "~src/utils";

import { StyledPanelFormLayout } from "../../Shared";
import { Header } from "./Header/Header";

/**
 *  ProfileForm updates either the user's profile settings, or their prospect's profile settings, depedning on which
 *  form is displayed.
 *    - UpdateProfile: Updates the user's profile settings
 *    - ConfirmInfo: Updates the prospect's profile settings
 */

export const ProfileForm = () => {
  const currentUserEmail = useAppSelector(selectUserAccountEmail);
  const {
    data: { cta, openedBy },
    modalType,
    reset,
  } = useCarouselContext();
  const isMounted = useIsMounted();

  // TODO: code debt. The "cart" moved panels, but still lives in the same redux slice. Move into a separate slice.
  const { addItemToCartContext } = usePersonalization();
  const { handleRouteToCartModal } = useRouteToCartModal();
  const {
    currentPersonalization: {
      cart,
      profile: { email },
    },
  } = useStartMyEmail();

  const [saving, setIsSaving] = useState(false);
  const chromeId = useAppSelector(selectChromeId);
  const [updateProfileSettings] = useUpdateProfileSettingsMutation({
    fixedCacheKey: email,
  });
  const [updateSettings] = useUpdateSettingsMutation({
    fixedCacheKey: currentUserEmail,
  });
  const snackbarContainerRef = useRef<HTMLDivElement>(null);
  const [messageApi, contextHolder] = message.useMessage({
    getContainer: () => snackbarContainerRef.current as HTMLElement,
    top: -64,
    duration: 2,
    maxCount: 1,
  });

  const { refetch } = useRankedFeedApi(email);

  useEffect(() => {
    if (
      modalType === ModalType.ProfileForm &&
      (!openedBy || openedBy !== email)
    ) {
      reset();
    }
  }, [openedBy, email, modalType, reset]);

  const routedItems = cart.events.items.filter(
    (item) => item.showCart === true
  );

  const handleRoutedItems = useCallback(() => {
    routedItems.forEach((item) => {
      addItemToCartContext({ ...item, showCart: true });
    });
    handleRouteToCartModal();
  }, [addItemToCartContext, handleRouteToCartModal, routedItems]);

  const showSuccessSnackbar = useCallback(() => {
    void (async () => {
      let timeoutHandle: NodeJS.Timeout | undefined;

      try {
        const refetchPromise = refetch();
        const { promise: delayPromise, timeoutId } = delay(2000);
        timeoutHandle = timeoutId;

        const snackbarPromise = delayPromise
          .then(() => {
            return messageApi.success({
              content: SNACKBAR_TEXT.INFO_SAVED,
              style: {
                border: "1px var(--color-neutral-purple-200)",
                borderRadius: 12,
                position: "relative",
              },
            });
          })
          .then(() => {
            setIsSaving(false);
          });
        await Promise.all([refetchPromise, snackbarPromise]);
        if (isMounted()) {
          if (routedItems.length) {
            handleRoutedItems();
          } else {
            reset();
          }
        }
      } catch (error) {
        // TODO: error handling
      } finally {
        if (timeoutHandle) {
          clearTimeout(timeoutHandle);
        }
      }
    })();
  }, [
    handleRoutedItems,
    isMounted,
    messageApi,
    refetch,
    reset,
    routedItems.length,
  ]);

  const [updateProfileForm] = Form.useForm<UpdateProfileFieldType>();
  const [confirmInfoForm] = Form.useForm<ConfirmInfoFieldType>();
  const submitUpdateProfileForm = useCallback(
    ({
      companyBio,
      companyName,
      companyWebsite,
      jobTitle,
    }: UpdateProfileFieldType) => {
      void (async () => {
        try {
          const payload = {
            chromeId,
            companyDescription: companyBio,
            companyName,
            companyUrl: companyWebsite,
            email: currentUserEmail,
            position: jobTitle,
          };
          setIsSaving(true);
          await updateSettings(payload);
          showSuccessSnackbar();
        } catch (e) {
          parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
            request: "updateProfile",
          });
          // TODO: error handling
          setIsSaving(false);
        }
      })();
    },
    [chromeId, currentUserEmail, showSuccessSnackbar, updateSettings]
  );

  const submitConfirmInfoForm = useCallback(
    ({
      companyBio,
      companyName,
      companyWebsite,
      jobTitle,
    }: ConfirmInfoFieldType) => {
      void (async () => {
        try {
          const payload = {
            chromeId,
            companyDescription: companyBio,
            companyName,
            companyUrl: companyWebsite,
            email,
            position: jobTitle,
          };
          setIsSaving(true);
          await updateProfileSettings(payload);
          showSuccessSnackbar();
        } catch (e) {
          parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
            request: "confirmInfo",
          });
          setIsSaving(false);
        }
      })();
    },
    [chromeId, email, showSuccessSnackbar, updateProfileSettings]
  );

  return (
    <StyledPanelFormLayout rootClassName="lv-profile-form-box" isFullHeight>
      <PersonalizationPanelContainer
        align="center"
        vertical
        $isPanelWidth
        $padding="0 20px"
        $textAlign="left"
      >
        <Header isSaving={saving} />
        {contextHolder}
        {cta === UPDATE_PROFILE_FORM.HEADER && (
          <UpdateProfile
            snackbarContainerRef={snackbarContainerRef}
            form={updateProfileForm}
            isSaving={saving}
            submitForm={submitUpdateProfileForm}
          />
        )}
        {cta === CONFIRM_INFO_FORM.HEADER && (
          <ConfirmInfo
            snackbarContainerRef={snackbarContainerRef}
            form={confirmInfoForm}
            isSaving={saving}
            submitForm={submitConfirmInfoForm}
          />
        )}
      </PersonalizationPanelContainer>
    </StyledPanelFormLayout>
  );
};
