import { Divider, Empty, Flex, Skeleton, Space } from "antd";
import { Icon, Tabs, Text } from "@lavender-ai/lav-components";

import { LinkedinPost, Tweet } from ".";

import { usePersonalization, useTweets } from "~src/customHooks";
import { useAppSelector, useGetLinkedInPostsQuery } from "~src/redux";
import { LinkedInPost as ILinkedInPost } from "~src/redux/typings";
import { NoDataDisplay } from "~src/component/Molecules";

interface SocialFeedProps {
  source: "company" | "individual";
}
export const SocialFeed: React.FC<SocialFeedProps> = ({
  source,
}: SocialFeedProps) => {
  const { chromeId } = useAppSelector(({ config }) => config);
  const {
    currentPersonalization: {
      company: { social_medias: company_social_medias },
      profile: { email, name, social_medias: profile_social_medias },
    },
  } = usePersonalization();

  const {
    currentTweets: { profile_twitter },
  } = useTweets();

  const linkedinUrl =
    source === "company"
      ? company_social_medias?.linkedin || ""
      : profile_social_medias?.linkedin || "";

  let firstName = name;
  let lastName = name;
  if (firstName.includes(" ")) {
    firstName = firstName.split(" ")[0];
    lastName = firstName.split(" ")[1];
  }

  const { data, isLoading, isError, isSuccess, isUninitialized } =
    useGetLinkedInPostsQuery(
      {
        email,
        chromeId,
        firstName,
        lastName,
        linkedinUrl,
        isCompany: source === "company" ? "true" : "false",
      },
      { skip: !linkedinUrl }
    );

  const empty =
    isError ||
    (!isLoading && data?.length === 0) ||
    (isUninitialized && !linkedinUrl);

  let linkedInTab: React.ReactNode | null = null;

  if (isLoading) {
    linkedInTab = <Skeleton style={{ padding: "20px 16px" }} loading active />;
  } else if (empty) {
    linkedInTab = (
      <NoDataDisplay mainText="We couldn't find any LinkedIn posts." />
    );
  } else if (isSuccess) {
    linkedInTab = (
      <>
        <Text size="subtext1" color={"black"}>
          {data.length} Relevant Posts
        </Text>
        <Divider style={{ marginBottom: 0, marginTop: 12 }} />
        {data?.map((post: ILinkedInPost, i: number) => {
          return (
            <div key={i}>
              <LinkedinPost post={post} />
              <Divider style={{ margin: 0 }} />
            </div>
          );
        })}
      </>
    );
  }

  const twitterTab = !profile_twitter?.tweets?.length ? (
    <NoDataDisplay mainText="We couldn't find any Twitter posts." />
  ) : (
    <Space size={12} direction={"vertical"}>
      {profile_twitter?.tweets?.map((tweet, _i) => {
        return (
          <div key={tweet.id}>
            <Tweet tweet={tweet} twitterUser={profile_twitter} />
            <Divider style={{ margin: 0 }} />
          </div>
        );
      })}
    </Space>
  );

  const linkedinLabel = (
    <Flex align={"center"}>
      <Icon
        size="mini"
        variant="IconBrandLinkedin"
        style={{ marginRight: "6px" }}
      />
      <Text size={"body1"}>Linkedin</Text>
    </Flex>
  );

  const twitterLabel = (
    <Flex align={"center"}>
      <Icon size="mini" variant="IconBrandX" style={{ marginRight: "6px" }} />
      <Text size={"body1"}>Twitter</Text>
    </Flex>
  );

  return (
    <Space
      size={0}
      style={{
        background: "var(--color-base-White)",
        borderRadius: "var(--radius-lrg, 16px)",
        textAlign: "left",
        width: "-webkit-fill-available",
      }}
      direction={"vertical"}
    >
      <div style={{ padding: "20px 16px" }}>
        <Text size={"subtext3"}>Social Feed</Text>
      </div>

      <Tabs
        items={[
          {
            children: linkedInTab,
            key: "1",
            label: linkedinLabel,
          },
          {
            children: twitterTab,
            key: "2",
            label: twitterLabel,
          },
        ]}
        size="small"
        style={{
          padding: "0 16px",
          margin: "0",
          width: "100%",
        }}
        variant="underlined"
      />
    </Space>
  );
};
