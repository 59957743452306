import { Flex } from "antd";
import { useCallback } from "react";
import { styled } from "styled-components";

import { Icon, Tooltip } from "@lavender-ai/lav-components";

import { ExtensionButton, ExtensionButtonMode } from "~src/component/Atoms";
import { Toggle } from "~src/component/Molecules";
import { LavenderIcon } from "~src/component/typings";
import { CenteredText, SecondaryText } from "~src/component/Styled";
import { useTooltip, useValuePropGeneration } from "~src/customHooks";
import { toggleSetting, useAppDispatch } from "~src/redux";
import { SME_CAPTION, SME_TOOLTIP } from "~src/strings";

import { FullWidthFlex } from "../Styled/FullWidthFlex";

const StyledStartMyEmailCTAContainer = styled.div`
  width: 100%;

  &:has(.lv-icebreakers-cta:disabled) {
    cursor: not-allowed;
  }

  .lv-icebreakers-cta {
    &:disabled {
      pointer-events: none;
    }
  }
`;

const StyledTooltipTitleWrapper = styled(Flex)`
  max-width: 100%;
  svg.lav-components-icon {
    color: var(--color-lavender-300);
    flex-shrink: 0;
    stroke: var(--color-lavender-300);
  }
`;

const TooltipTitle = (
  <StyledTooltipTitleWrapper align="center" gap="small">
    <Icon variant={LavenderIcon.IconInfoSquareRoundedFilled} size="mini" />
    <CenteredText color="white" size="subtext1">
      {SME_TOOLTIP.THIS_MAY_TAKE_A_FEW_SECONDS}
    </CenteredText>
  </StyledTooltipTitleWrapper>
);

const TOGGLE_TOOLTIP_PROPS = {
  title: TooltipTitle,
  overlayInnerStyle: {
    minWidth: "275px",
  },
};

interface Props {
  count?: number;
  isDisabled?: boolean;
  onClick: () => void;
  showValuePropGenerationToggle?: boolean;
  text: string;
}

export const StartMyEmailCTA = ({
  count,
  isDisabled,
  onClick,
  showValuePropGenerationToggle,
  text,
}: Props) => {
  const dispatch = useAppDispatch();
  const { open: tooltipOpen, setOpen: setTooltipOpen } = useTooltip(
    false,
    1000,
    ["click"]
  );
  const showCount = count !== undefined;
  const { isUserValuePropGenerationOn } = useValuePropGeneration();

  const onToggle = useCallback(
    (checked: boolean) => {
      void (async () => {
        await dispatch(
          toggleSetting({
            on: checked,
            setting: "value_prop_generation",
          })
        );
      })();
    },
    [dispatch]
  );

  const focusPanelAction = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (isDisabled) {
        if (showCount) {
          setTooltipOpen(true);
        }
      } else {
        onClick();
      }
    },
    [isDisabled, onClick, setTooltipOpen, showCount]
  );

  return (
    <FullWidthFlex align="center" gap="small" vertical>
      <Tooltip
        className="lv-icebreakers-cta-tooltip"
        rootClassName="lv-icebreakers-cta-tooltip--root"
        open={tooltipOpen}
        placement="top"
        title={
          <CenteredText color="white" size="subtext1">
            {SME_TOOLTIP.SELECT_ICEBREAKERS}
          </CenteredText>
        }
      >
        <StyledStartMyEmailCTAContainer onMouseDown={focusPanelAction}>
          <ExtensionButton
            className="lv-icebreakers-cta"
            disabled={isDisabled}
            mode={ExtensionButtonMode.cta}
            prefix={LavenderIcon.IconSparkles}
            text={text}
            variant="primary"
          />
        </StyledStartMyEmailCTAContainer>
      </Tooltip>
      {showCount && !showValuePropGenerationToggle && (
        <SecondaryText $light size="body1">
          {SME_CAPTION.SELECTED(count)}
        </SecondaryText>
      )}
      {showValuePropGenerationToggle && (
        <Toggle
          checked={isUserValuePropGenerationOn}
          defaultChecked={isUserValuePropGenerationOn}
          label={SME_CAPTION.AUTOFILL_VALUE_PROPS}
          onChange={onToggle}
          highlightLabelOnHover
          tooltipProps={TOGGLE_TOOLTIP_PROPS}
        />
      )}
    </FullWidthFlex>
  );
};
