import { Form } from "antd";

import { TextArea } from "@lavender-ai/lav-components";

import { useAppSelector } from "~src/redux";
import { useEffect } from "react";
import { useStartMyEmail } from "~src/customHooks";

const NAME = ["categories", "custom", "main"];

export const CustomField = () => {
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = useStartMyEmail();
  const customMainValueProp = useAppSelector(
    ({ valueProps }) =>
      valueProps.customValueProps[email]?.categories?.custom?.main || ""
  );
  const initialValue = customMainValueProp;
  const form = Form.useFormInstance();
  const initialFormValue = form.getFieldValue(NAME);

  useEffect(() => {
    if (initialFormValue !== initialValue && !form.isFieldTouched(NAME)) {
      form.setFields([
        {
          name: NAME,
          value: initialValue,
          touched: false,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NAME, form]);

  return (
    <Form.Item name={NAME} noStyle>
      <TextArea autoSize={{ minRows: 5, maxRows: 6 }} maxLength={250} />
    </Form.Item>
  );
};
