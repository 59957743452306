export type EmailDataState = Record<lvIdentifier, EmailWritingData>;
export interface EmailWritingData {
  scoringModelOverwrite: "personalized" | "best practices" | undefined;
  toAddress: emailAddress[] | never[];
  subject: string;
  subjectLastUpdated: number;
  hasSubjectPaused: boolean;
  body: string;
  bodyLastUpdated: number;
  hasBodyPaused: boolean;
  timeSent: number;
  writingStartTime: number;
  //INFO: list of all email addresses that have received this email in the body thread,
  // which different from the current email's toAddress, example someone forwarded the email but is now in the email thread
  recipientEmailList: string[] | never[];
  emailsBefore: EmailBefore[];
  authorEmail: string;
  authorName: string;
  stageOverwrite: string;
  isCoachUsed: boolean;
  isChatGPTUsed: boolean;
  isFrameworksUsed: boolean;
  isGifsUsed: boolean;
  isPersonalizationAssistantUsed: boolean;
  isStartMyEmailUsed: boolean;
  includeInAnalytics: boolean;
}
export interface Initialize {
  lvIdentifier: lvIdentifier;
  container: HTMLElement;
}

export interface PixelIDWrapper {
  id: lvIdentifier;
}

export interface UpdateToAddress {
  id: lvIdentifier;
  toAddress: emailAddress[];
}

export interface UpdateSubject {
  id: lvIdentifier;
  subject: string;
}

export interface UpdateBody {
  id: lvIdentifier;
  body: string;
}

export interface UpdateTimeSent {
  id: lvIdentifier;
  timeSent: number;
}

export interface UpdateRecipientEmailList {
  id: lvIdentifier;
  recipientEmailList: string[];
}
export interface EmailBefore {
  authorEmail: string;
  authorName: string;
  timeSent: number;
  recipients: [];
  emailBody: string;
}

export interface UpdateEmailsBefore {
  id: lvIdentifier;
  emailsBefore: EmailBefore[];
}
export interface UpdateAuthorName {
  id: lvIdentifier;
  authorName: string;
}
export interface UpdateAuthorEmail {
  id: lvIdentifier;
  authorEmail: string;
}
export interface UpdateStage {
  id: lvIdentifier;
  stageOverwrite: string;
}
export interface UpdateScoringModel {
  id: lvIdentifier;
  scoringModelOverwrite: "personalized" | "best practices" | undefined;
}

export interface ToggleIncludeInAnalytics {
  id: lvIdentifier;
  includeInAnalytics: boolean;
}
