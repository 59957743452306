import {
  CompanyResponse,
  EventItem,
  RankedFeedExperience,
  RankedFeedType,
  RecentActivity,
  TechItem,
} from "../api/typings";
import {
  DEFAULT_CART,
  DEFAULT_COMPANY,
  DEFAULT_RECENT_ACTIVITY,
  CLEARBIT_LOGO_URL,
  DEFAULT_APOLLO_AVATAR_URL,
  DEFAULT_APOLLO_LOGO_URL,
  DIFFBOT_IMG_DOMAIN,
  RANKED_FEED_TYPES,
  DEFAULT_PROFILE_COMPANY,
} from "~src/constants";
import type {
  IPersonalization,
  Person,
  PersonalizationEvent,
  PersonalizationExperience,
  PersonalizationProfileData,
  PersonalizationRankedData,
  PersonalizationTechStack,
  ProfileCompany,
  ProfileUser,
  User,
} from "~src/redux/typings";
import { normalizeTriggers } from "./ranked";
import { snakeCaseToTitleCase } from "./strings";

export const resolveCompanyLogo = (company: CompanyResponse) => {
  const { domain, logo } = company;
  if (
    domain &&
    (!logo ||
      logo.includes(DIFFBOT_IMG_DOMAIN) ||
      logo.includes(DEFAULT_APOLLO_LOGO_URL))
  ) {
    return `${CLEARBIT_LOGO_URL}${domain}`;
  }
  return logo || "";
};

export const resolveProfileAvatar = (profile: Person) => {
  const { avatar } = profile;
  return !avatar || avatar.includes(DEFAULT_APOLLO_AVATAR_URL) ? "" : avatar;
};
export const resolveProfileJobTitle = (profile: Person | undefined) => {
  return profile ? profile.job.title || "" : "";
};
export const resolveProfileCompanyBio = (
  profileCompany: ProfileCompany | undefined
) => {
  return profileCompany?.company_bio || "";
};
export const resolveProfileCompanyName = (
  profileCompany: ProfileCompany | undefined
) => {
  return profileCompany?.company_name || "";
};
export const resolveProfileCompanyWebsite = (
  profileCompany: ProfileCompany | undefined
) => {
  return profileCompany?.url || "";
};

export const newProfileUser = (): ProfileUser => ({
  avatar: "",
  displayName: "",
  email: "",
});

export const newPendingPersonalization = (email: string): IPersonalization => ({
  profile: newPendingProfile(email),
  recent_activity: DEFAULT_RECENT_ACTIVITY as RecentActivity,
  company: DEFAULT_COMPANY,
  notes: [],
  cart: DEFAULT_CART,
});

export const newRejectedPersonalization = (
  email: string
): IPersonalization => ({
  profile: {
    ...newPendingProfile(email),
    loading: false,
  },
  recent_activity: DEFAULT_RECENT_ACTIVITY as RecentActivity,
  company: DEFAULT_COMPANY,
  notes: [],
  cart: DEFAULT_CART,
});

export const newPendingProfile = (
  email: string
): PersonalizationProfileData => ({
  avatar: "",
  company: DEFAULT_PROFILE_COMPANY,
  bio: "",
  education: "",
  job: {
    company: "",
    title: "",
  },
  name: "",
  location: "",
  timezone: "",
  workHistory: [],
  email,
  loading: true,
});

export const extractInitials = (displayName: string): string => {
  const names = displayName.split(" ");
  if (names.length === 1) {
    return names[0].charAt(0);
  }
  return names[0].charAt(0) + names[names.length - 1].charAt(0);
};

export const extractFirstName = (name: string): string => name.split(" ")[0];

export const extractUserDataForProfile = (user: User | undefined) =>
  user
    ? (({ avatar, full_name, email }) => ({
        avatar,
        email,
        displayName: full_name,
      }))(user.person)
    : newProfileUser();

export const extractAvatarFallback = (name: string | undefined, email = "") =>
  extractInitials(name || email.split("@")[0].toUpperCase());

export const eventAvatarUrl = (event: PersonalizationEvent) =>
  CLEARBIT_LOGO_URL + event.source;

const titleFromEntityItem = (item: EventItem | TechItem) =>
  item.Entity.attributes.title;

const formattedTitleFromEventEntity = (item: EventItem) =>
  item.Entity.attributes.formattedTitle || titleFromEntityItem(item);
const formattedTitleFromExperienceEntity = (item: RankedFeedExperience) =>
  item.Entity.formatted_titles ? item.Entity.formatted_titles[0] : "";
const formattedTitleFromTechEntity = (item: TechItem) =>
  item.Entity.formatted_titles
    ? item.Entity.formatted_titles[0]
    : titleFromEntityItem(item);

const dateFromEventEntity = (item: EventItem) =>
  item.Entity.attributes.additional_data?.date ||
  item.Entity.attributes.additional_data?.article_published_at;
const dateFromTechEntity = (item: TechItem) =>
  item.Entity.attributes.last_seen_at;

const sourceFromEventEntity = (item: EventItem) =>
  item.Entity.attributes.additional_data?.article_source;
const sourceFromTechEntity = (item: TechItem) => item.Entity.attributes.domain;

const summaryFromEventEntity = (item: EventItem) =>
  (item.Entity.attributes.additional_data?.article_sentence || "").replace(
    /\s(\.{3}|…)(Read More)?$/,
    ""
  );
const summaryFromTechEntity = (item: TechItem) =>
  item.Entity.attributes.categories
    ? item.Entity.attributes.categories
        .map((cat) => snakeCaseToTitleCase(cat))
        .slice(0, 3)
        .join(", ")
    : "";

export const getEvents = (feed: EventItem[]): PersonalizationEvent[] =>
  feed.reduce((acc: PersonalizationEvent[], item: EventItem) => {
    if (item.Type === RankedFeedType.events) {
      acc.push({
        categories: normalizeTriggers(item.Entity.triggers),
        triggers: item.Entity.triggers ?? [],
        score: item.score ? item.score : 0,
        type: RankedFeedType.events,
        id: item.Entity.id,
        formattedTitle: formattedTitleFromEventEntity(item),
        title: titleFromEntityItem(item),
        date: dateFromEventEntity(item),
        source: sourceFromEventEntity(item),
        summary: summaryFromEventEntity(item),
        url: item.Entity.attributes.url,
      });
    }
    return acc;
  }, []);

export const getExperience = (
  employments: RankedFeedExperience[]
): PersonalizationExperience[] =>
  employments.reduce(
    (acc: PersonalizationExperience[], item: RankedFeedExperience) => {
      acc.push({
        ...item.Entity,
        start: item.Entity.start || 0, // icebreakers posing as work history items need a default
        formattedTitle: formattedTitleFromExperienceEntity(item),
        score: item.score ? item.score : 0,
        summary: "",
        triggers: item.Entity.triggers ?? [],
        type: RankedFeedType.work_history,
      });
      return acc;
    },
    []
  );

export const getTechStack = (feed: TechItem[]): PersonalizationTechStack[] =>
  feed.reduce((acc: PersonalizationTechStack[], item: TechItem) => {
    if (item.Type === RankedFeedType.tech_stack) {
      acc.push({
        categories: normalizeTriggers(item.Entity.triggers),
        triggers: item.Entity.triggers ?? [],
        score: item.score ? item.score : 0,
        type: RankedFeedType.tech_stack,
        id: item.Entity.id,
        formattedTitle: formattedTitleFromTechEntity(item),
        title: titleFromEntityItem(item),
        date: dateFromTechEntity(item),
        source: sourceFromTechEntity(item),
        summary: summaryFromTechEntity(item),
        url: item.Entity.attributes.url,
      });
    }
    return acc;
  }, []);

export const getAllRankfedFeedItems = (
  rankedFeed: PersonalizationRankedData
) => {
  return RANKED_FEED_TYPES.map((type) => rankedFeed[type].items).flat();
};
