import styled from "styled-components";
import { Icon, Tag } from "@lavender-ai/lav-components";
import { useCarouselContext } from "../../../CarouselContextProvider";
import { useGongData } from "../../../../../../customHooks";
import { GongCall } from "../../../../../../redux/typings";
import { store } from "~src/redux";

const ComponentFrame = styled.div`
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;
`;

const TitleFrame = styled.div`
  display: flex;
  padding: 20px 16px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: -0.1px;
`;

const TagFrame = styled.div`
  display: flex;
  padding: 0 16px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  margin-right: 3px;
`;

const DescriptionFrame = styled.ul`
  display: flex;
  padding: 8px 12px 24px;
  margin: 0 0 0 18px;
  flex-direction: column;
  align-items: flex-start;

  color: var(--color-neutral-purple-900, #18253d);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.25px;

  li::before {
    content: "• ";
    margin-right: 8px; // Adjust this value as needed
  }
`;

const StyledList = styled.ul<{ platform: string }>`
  margin-left: ${(props) =>
    props.platform === "gongEngage" ? "0px" : "-41px"};
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledListItem = styled.li`
  display: flex;
`;

interface Data {
  call: GongCall;
}

export const NextSteps = () => {
  const gongCalls = useGongData();
  const { next } = useCarouselContext();

  const state = store.getState();
  const platform = state.config.settings?.platform;

  if (!gongCalls.length) {
    return null;
  }

  return (
    <ComponentFrame>
      <TitleFrame>Next Steps</TitleFrame>
      <TagFrame>
        <StyledTag
          color="lavender"
          onClick={() => {
            next("GongCallPanel", { call: gongCalls[0] } as Data);
          }}
        >
          <StyledIcon size="mini" variant="IconPhone" />
          {gongCalls[0].title}
        </StyledTag>
      </TagFrame>
      <DescriptionFrame>
        <>
          {platform && (
            <StyledList platform={platform}>
              {gongCalls[0]?.nextSteps.map((step: string, index: number) => (
                <StyledListItem key={index}>{step}</StyledListItem>
              ))}
            </StyledList>
          )}
        </>
      </DescriptionFrame>
    </ComponentFrame>
  );
};
