import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  DEFAULT_RANKED_FEED_SECTION,
  FB_BASE_URL,
  TWITTER_BASE_URL,
  DEFAULT_CART,
} from "../../constants";
import {
  calculateRaised,
  ensureFullUrl,
  extractInvestors,
  getDomain,
  getEvents,
  getExperience,
  getTechStack,
  parseTechAggregateData,
  parseWorkHistory,
  resolveCompanyLogo,
  resolveProfileAvatar,
} from "~src/utils";
import { AppDispatch, RootState } from "../store";
import {
  CRMResponse,
  HubspotNote,
  HubspotNoteResponse,
  NewsResponse,
  PersonalizationResponse,
  RankedFeedResponse,
  RankedFeedType,
  TechStackResponse,
  TweetsResponse,
} from "~src/api/typings";
import {
  IPersonalization,
  PersonalizationCompanyData,
  PersonalizationProfileData,
  PersonalizationRankedData,
  ICRM,
  INews,
  ITechStack,
  ITweets,
} from "~src/redux/typings";

interface KnownError {
  error: string;
}

export const createFeedAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: KnownError;
}>();

export const parsePersonalizationResponse = (
  response: PersonalizationResponse,
  email: emailAddress
): IPersonalization => {
  const { profile, company, recent_activity, note } = response;

  const {
    company: {
      company_name: profileCompanyName,
      company_bio: profileCompanyBio,
      url: profileCompanyUrl,
    },
    social_medias,
  } = profile;

  const {
    investments,
    metrics: { raised, employeesRange } = {},
    social_medias: companySocialMedias = {
      crunchbase: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      website: "",
    },
  } = company;

  //!we need to use company.company_name > profile.company.company_name > profile.job.company
  // Only way to sync all fields, since profile.company.company_name / profile.job.company don't
  // have full info & lookups gets messy. The "companyData" object can be considered separate from
  // the "profileData" in this sense. *Applies to all fields in companyData*
  const companyNameFromDataVendor = company.company_name || "";
  const companyData: PersonalizationCompanyData = {
    bio: company.bio || "",
    location: company.location || "",
    jobs: { loading: true, jobs: undefined, aggregate: [] },
    name: companyNameFromDataVendor,
    employeesRange:
      employeesRange || company?.metrics?.["Employees Range"] || "",
    domain: company.domain || "",
    logo: resolveCompanyLogo(company),
    industries: (company.tags || []).filter((tag) => !!tag).slice(0, 3),
    investments: investments || [],
    investors: extractInvestors(investments || []),
    // "raised" may be incorrect from the metrics item, so we calculate it here
    raised:
      !investments?.length && raised
        ? raised
        : calculateRaised(investments || []),
    social_medias: {
      crunchbase: ensureFullUrl(companySocialMedias.crunchbase || ""),
      facebook: ensureFullUrl(companySocialMedias.facebook || ""),
      linkedin: ensureFullUrl(companySocialMedias.linkedin || ""),
      twitter: ensureFullUrl(companySocialMedias.twitter || ""),
      website: ensureFullUrl(company.website || ""),
    },
  };

  const profileData: PersonalizationProfileData = {
    avatar: resolveProfileAvatar(profile),
    bio: profile.bio || "",
    company: {
      company_name: profileCompanyName || "",
      company_bio: profileCompanyBio || "",
      url: profileCompanyUrl || "",
    },
    education: (profile.education || [""])[0],
    email,
    job: {
      // could be diff from company if we just did a domain grab on the email and its old data, or
      // the user updated it in Dashboard
      company: profileCompanyName || "",
      title: profile.job.title || "",
    },
    loading: false,
    name: (profile.full_name || "").trim(),
    timezone: profile.timezone || "",
    location: profile.location || "",
    workHistory: parseWorkHistory(profile.work_history),
  };

  if (social_medias) {
    profileData.social_medias = {
      crunchbase: ensureFullUrl(social_medias.crunchbase || ""),
      facebook: ensureFullUrl(
        social_medias.facebook && social_medias.facebook !== FB_BASE_URL
          ? social_medias.facebook
          : ""
      ),
      linkedin: ensureFullUrl(social_medias.linkedin || ""),
      twitter: ensureFullUrl(
        social_medias.twitter && social_medias.twitter !== TWITTER_BASE_URL
          ? social_medias.twitter
          : ""
      ),
      website: ensureFullUrl(social_medias.website || ""),
    };
  }

  return {
    cart: DEFAULT_CART,
    profile: profileData,
    recent_activity,
    company: companyData,
    notes: note?.notes ?? [],
  };
};

export const parseCRMResponse = (response: CRMResponse): ICRM => {
  const {
    has_gong_access: hasGongAccess,
    has_hubspot_access: hasHubspotAccess,
    has_salesforce_access: hasSalesforceAccess,
    gong_data,
    hubspot_data,
    salesforce_data,
  } = response;

  const hubspotData = hubspot_data
    ? {
        personal: {
          name: `${hubspot_data?.properties?.firstName} ${hubspot_data?.properties?.lastName}`,
          email: hubspot_data?.properties?.email,
          phone: hubspot_data?.properties?.phone,
          stage: hubspot_data?.properties?.lifecyclestage,
        },
        notes: (hubspot_data?.notes || []).map(
          (note: HubspotNoteResponse) =>
            ({
              body: note.properties?.hs_note_body as string,
              timestamp: note.properties?.hs_timestamp as string,
            }) as HubspotNote
        ),
        company: {
          name: hubspot_data?.company?.properties?.name,
          domain: hubspot_data?.company?.properties?.domain,
          stage: hubspot_data?.company?.properties?.lifecyclestage,
        },
      }
    : undefined;

  const rawSalesforce = salesforce_data?.[0];
  const salesforceData =
    rawSalesforce !== undefined
      ? {
          lead: {
            name: `${rawSalesforce?.lead_record?.FirstName} ${rawSalesforce?.lead_record?.LastName}`,
            email: rawSalesforce?.lead_record?.Email,
            phone: rawSalesforce?.lead_record?.Phone,
            stage: rawSalesforce?.lead_record?.Status,
          },
          contact: {
            name: `${rawSalesforce?.contact_records?.FirstName} ${rawSalesforce?.contact_records?.LastName}`,
            email: rawSalesforce?.contact_records?.Email,
            phone: rawSalesforce?.contact_records?.Phone,
          },
          company: {
            name: rawSalesforce?.account_record?.Name,
            domain: rawSalesforce?.account_record?.Website,
            phone: rawSalesforce?.account_record?.Phone,
            industry: rawSalesforce?.account_record?.Industry,
          },
        }
      : undefined;

  return {
    gong_data: gong_data ?? [],
    hubspot_data: hubspotData,
    salesforce_data: salesforceData,
    hasGongAccess,
    hasHubspotAccess,
    hasSalesforceAccess,
    loading: false,
  };
};

export const parseNewsResponse = (
  response: NewsResponse,
  email: emailAddress
  // isValidForStartMyEmail: boolean = true
): INews => {
  const feed = response.feed[getDomain(email)]?.Feed;

  return {
    feed: getEvents(feed ?? []),
    loading: false,
  };
};

export const parseTweetResponse = (response: TweetsResponse): ITweets => {
  const { company, company_twitter, profile_twitter } = response;
  const {
    investments,
    metrics: { raised, employeesRange } = {},
    social_medias: companySocialMedias = {
      crunchbase: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      website: "",
    },
  } = company;

  //!we need to use company.company_name > profile.company.company_name > profile.job.company
  // Only way to sync all fields, since profile.company.company_name / profile.job.company don't
  // have full info & lookups gets messy. The "companyData" object can be considered separate from
  // the "profileData" in this sense. *Applies to all fields in companyData*
  const companyNameFromDataVendor = company.company_name || "";
  const companyData: PersonalizationCompanyData = {
    bio: company.bio || "",
    location: company.location || "",
    jobs: { loading: true, jobs: undefined, aggregate: [] },
    name: companyNameFromDataVendor,
    employeesRange:
      employeesRange || company?.metrics?.["Employees Range"] || "",
    domain: company.domain || "",
    logo: resolveCompanyLogo(company),
    industries: (company.tags || []).filter((tag) => !!tag).slice(0, 3),
    investments: investments || [],
    investors: extractInvestors(investments || []),
    // "raised" may be incorrect from the metrics item, so we calculate it here
    raised:
      !investments?.length && raised
        ? raised
        : calculateRaised(investments || []),
    social_medias: {
      crunchbase: ensureFullUrl(companySocialMedias.crunchbase || ""),
      facebook: ensureFullUrl(companySocialMedias.facebook || ""),
      linkedin: ensureFullUrl(companySocialMedias.linkedin || ""),
      twitter: ensureFullUrl(companySocialMedias.twitter || ""),
      website: ensureFullUrl(company.website || ""),
    },
  };

  return {
    company: companyData,
    company_twitter,
    profile_twitter,
    loading: false,
  };
};

export const parseTechStackResponse = (
  response: TechStackResponse
): ITechStack => {
  return {
    techAggregate: parseTechAggregateData(response.tech_aggregate),
    loading: false,
  };
};

export const parseRankedFeed = (
  feed: RankedFeedResponse
): PersonalizationRankedData => {
  const {
    ranked_feed: { events, work_history, tech_stack },
  } = feed;
  const eventItems = events.items !== undefined ? getEvents(events.items) : [];
  const techStackItems =
    tech_stack.items !== undefined ? getTechStack(tech_stack.items) : [];
  const employmentItems =
    work_history.items !== undefined ? getExperience(work_history.items) : [];
  return {
    [RankedFeedType.events]: {
      items: eventItems,
      total_score: events.total_score || 0,
    },
    [RankedFeedType.funding]: DEFAULT_RANKED_FEED_SECTION,
    [RankedFeedType.job_openings]: DEFAULT_RANKED_FEED_SECTION,
    [RankedFeedType.tech_stack]: {
      items: techStackItems,
      total_score: tech_stack.total_score || 0,
    },
    [RankedFeedType.work_history]: {
      items: employmentItems,
      total_score: work_history.total_score || 0,
    },
  };
};
