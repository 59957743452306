import { INews } from "~src/redux/typings";

export const newPendingNews = (): INews => ({
  feed: [],
  loading: true,
});

export const newRejectedNews = (): INews => ({
  feed: [],
  loading: false,
});
