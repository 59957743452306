import { useCallback, useEffect, useMemo } from "react";
import { Form, FormInstance } from "antd";

import { ExtensionButton } from "~src/component/Atoms";
import { LabeledInput, LabeledTextArea } from "~src/component/Molecules";

import {
  COMPANY_BIO_INPUT,
  COMPANY_NAME_INPUT,
  COMPANY_WEBSITE_INPUT,
  JOB_TITLE_INPUT,
  MIN_COMPANY_BIO_LENGTH,
  MIN_COMPANY_NAME_LENGTH,
  MIN_JOB_TITLE_LENGTH,
} from "~src/constants";
import { useStartMyEmail } from "~src/customHooks";
import { CONFIRM_INFO_FORM } from "~src/strings";

import { StyledInnerForm, StyledSnackbarContainer } from "../Shared/Styled";

export interface FieldType {
  companyBio: string;
  companyName: string;
  companyWebsite: string;
  jobTitle: string;
}

interface Props {
  form: FormInstance<FieldType>;
  isSaving: boolean;
  snackbarContainerRef?: React.RefObject<HTMLDivElement>;
  submitForm: (values: FieldType) => void;
}

export const ConfirmInfo = ({
  form,
  isSaving,
  snackbarContainerRef,
  submitForm,
}: Props) => {
  const {
    currentPersonalization: {
      profile: {
        job,
        company: {
          company_bio: companyBio,
          company_name: companyName,
          url: companyWebsite,
        },
      },
    },
  } = useStartMyEmail();

  const jobTitle = job.title;

  const formCompanyBio = Form.useWatch(COMPANY_BIO_INPUT.name, form);
  const formCompanyName = Form.useWatch(COMPANY_NAME_INPUT.name, form);
  const formCompanyWebsite = Form.useWatch(COMPANY_WEBSITE_INPUT.name, form);
  const formJobTitle = Form.useWatch(JOB_TITLE_INPUT.name, form);

  useEffect(() => {
    form.setFieldsValue({
      companyBio: formCompanyBio,
      companyName: formCompanyName,
      companyWebsite: formCompanyWebsite,
      jobTitle: formJobTitle,
    });
  }, [form, formCompanyBio, formCompanyName, formCompanyWebsite, formJobTitle]);

  const formHasChanged = useMemo(() => {
    return (
      (formCompanyBio || "") !== (companyBio || "") ||
      (formCompanyName || "") !== (companyName || "") ||
      (formCompanyWebsite || "") !== (companyWebsite || "") ||
      (formJobTitle || "") !== (jobTitle || "")
    );
  }, [
    companyBio,
    companyName,
    companyWebsite,
    formCompanyBio,
    formCompanyName,
    formCompanyWebsite,
    formJobTitle,
    jobTitle,
  ]);

  const isSubmitDisabled = useCallback(() => {
    return (
      !formHasChanged ||
      !formCompanyBio ||
      formCompanyBio.length < MIN_COMPANY_BIO_LENGTH ||
      !formCompanyName ||
      formCompanyName.length < MIN_COMPANY_NAME_LENGTH ||
      (formCompanyWebsite !== undefined && !formCompanyWebsite.length) ||
      (formJobTitle ? formJobTitle.length < MIN_JOB_TITLE_LENGTH : false)
    );
  }, [
    formCompanyBio,
    formCompanyName,
    formCompanyWebsite,
    formHasChanged,
    formJobTitle,
  ]);

  const initialValues = useMemo(
    () => ({
      companyBio,
      companyName,
      companyWebsite,
      jobTitle,
    }),
    [companyBio, companyName, companyWebsite, jobTitle]
  );

  const buttonState = useMemo(() => {
    if (isSaving) {
      return "loading";
    }
    return isSubmitDisabled() ? "disabled" : "initial";
  }, [isSaving, isSubmitDisabled]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={submitForm}
    >
      <StyledInnerForm vertical gap={24}>
        <Form.Item
          label={JOB_TITLE_INPUT.label}
          name={JOB_TITLE_INPUT.name}
          noStyle
        >
          <LabeledInput
            label={JOB_TITLE_INPUT.label}
            name={JOB_TITLE_INPUT.name}
          />
        </Form.Item>
        <Form.Item
          label={COMPANY_NAME_INPUT.label}
          name={COMPANY_NAME_INPUT.name}
          noStyle
          rules={COMPANY_NAME_INPUT.rules}
        >
          <LabeledInput
            label={COMPANY_NAME_INPUT.label}
            name={COMPANY_NAME_INPUT.name}
          />
        </Form.Item>
        <Form.Item
          label={COMPANY_WEBSITE_INPUT.label}
          name={COMPANY_WEBSITE_INPUT.name}
          noStyle
          rules={COMPANY_WEBSITE_INPUT.rules}
        >
          <LabeledInput
            label={COMPANY_WEBSITE_INPUT.label}
            name={COMPANY_WEBSITE_INPUT.name}
          />
        </Form.Item>
        <Form.Item
          label="Company Bio"
          name={COMPANY_BIO_INPUT.name}
          noStyle
          rules={COMPANY_BIO_INPUT.rules}
        >
          <LabeledTextArea
            label="Company Bio"
            minLength={MIN_COMPANY_BIO_LENGTH}
            name={COMPANY_BIO_INPUT.name}
          />
        </Form.Item>
      </StyledInnerForm>
      <StyledSnackbarContainer ref={snackbarContainerRef}>
        <Form.Item noStyle label={CONFIRM_INFO_FORM.SUBMIT}>
          <ExtensionButton
            fullWidth
            state={buttonState}
            variant="primary"
            htmlType="submit"
            text={
              isSaving ? CONFIRM_INFO_FORM.SUBMITTING : CONFIRM_INFO_FORM.SUBMIT
            }
          />
        </Form.Item>
      </StyledSnackbarContainer>
    </Form>
  );
};
