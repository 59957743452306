export const getDomain = (email: string) => {
  if (!email) {
    return email;
  }
  const split = email.split("@");
  if (split.length > 1) {
    return split[1];
  }
  return email;
};
export const getDomainUrl = (url: string) => {
  if (!url) {
    return url;
  }
  let split = url.split("/");
  if (split.length > 1) {
    url = split[2];
  }
  split = url.split("?");
  if (split.length > 0) {
    return split[0];
  }
  return url;
};

// should match Lavender backend ExtractBodySubject
export const extractBodySubject = (response: string) => {
  let subject = "";
  let body = response;

  let matches = response.match(/Subject:.*$/m);

  if (matches && matches.length > 0) {
    subject = matches[0];
    body = body.replace(subject, "").trim();
    subject = subject.replace("Subject:", "").trim();
  } else {
    matches = response.match(/subject:.*$/m);
    if (matches && matches.length > 0) {
      subject = matches[0];
      body = body.replace(subject, "").trim();
      subject = subject.replace("subject:", "").trim();
    }
  }

  return { subject, body };
};
