import { PredictLeadEvent } from "~src/redux/frameworkAi/frameworkAi";
import { AISuggestion, Recommendation } from "~src/redux/typings";

import { URLS, networkRequest } from ".";

export const getAISuggestion = async (
  chromeId: chromeId,
  body: string,
  recommendation: Recommendation,
  ignoreCache: boolean,
  promptName?: string
) => {
  return await networkRequest.post<
    AISuggestion & {
      message: Error;
    }
  >(URLS.aiSuggestion, {
    chrome_id: chromeId,
    input: recommendation?.text || body,
    prompt_name: promptName || "make_concise",
    ignore_cache: ignoreCache,
  });
};

export const fetchFrameworksAI = async (
  chromeId: chromeId,
  category: string,
  object: PredictLeadEvent,
  ignore_cache: boolean,
  email: string
) => {
  // category can only be
  // events, jobs, "tech", "compliment", "competitor"
  return await networkRequest.post<{
    snippets: {
      Template: string;
    }[];
    message: Error;
  }>(URLS.frameworksAi, {
    chrome_id: chromeId,
    category,
    object,
    ignore_cache,
    email,
  });
};
