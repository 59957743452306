import { useState } from "react";
import { Col, Divider, Flex, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import clsx from "clsx";

import { Button, Text } from "@lavender-ai/lav-components";

import { PERSONALIZATION_CTA } from "~src/strings";
import { PersonalizationJobAggregateData } from "~src/redux/typings";
import { SecondaryIconButton } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";

const { HIDE_DIVISIONS, SEE_ALL_DIVISIONS } = PERSONALIZATION_CTA;

interface JobAggregateProps {
  aggregate: PersonalizationJobAggregateData[];
  loading: boolean;
}

const columns: ColumnsType<PersonalizationJobAggregateData> = [
  {
    title: "category",
    dataIndex: "category",
    key: "category",
    render: (category) => <Text size="subtext1">{category}</Text>,
  },
  {
    title: "percent",
    dataIndex: "percent",
    key: "percent",
    render: (percent) => <Text size="body1">{percent}%</Text>,
    width: "56px",
  },
  {
    title: "roles",
    dataIndex: "roles",
    key: "roles",
    render: (roles) => (
      <Text size="body1">
        {roles} {roles === 1 ? "role" : "roles"}
      </Text>
    ),
    width: "72px",
  },
  Table.EXPAND_COLUMN,
];

export const JobAggregate = ({ aggregate, loading }: JobAggregateProps) => {
  const [showAll, setShowAll] = useState(
    loading ? false : aggregate?.length <= 4
  );

  const displayedData = showAll ? aggregate : aggregate?.slice(0, 4);

  return (
    <>
      <Table
        columns={columns}
        dataSource={displayedData}
        showHeader={false}
        loading={{
          spinning: loading,
        }}
        pagination={false}
        expandable={{
          columnWidth: "32px",
          rowExpandable: (record) => record.roles > 0,
          expandedRowRender: (record, index, _indent, expanded) => (
            <Flex
              className={clsx({
                "--expanded": expanded,
                "--last": index === displayedData.length - 1,
              })}
              vertical
            >
              {Object.entries(record.breakdown).map(([key, value]) => (
                <Row key={key}>
                  <Col flex="191px">
                    <Text size="body1">{key}</Text>
                  </Col>
                  <Col flex="72px">
                    <Text size="body1">
                      {value} {value === 1 ? "role" : "roles"}
                    </Text>
                  </Col>
                </Row>
              ))}
            </Flex>
          ),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record.roles === 0) {
              return null;
            }

            return expanded ? (
              <Button
                size="small"
                variant="plain"
                icon={LavenderIcon.IconChevronUp}
                onClick={(e) => {
                  onExpand(record, e);
                }}
              />
            ) : (
              <Button
                size="small"
                variant="plain"
                icon={LavenderIcon.IconChevronDown}
                onClick={(e) => {
                  onExpand(record, e);
                }}
              />
            );
          },
        }}
      />
      {!loading && aggregate?.length > 4 && (
        <div style={{ padding: "8px 0 12px" }}>
          <SecondaryIconButton
            prefix={showAll ? LavenderIcon.IconMinus : LavenderIcon.IconPlus}
            fullWidth
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? HIDE_DIVISIONS : SEE_ALL_DIVISIONS}
          </SecondaryIconButton>
        </div>
      )}
      <Divider style={{ margin: "8px 0px" }} />
    </>
  );
};
