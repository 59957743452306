import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { snakeCase as snakeCaseKeys } from "change-case/keys";

import { APIEndpoints } from "~src/api";
import {
  UpdateSettingsResponse,
  UpdateSettingsPayload,
} from "~src/api/typings";

import { feedActions } from "./feed";

export const profileApiSlice = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.BACKEND_API}/api/` }),
  endpoints: (builder) => ({
    updateProfileSettings: builder.mutation<
      UpdateSettingsResponse,
      UpdateSettingsPayload
    >({
      query: (payload) => {
        const transformRequestBody = (
          originalPayload: UpdateSettingsPayload
        ) => {
          return snakeCaseKeys(originalPayload);
        };

        const transformedPayload = transformRequestBody(payload);

        return {
          url: APIEndpoints.profileSettings,
          method: "POST",
          body: transformedPayload,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(feedActions.updateProfileSettings(arg));
      },
    }),
  }),
});

export const profileApiReducer = profileApiSlice.reducer;
export const { useUpdateProfileSettingsMutation } = profileApiSlice;
