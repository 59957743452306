import { createAction, createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type AppDispatch, type RootState } from "./store";
import { verify } from "./verification";
import {
  EmailDataState,
  PixelIDWrapper,
  ToggleIncludeInAnalytics,
  UpdateAuthorEmail,
  UpdateAuthorName,
  UpdateBody,
  UpdateEmailsBefore,
  UpdateRecipientEmailList,
  UpdateScoringModel,
  UpdateStage,
  UpdateSubject,
  UpdateTimeSent,
  UpdateToAddress,
} from "~src/redux/typings";

export const INIT_EMAIL_DATA_STATE: EmailDataState = {};

export const updateIdAndToAddress = createAction<UpdateToAddress>("emails/updateIdAndToAddress");

export const updateAndVerifyRecipients = createAsyncThunk<
  Promise<void>,
  UpdateToAddress,
  { dispatch: AppDispatch; state: RootState }
>("emails/updateRecipients", async ({ id, toAddress }, { dispatch, getState }) => {
  dispatch(updateIdAndToAddress({ id, toAddress }));

  const {
    config: { emailAddress },
  } = getState();

  const exceptUser = toAddress.filter((email) => email !== emailAddress);

  if (exceptUser.length) {
    await dispatch(verify({ toAddress: exceptUser }));
  }
});

export const emailDataSlice = createSlice({
  name: "emails",
  initialState: INIT_EMAIL_DATA_STATE,
  reducers: {
    updateSubjectString: (
      state: EmailDataState,
      action: PayloadAction<UpdateSubject>
    ): EmailDataState => {
      const { id, subject } = action.payload;
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            subject,
            subjectLastUpdated: Date.now(),
            hasSubjectPaused: false,
          },
        };
      }
      return state;
    },
    pauseSubject: (state: EmailDataState, { payload: id }) => {
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            hasSubjectPaused: true,
          },
        };
      }
    },
    tagCoachUsed: (state: EmailDataState, action: PayloadAction<PixelIDWrapper>) => {
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isCoachUsed: true,
        },
      };
    },
    tagChatGPTUsed: (state: EmailDataState, action: PayloadAction<PixelIDWrapper>) => {
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isChatGPTUsed: true,
        },
      };
    },
    tagFrameworksUsed: (state: EmailDataState, action: PayloadAction<PixelIDWrapper>) => {
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isFrameworksUsed: true,
        },
      };
    },
    tagGifsUsed: (state: EmailDataState, action: PayloadAction<PixelIDWrapper>) => {
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isGifsUsed: true,
        },
      };
    },
    tagPersonalizationAssistantUsed: (
      state: EmailDataState,
      action: PayloadAction<PixelIDWrapper>
    ) => {
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isPersonalizationAssistantUsed: true,
        },
      };
    },
    tagStartMyEmailUsed: (state: EmailDataState, action: PayloadAction<PixelIDWrapper>) => {
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isStartMyEmailUsed: true,
        },
      };
    },
    toggleAnalytics: (state: EmailDataState, action: PayloadAction<ToggleIncludeInAnalytics>) => {
      const { id, includeInAnalytics } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          includeInAnalytics,
        },
      };
    },
    pauseBody: (state: EmailDataState, { payload: id }) => {
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            hasBodyPaused: true,
          },
        };
      }
    },
    updateRecipientEmailList: (
      state: EmailDataState,
      action: PayloadAction<UpdateRecipientEmailList>
    ): EmailDataState => {
      const { id, recipientEmailList } = action.payload;
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            recipientEmailList,
          },
        };
      }
      return state;
    },
    updateEmailsBefore: (
      state: EmailDataState,
      action: PayloadAction<UpdateEmailsBefore>
    ): EmailDataState => {
      const { id, emailsBefore } = action.payload;
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            emailsBefore,
          },
        };
      }
      return state;
    },
    updateTimeSent: (
      state: EmailDataState,
      action: PayloadAction<UpdateTimeSent>
    ): EmailDataState => {
      const { id, timeSent } = action.payload;
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            timeSent,
          },
        };
      }
      return state;
    },
    updateAuthorName: (
      state: EmailDataState,
      action: PayloadAction<UpdateAuthorName>
    ): EmailDataState => {
      const { id, authorName } = action.payload;
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            authorName,
          },
        };
      }
      return state;
    },
    updateAuthorEmail: (
      state: EmailDataState,
      action: PayloadAction<UpdateAuthorEmail>
    ): EmailDataState => {
      const { id, authorEmail } = action.payload;
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            authorEmail,
          },
        };
      }
      return state;
    },
    updateStage: (state: EmailDataState, action: PayloadAction<UpdateStage>): EmailDataState => {
      const { id, stageOverwrite } = action.payload;
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            stageOverwrite,
          },
        };
      }
      return state;
    },
    updateScoringModel: (
      state: EmailDataState,
      action: PayloadAction<UpdateScoringModel>
    ): EmailDataState => {
      const { id, scoringModelOverwrite } = action.payload;
      if (id !== undefined) {
        return {
          ...state,
          [id]: {
            ...state[id],
            scoringModelOverwrite,
          },
        };
      }
      return state;
    },
    updateBodyString: (
      state: EmailDataState,
      action: PayloadAction<UpdateBody>
    ): EmailDataState => {
      const { id, body } = action.payload;
      if (id !== undefined) {
        // INFO: finding when the user started writing the email
        let writingStartTime = 0;
        if (state[id]?.body === "" && state[id]?.bodyLastUpdated === 0) {
          writingStartTime = Date.now();
        }
        return {
          ...state,
          [id]: {
            ...state[id],
            body,
            bodyLastUpdated: Date.now(),
            hasBodyPaused: false,
            writingStartTime: writingStartTime > 0 ? writingStartTime : state[id]?.writingStartTime,
          },
        };
      }
      return state;
    },
    initialize: (
      state: EmailDataState,
      { payload }: PayloadAction<{ lvIdentifier: lvIdentifier }>
    ) => {
      const { lvIdentifier } = payload;
      if (state[lvIdentifier] === undefined && lvIdentifier !== undefined) {
        return {
          ...state,
          [lvIdentifier]: {
            toAddress: [],
            subject: window.email_data?.subject ? window.email_data?.subject : "",
            scoringModelOverwrite: undefined,
            stageOverwrite: "",
            subjectLastUpdated: 0,
            hasSubjectPaused: false,
            body: window.email_data?.body ? window.email_data?.body : "",
            bodyLastUpdated: 0,
            isCoachUsed: false,
            isChatGPTUsed: false,
            isGifsUsed: false,
            isFrameworksUsed: false,
            isPersonalizationAssistantUsed: false,
            isStartMyEmailUsed: false,
            includeInAnalytics: true,
            hasBodyPaused: false,
            timeSent: -1,
            recipientEmailList: [],
            emailsBefore: [],
            authorEmail: "",
            authorName: "",
            writingStartTime: window.email_data?.writingStartTime
              ? window.email_data?.writingStartTime
              : 0,
          },
        };
      }
      return state;
    },
    deleteEmailData: (state: EmailDataState, action: { payload: lvIdentifier }) => {
      // filter out the email data that is being deleted without using delete call
      if (action.payload !== undefined) {
        const { [action.payload]: _, ...rest } = state;
        return { ...rest };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateIdAndToAddress, (state, action) => {
      const { id, toAddress } = action.payload;
      if (id !== undefined) {
        return { ...state, [id]: { ...state[id], toAddress } };
      }
      return state;
    });
  },
});

export const emailDataReducer = emailDataSlice.reducer;
export const emailAction = emailDataSlice.actions;
