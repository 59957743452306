import { networkRequest, URLS } from "~src/api";
import { NetworkStatus, OpenEmailType } from "~src/redux/typings";

export const getOpenEmailTotal = async (
  chromeId: chromeId,
  lvIdentifier: lvIdentifier
) => {
  return await networkRequest.post<{
    total_opens: number;
    message: NetworkStatus;
    opens?: OpenEmailType[];
    error: string;
  }>(URLS.openEmailTotal, {
    pixel_id: lvIdentifier,
    chrome_id: chromeId,
  });
};
