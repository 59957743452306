import { Analysis, Person, SocialMedia } from "~src/redux/typings";
import { Note } from "./note";
import { PersonalizationTrigger } from ".";

export interface PersonalizationResponse {
  profile: Person;
  recent_activity: RecentActivity;
  company: CompanyResponse;
  note?: Note;
}

export interface CompanyBase {
  bio?: string;
  domain?: string;
  location?: string;
  logo?: string;
}

export interface FeedItem<T> {
  score?: number;
  Type: CompanyDataType;
  Entity: FeedEntity<T>;
}

export enum CompanyDataType {
  competitor = "competitor",
  events = "events",
  jobs = "jobs",
  tech = "tech",
  work_history = "work_history",
}

export interface CompanyFeed<T> {
  Entity: FeedEntity<T>;
  Type: CompanyDataType;
}

export interface FeedEntity<T> {
  attributes: T;
  id: string;
  relationships?: object;
  formatted_titles: string[] | null; // null for technology type
  placeholder_prompts: string[] | null; // null for technology type
  triggers: PersonalizationTrigger[] | null; // null for technology type
  you_id?: string; // news only
  type: string; // technology or ""
}

export interface CompanyInvestment {
  date: number;
  news_url: string;
  amount: CompanyInvestmentAmount;
  series: string;
  investors: CompanyInvestor[] | null;
}

export interface CompanyResponse extends CompanyBase {
  id: string; // do not use - might be a zero value
  last_updated: string;
  apollo_company_id: string;
  exact_buyer_company_id: string;
  clearbit_company_id: string;
  full_contact_company_id: string;
  diffbot_id: string;
  competitors?: string[]; // from dashboard
  compliments?: string[]; // from dashboard
  third_party_pl_mapped?: boolean; // mapped in dashboard by ai
  company_name?: string;
  record_found?: boolean;
  investments?: CompanyInvestment[];
  metrics?: CompanyMetrics;
  social_medias?: SocialMedia;
  tags?: string[];
  website?: string;
  founders: // DEPRECATED: Diffbot data
  | {
        summary?: string;
        image?: string;
        name?: string;
      }[]
    | null;
}

interface CompanyInvestmentAmount {
  currency: string;
  value: number;
}

interface CompanyInvestor {
  name: string;
  summary: string; // DEPRECATED: Diffbot data
  image: string; // DEPRECATED: Diffbot data
}

interface CompanyMetrics {
  "Employees Range"?: string;
  alexaGlobalRank?: number;
  alexaUsRank?: number;
  employees?: number;
  employeesRange?: string;
  estimatedAnnualRevenue?: number;
  founded?: string | number;
  revenue?: number;
  raised: number; // defaults to 0
}

export interface RecentActivity {
  calendar_event: object;
  email_analysis: Record<string, RecentActivityEmailAnalysis>;
  indexes: RecentActivityIndex[] | null;
}

export interface RecentActivityEmailAnalysis {
  id: string;
  is_reply: boolean;
  is_rtr: boolean;
  owner: string;
  by_owner: boolean;
  subject_hash: string;
  raw_subject: string;
  recipients_hash: string;
  body_hash: string;
  time_sent: string;
  author_email: string;
  analysis: Analysis[];
  recipients: RecentActivityEmailAnalysisRecipient[];
  recipientstr: string;
  anonymous: object;
  total_opens: number;
  reply_count: number;
  calendar_event_id: string;
  emails_before: RecentActivityEmail[];
  emails_after: RecentActivityEmail[];
  domains_arr: string[];
  third_party: {
    updated_at: string;
    time_sent: string;
    clicked_at: string;
    opened_at: string;
    delivered_at: string;
  };
}

interface RecentActivityEmailAnalysisRecipient {
  email_address: string;
  sorter_segment: string;
}

interface RecentActivityEmail {
  author_email: string;
  time_sent: string;
}

export interface RecentActivityIndex {
  Id: string;
  Time: string;
  Type: string;
  IncludesRecipient: boolean;
  Upcoming: boolean;
}

export interface Tweet {
  id?: number;
  created_at?: string;
  favorite_count?: number;
  retweet_count?: number;
  text?: string;
}

export interface TwitterUser {
  _id?: any; // Replace 'any' with the actual type of 'primitive.ObjectID' in your TypeScript code
  description?: string;
  favourites_count?: number;
  followers_count?: number;
  friends_count?: number;
  twitter_id?: number;
  location?: string;
  name?: string;
  profile_banner_url?: string;
  profile_image_url_https?: string;
  protected?: boolean;
  screen_name?: string;
  statuses_count?: number;
  verified?: boolean;
  tweets?: Tweet[];
  last_updated?: string;
}
