import { FeedEntityAttribute } from "./additionalCompanyData";
import { CompanyFeed } from "./personalization";

export interface NewsResponse {
  feed: Record<
    emailAddress,
    {
      Feed: CompanyFeed<FeedEntityAttribute>[] | null;
    }
  >;
}
